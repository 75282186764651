import Api from "./ApiLogic";
import * as QUERIES from "../graphql/queries";
import { map, distinctUntilChanged, delay } from 'rxjs/operators';

const pollingInterval = 1000;

export default new class {
  _completePolls = [];
  _runningPolls = [];

  _transformResult = (data, pollState) => {
    let polls = data.data.getPolls;

    return polls.map(p => {
      return {
        id: p.pollid,
        title: p.title,
        name: p.name,
        state: p.state,
        timeInSeconds: p.time_in_seconds,
        winningChoiceId: p.winning_choice_id,
        winningChoiceUrl: p.winning_choice_url,
        winningChoiceTitle: p.winning_choice_title,
        winningChoiceDesc: p.winning_choice_desc,
        winningChoiceThumbnail: p.winning_choice_thumbnail
      }
    }).sort((a, b) => a.id - b.id) // sort by most recent
      .filter(p => p.state === pollState);
  }

  runningPolls(eventId) {
    if (!this._runningPolls[eventId]) {
      let query = { query: QUERIES.getPolls, variables: { eventId: eventId } };
      this._runningPolls[eventId] = Api.pollingStream(query, pollingInterval).pipe(
        map(data => this._transformResult(data, "Running")),
        distinctUntilChanged((prev, curr) => prev.length === curr.length));
    }
    return this._runningPolls[eventId];
  }

  completePolls(eventId) {
    if (!this._completePolls[eventId]) {
      let query = { query: QUERIES.getPolls, variables: { eventId: eventId } };
      this._completePolls[eventId] = Api.pollingStream(query, pollingInterval).pipe(
        map(data => this._transformResult(data, "Complete")),
        distinctUntilChanged((prev, curr) => prev.length === curr.length),
        delay(1000));
    }
    return this._completePolls[eventId];
  }
}()