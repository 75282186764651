import React, { useState, useEffect, useMemo, Fragment } from "react";
import {
  Button,
  makeStyles,
  Box,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import * as PaymentAPI from "../../utilities/PaymentAPI";
import FetchInvoiceTotalByUser from "./DonateFetchInvoiceTotalByUser";
import * as MUTATIONS from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import axiosRetry from "axios-retry";
//import FetchCreditCardInfo from "./DonateFetchCreditCardInfo";
import Confetti from "react-confetti";
import * as QUERIES from "../../graphql/queries";
import { useQuery } from "@apollo/client";
//import { useApolloClient } from "@apollo/client";
import EventLogic from "./../../logic/EventLogic";
import produce from "immer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    //justifyContent: "center",
  },
}));

const CheckOut = (props) => {
  //console.log("props inside checkout");
  //console.log(props);
  //console.log(props.userID);
  //console.log(props.invoiceID);

  const classes = useStyles();

  const divStyle = {
    height: props.paperHeight + 'px',
    width: props.paperWidth  + 'px',
  };

  const total = FetchInvoiceTotalByUser(props.userID, "donatecheckout");

  const [isLoading, setLoading] = useState(false);
  const [paymentSuccess, setSuccess] = useState(false);

  const { loading, error, data } = useQuery(QUERIES.getActiveAliasByUser, {
    variables: { uid: props.userID },
    //fetchPolicy: "cache-and-network",
  });
  if (loading && !data) {
    console.log("Loading cc info...");
  }
  if (error) console.log("GraphQL Error! getActiveAliasByUser", error.message);
  /*
  const client = useApolloClient();
  const updateCache = (cache, { data }) => {    
    client.resetStore();
  }
  */

  const updateMyPaymentsAndTotalCache = (cache, { data }) => {
    // update user total in cache back to 0
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: props.userID },
    });
    if (existingInvoiceTotalByUser) {
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: props.userID },
        data: { getInvoiceTotalByUser: 0 },
      });
    }
    // add paid invoice to cache
    try {
      const existingMyPayments = cache.readQuery({
        query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
        variables: { uid: props.userID },
      });
      //console.log("existingMyPayments");
      //console.log(existingMyPayments);
      if (existingMyPayments) {
        /*
        console.log("existingMyPayments.getPaidInvoicesByUserOrderedByEvents");
        console.log(existingMyPayments.getPaidInvoicesByUserOrderedByEvents);
        console.log("SHOULD SEE", produce(existingMyPayments.getPaidInvoicesByUserOrderedByEvents, (eventWithPayments) => {            
          for (var index = 0; index < existingMyPayments.getPaidInvoicesByUserOrderedByEvents.length; index++) {
            console.log("index", existingMyPayments.getPaidInvoicesByUserOrderedByEvents[index]);
            if(existingMyPayments.getPaidInvoicesByUserOrderedByEvents[index].event.id === EventLogic.currentEvent.id){
              const newPayment = data.updateInvoiceState;
              console.log("newpayment", newPayment);
              console.log("eventswithpayments", eventWithPayments[index]);
              eventWithPayments[index].invoices.push(newPayment);
            }
          }
        }));
        */
        cache.writeQuery({
          query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
          variables: { uid: props.userID },
          data: {"getPaidInvoicesByUserOrderedByEvents": produce(existingMyPayments.getPaidInvoicesByUserOrderedByEvents, (eventWithPayments) => {            
            for (var index = 0; index < existingMyPayments.getPaidInvoicesByUserOrderedByEvents.length; index++) {
              console.log("index", existingMyPayments.getPaidInvoicesByUserOrderedByEvents[index]);
              if(existingMyPayments.getPaidInvoicesByUserOrderedByEvents[index].event.id === EventLogic.currentEvent.id){
                const newPayment = data.updateInvoiceState;
                //console.log("newpayment", newPayment);
                //console.log("eventswithpayments", eventWithPayments[index]);
                eventWithPayments[index].invoices.push(newPayment);
              }
            }
          })},
        });
      }
    } catch (error) {
      console.log("ERROR querying existing payments in cache: ", error);
    }
  };

  const [updateInvoiceState] = useMutation(MUTATIONS.updateInvoiceState, {
    update: updateMyPaymentsAndTotalCache,
  });

  function handlePayment() {
    setLoading(true);

    if (data) {
      const client = axios.create();
      axiosRetry(client, {
        retries: 5,
        retryDelay: (retryCount, error) => {
          console.log("retry attempt: ", retryCount);
          console.log("error", error);
          return retryCount * 500;
        },
        retryCondition: axiosRetry.isRetryableError,
      });

      const options = {
        url: PaymentAPI.CHARGE_URL,
        method: "POST",
        headers: {
          "x-api-key": PaymentAPI.APIKEY,
          "Content-Type": "application/json",
        },
        data: {
          //userid: props.userID,
          aliasid: data.getActiveAliasByUser.aliasid,
          transactionAmount: total,
        },
      };
      console.log(options);
      console.log("aliasid is ", data.getActiveAliasByUser.aliasid);
      client(options)
        .then((response) => {
          //console.log("axios response");
          console.log(response);
          //console.log(response.data);
          if (response.data) {
            setLoading(false);
            setSuccess(true);
            // after successful payment, wait 5 seconds, update invoice state, set invoice total to 0
            setTimeout(function () {
              props.setAnchorEl(false);
              props.setActiveView("buttonToggle");
              updateInvoiceState({
                variables: {
                  input: {
                    id: parseInt(props.invoiceID),
                    stateid: 2,
                  },
                },
              });
            }, 5000);
          }
        })
        .catch((err) => {
          console.log("CAUGHT payment error", err);
          if (err.response) {
            console.log("5xx or 4xx");
            console.log(err.response);
          } else if (err.request) {
            console.log("err.request", err.request);
          }
          props.setActiveView("retry");
          setLoading(false);
        });
    }
  }

  const renderConfetti = () => {
    return (
      <Confetti
        style={{ zIndex: "5" }}
        width={props.paperWidth}
        height={props.paperHeight}
      />
    );
  };

  const thankYou = (
    <div className="col-flexbox-container" style={divStyle}>
      {renderConfetti()}
      <div className="col-flexbox-component">
        <div className="thankYouTitle"> Thanks! </div>
      </div>
      <div className="col-flexbox-component">
        <div className="thankYouPara">You have made a ${total} donation!</div>
      </div>
    </div>
  );

  const paymentInfo = useMemo(
    () => (
      <div className="col-flexbox-component">
        <div className="donateTitle">Payment Information</div>
        {loading && !data && <CircularProgress style={{ color: "gray" }} />}
        {data && data.getActiveAliasByUser ? (
          <div className="creditCard">
            Saved {data.getActiveAliasByUser.paymentmethod} Ending in{" "}
            <span className="creditCardNumber">
              *{data.getActiveAliasByUser.last_3}
            </span>
          </div>
        ) : (
          <div></div>
        )}
        {/*FetchCreditCardInfo(props.userID)*/}
      </div>
    ),
    [data, loading]
  );
  useEffect(() => {
    if (props.popperRef.current) {
      //console.log("UPDATING popper");
      props.popperRef.current.update();
    }
  }, [paymentInfo, props.popperRef]);

  const checkOutButtonSection = (
    <Fragment>
      <div className="divBar">
        <Divider variant="middle" />
      </div>

      <div className="col-flexbox-component">
        <div className="row-flexbox-container checkout">
          <div className="row-flexbox-component">
            <Button
              variant="outlined"
              size="large"
              onClick={() => props.setActiveView("buttonToggle")}
              className={classes.root}
            >
              Back to Donate {">"}
            </Button>
          </div>
          <div className="row-flexbox-component">
            <Button
              variant="outlined"
              size="large"
              onClick={() => props.setActiveView("manageDonations")}
              className={classes.root}
            >
              Donation Cart
            </Button>
          </div>
          <div className="row-flexbox-component">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => 
                handlePayment()
                /*
                // used for frontend dev to view different screens 
                //setLoading(true)
                //setSuccess(true)
                //props.setActiveView("retry")
                */                
            }
              className={classes.root}
              disabled={loading || !data || !data.getActiveAliasByUser}
            >
              Complete Payment
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {!isLoading && !paymentSuccess && (
        <div className="col-flexbox-component">
          <div className="donateTitle">Check Out:</div>
          <div className="checkOutTotal">
            Donation Total: ${total ? total : 0}
          </div>
        </div>
      )}

      {!isLoading && !paymentSuccess && paymentInfo}

      {isLoading && (
        <div className="col-flexbox-container">
          <div className="col-flexbox-component">
            <div className="orderProcessingTitle">Order Processing</div>
            <div className="col-flexbox-component">
              <div className="orderProcessingAmt">${total}</div>
            </div>
            <div className="col-flexbox-component">
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            </div>
            <div className="col-flexbox-component">
              <div className="orderProcessingPara">
                Please do not click the close button.
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && !paymentSuccess && checkOutButtonSection}

      {paymentSuccess && thankYou}
    </Fragment>
  );
};

export default CheckOut;
