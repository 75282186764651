import React, { useState, useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import PollListener from "../logic/PollsLogic";
import PollStore from "./../logic/PollLogic";
import Poll from "./../components/Poll";
import PollWinner from "./../components/PollWinner";
import Fade from "./../components/Fade";
import CheckoutMain from "./../components/Checkout/CheckoutMain";
import MSCheckoutMain from "../components/MSCheckOut/MSCheckoutMain";
import IvsPlayer from "./../components/IVS_Player/IVS_Player";
import ChatLogic from "./../logic/ChatLogic";
import EventLogic, { EventStatus } from "./../logic/EventLogic";
import RunOfShowStore, { RunOfShowStates } from "./../logic/RunOfShowLogic";
import CometChatMessageListScreen from "./../libraries/CometChat/components/CometChatMessageListScreen";
import { useAuth } from "./../hooks/UseAuth";
import { Container, Grid } from "@material-ui/core";

const LivePage = (props) => {
  const auth = useAuth();
  const [runningPoll, setRunningPoll] = useState(null);
  const [checkOut, setCheckOut] = useState(false);
  const [completePoll, setCompletePoll] = useState(null);
  const [donatedAtCheckout, setDonatedAtCheckOut] = useState(null);
  const [chatGroup, setChatGroup] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [sequinSubmitted, setSequinSubmitted] = useState(false);
  const { width, height } = useWindowSize();
  const [chatIsMaximized, setChatIsMaximized] = useState(false);
  const showCheckOut = Boolean(
    checkOut && auth.userEventStatus(EventLogic.currentEvent.id, "checkedin")
  );
  const [cometChatSupportAgents, setCometChatSupportAgents] = useState([]);

  /*
  useEffect(() => {
    console.log("auth.user: ", auth.user.username);
  }, [auth]);

  useEffect(() => {
    console.log("cometChatSupportAgents: ", cometChatSupportAgents);
  }, [cometChatSupportAgents]);
*/

  useEffect(() => {
    let eventSub;
    let runningPollSub;
    let completePollSub;
    let runOfShowSub;
    let agentsID = [];

    eventSub = EventLogic.eventStream.subscribe((ev) => {
      // when an event gets set...
      if (!ev.id) return; // maybe show a "please sign in to an event message here"
      if (ev.status === EventStatus.preEvent) {
      } // go somewhere
      if (ev.status === EventStatus.postEvent) {
      } // go somewhere else

      setCurrentEvent(ev);
      ChatLogic.getGroup(ev.chatGroupGuid).then((group) => {
        // get the CometChat group associated with the event
        setChatGroup(group); // set the chat group
      });

      ChatLogic.getModerators(ev.chatGroupGuid).then(
        groupMembers => {
          //console.log("Group Member list fetched successfully:", groupMembers);
          for (var index = 0; index < groupMembers.length; index++) {
            agentsID.push(groupMembers[index].uid);
          }
          setCometChatSupportAgents(agentsID);
          //console.log("Group Member list fetched successfully:", agentsID);
        },
        error => {
          console.log("Group Member list fetching failed with exception:", error);
        }
      );

      if (runningPollSub) runningPollSub.unsubscribe();
      let pollObs = PollListener.runningPolls(ev.id);
      runningPollSub = pollObs.subscribe((runningPolls) => {
        // start listening for running polls for the event
        runningPolls.length
          ? setRunningPoll(runningPolls[0])
          : setRunningPoll(null);
      });

      if (completePollSub) completePollSub.unsubscribe();
      let comObs = PollListener.completePolls(ev.id);
      completePollSub = comObs.subscribe((completePolls) => {
        // start listening for complete polls for the event
        completePolls.length
          ? setCompletePoll(completePolls[0])
          : setCompletePoll(null);
      });

      if (runOfShowSub) runOfShowSub.unsubscribe();
      let showObs = RunOfShowStore.stream(ev.id);
      runOfShowSub = showObs.subscribe((exps) => {
        // start listening for run of show experiences for the event
        setCheckOut(exps.some(x => x.type === RunOfShowStates.checkOut));
      });
    });
    return async () => {
      if (runningPollSub) runningPollSub.unsubscribe();
      if (completePollSub) completePollSub.unsubscribe();
      if (runOfShowSub) runOfShowSub.unsubscribe();
      eventSub.unsubscribe();
    };
  }, []);

  const actionHandler = (action, item, count, ...otherProps) => {
    console.log("action: " + action);
  };

  const sequinSubmittedHandler = () => {
    setSequinSubmitted(true);
  };

  const [chatSettings, setChatSettings] = useState({
    main: {
      // show_call_notifications: false,
      send_photos_videos: false,
      send_files: false,
      send_emojis: true,
      allow_creating_polls: false,
      share_live_reactions: true,
      show_stickers: false,
      allow_message_reactions: false,
      enable_threaded_replies: false,
      enable_deleting_messages: false,
      enable_editing_messages: false,
      enable_sound_for_messages: false,
    },
  });

  const handleSoundForMessages = (onOrOff) => {
    //console.log("handleSoundforMessages", onOrOff);
    setChatSettings({
      ...chatSettings, main: {
        send_photos_videos: false,
        send_files: false,
        send_emojis: true,
        allow_creating_polls: false,
        share_live_reactions: true,
        show_stickers: false,
        allow_message_reactions: false,
        enable_threaded_replies: false,
        enable_deleting_messages: false,
        enable_editing_messages: false,
        enable_sound_for_messages: onOrOff,
      }
    });

  };
  /*
    useEffect(() => {
      console.log("chatsettings: ", chatSettings.main.enable_sound_for_messages);
      console.log(chatSettings);
    }, [chatSettings, chatSettings.main.enable_sound_for_messages]);
  */
  const chatTheme = {
    fontFamily: "'SJ Sans', 'Helvetica', 'Arial', sans-serif",
  };

  const renderChat = () => {
    return chatGroup ? (
      <CometChatMessageListScreen
        type={"group"}
        item={chatGroup}
        viewdetail={false}
        audiocall={false}
        videocall={false}
        loggedInUser={auth.user.chatUser}
        widgetsettings={chatSettings}
        actionGenerated={actionHandler}
        theme={chatTheme}
        isMaximized={chatIsMaximized}
        setIsMaximized={setChatIsMaximized}
        admins={cometChatSupportAgents}
        handleSoundForMessages={handleSoundForMessages}
      />
      /*
      <CometChatMessages chatWithGroup={chatGroup.guid}/>
       */
    ) :
      null;
  };

  const renderCheckout = () => {
    return (
      showCheckOut && (
        <Container className="checkout_container desktop">
          <Fade show={showCheckOut} classname="checkout-fade">
            {currentEvent?.requiresRegistration ? (
              <CheckoutMain setDonatedAtCheckOut={setDonatedAtCheckOut} />
            ) : (
              <MSCheckoutMain />
            )}
          </Fade>
        </Container>
      )
    );
  };

  const renderLiveStream = () => {
    return currentEvent ? (
      <IvsPlayer streamUrl={currentEvent.streamUrl} />
    ) : null;
  };

  const renderRunningPoll = () => {
    const store = runningPoll ? new PollStore(runningPoll.id) : null;
    const voterCount = chatGroup?.membersCount || 10;
    return (
      <Fade show={runningPoll}>
        <Container className="poll_container">
          <Poll store={store} numberOfVoters={voterCount} />
        </Container>
      </Fade>
    );
  };

  const renderWinner = () => {
    return (
      <Fade show={completePoll}>
        <Container className="poll_container winner">
          <PollWinner pollChoice={completePoll} />
        </Container>
      </Fade>
    );
  };

  const renderConfetti = () => {
    return completePoll || donatedAtCheckout ? (
      <Confetti style={{ zIndex: "11" }} width={width} height={height} />
    ) : null;
  };

  return (
    <>
      <Grid className="live-event-ui-grid-desktop">
        <Grid container className="live-event-ui" spacing={0}>
          {renderConfetti()}

          <Grid
            item
            sm={9}
            xs={12}
            className={
              chatIsMaximized ? "live-event-window-min" : "live-event-window"
            }
          >
            <div className="live-event-player">
              {renderLiveStream()}
              {renderRunningPoll()}
              {renderCheckout()}
              {renderWinner()}
            </div>

            <div className="live-event-banner">
              <h2 className="event-name">{currentEvent?.chatGroupName}</h2>
            </div>
          </Grid>

          <Grid
            item
            sm={3}
            xs={12}
            className={`live-event-chat ${showCheckOut ? "checkout" : "nocheckout"
              }`}
          >
            {renderChat()}
          </Grid>
        </Grid>
      </Grid>

      <div className="live-event-banner-mobile">
        <h2 className="event-name">{currentEvent?.chatGroupName}</h2>
      </div>
    </>
  );
};

export default LivePage;
