import React from "react";
import CreditCardAddressForm from "../CreditCardAddressForm";

const RsvpPage3 = ({ setAddress, address }) => {

  return (
    <>
      <header className="page-header">
        <div>
          <h2>Event RSVP</h2>
          <span className="divider"></span>
        </div>
      </header>
      <CreditCardAddressForm setAddress={setAddress} address={address} />
    </>
  );
};

export default RsvpPage3;
