import React, { useState, useEffect } from "react";
import { 
  //Button, 
  //Link, 
  Grid, 
  //Typography 
} from "@material-ui/core";

const DonationGifts = ({ total }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (total < 25) {
      setPrice("25");
      setTitle("Donate a No More Chemo Party");
      setMessage(
        "Patients and families celebrate special milestones, such as completing chemotherapy treatment."
      );
    } else if (total >= 25 && total < 50) {
      setPrice("50");
      setTitle("Donate Share of Platelet Transfusion");
      setMessage(
        "The platelet count of patients can drop to dangerously low levels during chemotherapy, so transfusions are essential for treatment."
      );
    } else if (total >= 50 && total < 75) {
      setPrice("75");
      setTitle("Donate Share of Oxygen Treatment");
      setMessage(
        "o	St. Jude patients may need oxygen to help their bodies function properly and aid in healing."
      );
    } else if (total >= 75 && total < 100) {
      setPrice("100");
      setTitle("Donate a Patient Wagon");
      setMessage(
        "Red wagons are the preferred mode of travel for our youngest patients."
      );
    } else if (total >= 100 && total < 250) {
      setPrice("250");
      setTitle("Donate Share of Chemotherapy");
      setMessage(
        "The advances made by St. Jude Children's Research Hospital have helped increase the effectiveness of chemotherapy and reduce the side effects for young patients with leukemia and solid tumors."
      );
    } else if (total >= 250 && total < 500) {
      setPrice("500");
      setTitle("Donate Share of Bone Marrow Treatment");
      setMessage(
        "The St. Jude Bone Marrow Transplantation Program is one of the largest pediatric programs in the world."
      );
    }
  }, [total]);

  return (
    <Grid direction="row" container className="checkout_donate_msg">
      <Grid item>
        {title} for ${price}
      </Grid>
      <Grid item>
        {message} Add an additional ${price - total} or more to purchase one.
      </Grid>
    </Grid>
  );
};

export default DonationGifts;
