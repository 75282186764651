import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useAuth } from "./../../../hooks/UseAuth";

const DisplayName = ({
  setDisplayName,
  displayName,
  setValid,
  setShowBackButton
}) => {
  const auth = useAuth();

  useEffect(() => {
    setShowBackButton(false);
    setValid(false);
  }, [])

  useEffect(() => {
    setDisplayName(auth.user?.chatUser?.name);
  }, [auth.user])

  useEffect(() => {
    setValid(displayName?.length > 0);
  })

  const handleChange = ({ target }) => {
    setDisplayName(target.value);
  };

  const renderView = () => {
    return (
      <Grid direction="column" container className="display-name-grid-container" wrap="nowrap" spacing={2}>
        <Grid item className="display-name-header-item">
          <h3 className="formgroup-header">
            Display Name
          </h3>
        </Grid>
        <Grid item style={{ paddingTop: "0px" }} className="display-name-info-grid-item">
          Display name will show in the chat.
        </Grid>
        <Grid item xs={12} className="display-name-textfield-grid-item">
          <TextField
            name="displayName"
            id="displayName"
            label="Your Display Name"
            value={displayName}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default DisplayName;
