import React, { useState, useEffect, Fragment } from "react";
import { Button, Grid, Paper, CircularProgress, Grow } from "@material-ui/core";
import * as Views from "./Views";
import SequinForm from "../SequinForm";
import { useAuth } from "../../hooks/UseAuth";
import EventLogic from "../../logic/EventLogic";
import client from "../../graphql/client";
import { useMutation } from "@apollo/client";
import * as QUERIES from "../../graphql/queries";
import * as MUTATIONS from "../../graphql/mutations";
import { useQuery } from "@apollo/client";

const MSCheckoutMain = (props) => {
  const auth = useAuth();
  const classname = props.classname;
  const [checkoutPage, setCheckoutPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);

  const handleSubmit = async (e) => {
    setCheckoutPage(checkoutPage + 1);
  };

  const renderHeader = () => {
    let header = "";

    switch (checkoutPage) {
      case 1:
        header = "One last opportunity to give before creating your sequin";
        break;
      case 2:
        header = "Leave Your Mark";
        break;
      default:
        break;
    }
    return header;
  };

  const renderButtonLabel = () => {
    let label = "";

    switch (checkoutPage) {
      case 1:
        label = "Create Sequin";
        break;
      default:
        break;
    }
    return label;
  };

  const renderNextButton = () => {
    return (
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={(e) => {
          handleSubmit(e);
        }}
      >
        {loading ? (
          <CircularProgress size={20} style={{ color: "white" }} />
        ) : (
          renderButtonLabel()
        )}
      </Button>
    );
  };

  const renderCheckout = () => {
    return (
      <Fragment>
        <Grid direction="row" container wrap="nowrap" className="checkout_grid">
          <Paper className="checkout_wrapper left" variant="outlined">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className="ms-checkout-grid-wrap"
            >
              <Grid item className="checkout-page-header-grid-item">
                <header className="page-header checkout_header">
                  <div>
                    <h2>{renderHeader()}</h2>
                    <span className="divider"></span>
                  </div>
                </header>
              </Grid>

              {checkoutPage === 1 && (
                <Grow in={true}>
                  <Views.BenevityLink
                    userID={auth.user.username}
                  />
                </Grow>
              )}

              {checkoutPage === 2 && (
                <Grow in={true}>
                  <SequinForm setShowBackButton={setShowBackButton} />
                </Grow>
              )}

              <Grid
                container
                direction="row-reverse"
                justify="space-between"
                className="check_out_button_container"
              >
                {checkoutPage !== 2 && renderNextButton()}
              </Grid>

            </Grid>
          </Paper>
          <Paper
            className="checkout_wrapper right shaded"
            variant="outlined"
          ></Paper>
        </Grid>
      </Fragment>
    );
  };

  return renderCheckout();
};

export default MSCheckoutMain;
