import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./App";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import ChatLogic from "./logic/ChatLogic";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

Amplify.configure(config);
async function chatInit() {
  await ChatLogic.init();
}
chatInit();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />,
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
