import * as QUERIES from "../graphql/queries";
import Api from "./ApiLogic";
import { map, distinctUntilChanged } from 'rxjs/operators';

export const RunOfShowStates = {
  livestream: "LIVESTREAM",
  livestreamPlaying: "LIVESTREAM_PLAYING",
  video: "VIDEO",
  videoPlaying: "VIDEO_PLAYING",
  forest: "FOREST",
  forestPlaying: "FOREST_PLAYING",
  userInteraction: "USERINTERACTION",
  checkOut: "CHECKOUT",
  blueLightSpecial: "BLUELIGHTSPECIAL"
}

export default new class {
  _streams = [];

  _transformResult = data => {
    let exps = data.data.getCurrentExperiences;

    if (!exps || exps.length == 0) return [{ type: RunOfShowStates.forest }];

    return exps.map(exp => {
      return { id: exp.id, type: exp.type, title: exp.title, url: exp.url };
    }).sort((exp1, exp2) => exp1.id - exp2.id);
  }

  _experiencesEqual = (exps1, exps2) => {
    for (let i = 0; i < exps1.length; i++) {
      if (exps1[i].id !== exps2[i].id) return false;
    }
    return true;
  }

  stream(eventId) {
    if (!this._streams[eventId]) {
      this._streams[eventId] = Api.pollingStream({ query: QUERIES.getCurrentExperiences, variables: { eventId: eventId } }).pipe(
        map(data => this._transformResult(data)),
        distinctUntilChanged((prev, curr) => {
          return (prev.length === curr.length) && this._experiencesEqual(prev, curr);
        })
      );
    }
    return this._streams[eventId];
  }

}()