import React, { useState, useEffect, useContext, createContext } from "react";
import { Auth } from "aws-amplify";
import { BehaviorSubject } from "rxjs";
import { useMutation } from "@apollo/client";
import client from "../graphql/client";
import * as QUERIES from "../graphql/queries";
import * as MUTATIONS from "../graphql/mutations";
import ChatLogic from "../logic/ChatLogic";
import AuthLogic, { AuthStatus } from "../logic/AuthLogic";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [status$] = useState(new BehaviorSubject({
    state: AuthStatus.authNone,
    data: null
  }))
  const [updateEventUserState] = useMutation(MUTATIONS.updateEventUserState);
  const [createEventUser] = useMutation(MUTATIONS.createEventUser);

  useEffect(() => {
    async function init() {
      await _initUser();
    }
    init();
  }, []);

  const _initUser = () => {
    return Auth.currentAuthenticatedUser()
      .then(async (user) => {
        user.events = await _getUserEvents(user.username);

        const currentUserInfoResults = await Auth.currentUserInfo();
        user.attributes = currentUserInfoResults.attributes;

        try {
          const chatUser = await ChatLogic.signIn(user.attributes.sub);
          if (chatUser) {
            user.chatUser = chatUser;
            setUser(user);
            AuthLogic.updateStatus(AuthStatus.authSuccess);
          }
          else throw Error(`No chat user associated with cognito user ${user.username}`);
        } catch (chatErr) {
          console.error(chatErr);
          AuthLogic.updateStatus(AuthStatus.authLogout);
        }
      })
      .catch((err) => {
        console.error(err);
        AuthLogic.updateStatus(AuthStatus.authLogout);
      });
  };

  const _getUserEvents = async (username) => {
    let query = {
      query: QUERIES.getUserEvents,
      variables: { uid: username },
      fetchPolicy: "network-only",
    };
    const { data, error } = await client.query(query);

    if (error) {
      console.error(error);
      return [];
    }
    return data?.getEventsByUser || [];
  };

  const _setUserEvents = async (username) => {
    const events = await _getUserEvents(username);
    setUser(u => {
      u.events = events;
      return u;
    });
  }

  const userEventStatus = (eventId, state) => {
    let event = user.events.find((e) => e.eventid === eventId);
    return event && event.state === state;
  };

  const signIn = async (username, password) => {
    try {
      AuthLogic.updateStatus(AuthStatus.authStart);
      let user = await Auth.signIn(username, password);
      if (!user) {
        console.error("Congnito login failed for user: " + username);
        AuthLogic.updateStatus(AuthStatus.authFail, "Login failed.");
        return;
      }
      console.log("USER is", user);

      const currentUserInfoResults = await Auth.currentUserInfo();
      user.attributes = currentUserInfoResults.attributes;
      console.log("USER is now", user);
      if (!user.attributes.email_verified) {
        console.log("attribute email not verified");
        AuthLogic.updateStatus(AuthStatus.authNotConfirmed);
        return;
      }

      user.events = await _getUserEvents(user.username);

      const chatUser = await ChatLogic.signIn(user.username);
      if (chatUser) {
        user.chatUser = chatUser;
        setUser(user);
        AuthLogic.updateStatus(AuthStatus.authSuccess);
      } else {
        console.error("Comet chat login failed with user " + user.username);
        AuthLogic.updateStatus(AuthStatus.authFail, "Login failed.");
      }
    } catch (error) {
      console.log(error);
      if (error.code === "UserNotConfirmedException") {
        console.log("user not confirmed");
        AuthLogic.updateStatus(AuthStatus.authNotConfirmed);
      } else {
        AuthLogic.updateStatus(AuthStatus.authFail, error.message);
      }
    }
  };

  const signInNoPassword = async (username) => {
    try {
      AuthLogic.updateStatus(AuthStatus.authStart);
      let user = await Auth.signIn(username);
      if (!user) {
        console.error("Congnito login (no password) failed for user: " + username);
        AuthLogic.updateStatus(AuthStatus.authFail, "Login failed.");
        return null;
      }
      console.log("USER is", user);
      setUser(user);
      return user;

    } catch (error) {
      console.log(error);
      AuthLogic.updateStatus(AuthStatus.authFail, error.message);
      return null;
    }
  };

  const sendChallenge = async (code) => {
    try {
      const authUser = await Auth.sendCustomChallengeAnswer(user, code);
      if (!authUser.signInUserSession) throw Error("Invalid code");

      const currentUserInfoResults = await Auth.currentUserInfo();
      const chatUser = await ChatLogic.signIn(authUser.attributes.sub);
      if (chatUser) {
        setUser(u => {
          u.attributes = currentUserInfoResults.attributes;
          u.chatUser = chatUser;
          return u;
        });
        AuthLogic.updateStatus(AuthStatus.authSuccess);
      } else {
        console.error("Comet chat login failed with user " + user.username);
        AuthLogic.updateStatus(AuthStatus.authFail, "Login failed.");
      }
    } catch (error) {
      console.error(error);
      throw error;
      // check error to see what happens when < 3 incorrect attempts vs > 3
    }
  }

  const signUp = (userData) => {
    return Auth.signUp(userData).then(async (user) => {
      await ChatLogic.register(user.userSub, userData.attributes.name);
    });
  };

  const signUpNoPassword = async (userData) => {
    try {
      const user = await Auth.signUp(userData);
      await ChatLogic.register(user.userSub, userData.attributes.name)
    }
    catch (err) {
      if (err.code === "UsernameExistsException") {
        console.log(err)
      }
      else throw err;
    }
  }

  const registerUserToEvent = (event) => {
    return createEventUser({
      variables: {
        input: { eventid: event.id, useridentifier: user.username },
      }
    }).then(async () => {
      await _setUserEvents(user.username);
      await ChatLogic.joinGroup(event.chatGroupGuid);
    });
  };

  const updateUserEventStatus = (eventId, state) => {
    const input = {
      useridentifier: user.username,
      eventid: eventId,
      state: state,
    };
    return updateEventUserState({ variables: { input: input } }).then(
      async () => {
        await _setUserEvents(user.username);
      }
    );
  };

  const updateChatDisplayName = (displayName) => {
    let uid = user.username;
    return ChatLogic.updateUser(uid, displayName);
  };

  const signOut = async () => { // may have to re-arrange order (set status first?)
    try {
      await Auth.signOut();
      await ChatLogic.signOut();
    }
    catch (err) {
      console.error(err);
    }
    setUser(false);
    AuthLogic.updateStatus(AuthStatus.authLogout);
  };

  const userNameAvailable = (userName) => {
    // this is an Amplify hack because there is currently no 'userExists' method in the Cognito API
    return Auth.confirmSignUp(userName, "0000", { forceAliasCreation: false })
      .then((data) => console.log(data))
      .catch((err) => {
        switch (err.code) {
          case "UserNotFoundException":
            return true;
          default:
            return false;
        }
      });
  };

  const updateUser = async (attributes) => {
    if (user) {
      return Auth.updateUserAttributes(user, attributes).then(async () => {
        await getUpdatedUser();
      });
    } else {
      console.log("user is not defined");
    }
  };

  const currentUserInfo = () => {
    return Auth.currentUserInfo();
  };

  const verifyAttribute = (verificationCode) => {
    return Auth.verifyCurrentUserAttributeSubmit(
      "email",
      verificationCode
    ).then(async () => {
      if (user) {
        await getUpdatedUser();
      }
    });
  };

  const getUpdatedUser = async () => {
    if (user) {
      const results = await Auth.currentUserInfo();
      user.attributes = results.attributes;
    } else {
      console.log("user is not defined");
    }
  };

  const resendEmailVerification = () => {
    return Auth.verifyCurrentUserAttribute("email");
  };

  const changePassword = (oldPassword, newPassword) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  };

  const confirmSignup = (userName, confirmationCode) => {
    return Auth.confirmSignUp(userName, confirmationCode);
  };

  const resendSignup = (userName) => {
    return Auth.resendSignUp(userName);
  };

  const forgotPassword = (userName) => {
    return Auth.forgotPassword(userName);
  };

  const forgotPasswordSubmit = (userName, confirmationCode, password) => {
    return Auth.forgotPasswordSubmit(userName, confirmationCode, password);
  };

  const sendPasswordResetEmail = (email) => { };

  const confirmPasswordReset = (code, password) => { };

  return {
    status$,
    get currentState() {
      return status$.value;
    },
    user,
    signIn,
    signInNoPassword,
    sendChallenge,
    signUp,
    signUpNoPassword,
    registerUserToEvent,
    updateUserEventStatus,
    updateChatDisplayName,
    userEventStatus,
    signOut,
    userNameAvailable,
    updateUser,
    currentUserInfo,
    verifyAttribute,
    getUpdatedUser,
    resendEmailVerification,
    changePassword,
    confirmSignup,
    resendSignup,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}
