import React, { useState, useEffect, Fragment } from "react";
import DonatePopper from "./DonatePopUp";
import DonatePopperAnchor from "./DonatePopperAnchor";
import DonateAmountWidget from "./DonateAmountWidget";

const DonateButtonAndWidget = (props) => {
  const [widgetIsOpen, setWidgetIsOpen] = useState(false);
  const [donateIsOpen, setDonateIsOpen] = useState(false);

  function HandlePopperOpen(isOpen, popperName) {
    useEffect(() => {
      if (isOpen) {
        if (popperName === "amountWidget") {
          //console.log("widget is open");
          setWidgetIsOpen(true);
        }
        if (popperName === "donateButton") {
          //console.log("donate is open");
          setDonateIsOpen(true);
        }
      } else {
        if (popperName === "amountWidget") {
          //console.log("widget is closed");
          setWidgetIsOpen(false);
        }
        if (popperName === "donateButton") {
          //console.log("donate is closed");
          setDonateIsOpen(false);
        }
      }
    }, [isOpen, popperName]);
  }

  return (
    <Fragment>
      <DonatePopper
        DonatePopperAnchor={() => DonatePopperAnchor(props)}
        firstPage="buttonToggle"
        popperIsOpen={HandlePopperOpen}
        otherPopperIsOpen={widgetIsOpen}
        popperName="donateButton"
      />
      <DonatePopper
        DonatePopperAnchor={() => DonateAmountWidget(props)}
        firstPage="manageDonations"
        popperIsOpen={HandlePopperOpen}
        otherPopperIsOpen={donateIsOpen}
        popperName="amountWidget"
      />
    </Fragment>
  );
};

export default DonateButtonAndWidget;
