import React, { useEffect } from "react";
import CreditCardAddressForm from "./../../CreditCardAddressForm";
import { Grid, Typography } from "@material-ui/core";

const CheckoutNewCCAddress = ({
  setAddress,
  address,
  setValid,
  setShowBackButton,
  saveCardError,
}) => {
  useEffect(() => {
    setShowBackButton(true);
  }, []);

  const renderView = () => {
    return (
      <>
        <CreditCardAddressForm
          setAddress={setAddress}
          address={address}
          setValid={setValid}
        ></CreditCardAddressForm>
        {saveCardError && (
          <Grid item container className="error-message-grid-container" alignItems="center" xs={12}>
            <Typography
              style={{ color: "red" }}
              variant="caption"
              className="sub-header"
            >
              Something went wrong. Please retry.
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  return renderView();
};

export default CheckoutNewCCAddress;
