import React, { useState, useEffect } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import * as Views from "./Views";
import { useAuth } from "../../hooks/UseAuth";
import { Validate } from "./Validate";
import { Redirect } from "react-router";
//import { useLocation } from "react-router-dom";

const RegistrationMain = (props) => {
  const auth = useAuth();

  const [userInfo, setUserInfo] = useState({
    fullName: "",
    userName: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [registrationPage, setRegistrationPage] = useState(1);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [valid, setValid] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [header, setHeader] = useState("");
  const [authState, setAuthState] = useState({});
  const [authData, setAuthData] = useState({});
  const [userExistsNotConfirmed, setUserExistsNotConfirmed] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState("");
  useEffect(() => {
    const sub = auth.status$.subscribe((state) => {
      setAuthState(state.state);
      setAuthData(state.data);
    });
    return () => sub.unsubscribe();
  }, [auth.status$]);
  //let location = useLocation();
  //let { from } = location.state || { from: { pathname: "/forest" } };
  /*
  useEffect(() => {
    console.log("authState", authState);
  }, [authState]);
  */
  const [error, setError] = useState({
    fullName: false,
    userName: false,
    password: false,
    confirmPassword: false,
    phone: false,
    userExists: false,
  });

  useEffect(() => {
    let allValid =
      !error.userName &&
      !error.fullName &&
      !error.password &&
      !error.confirmPassword &&
      !error.phone &&
      !error.userExists;
    setValid(allValid);
  }, [
    error.userName,
    error.fullName,
    error.password,
    error.confirmPassword,
    error.phone,
    error.userExists,
  ]);

  useEffect(() => {
    if (typeof props.props.location.state !== "undefined") {
      //console.log("props is ", props);
      //console.log("auth is ", auth);

      const { username, password } = props.props.location.state;
      setUserExistsNotConfirmed(true);
      setUserInfo({
        userName: username,
        password: password,
      });
      setRegistrationPage(3);
    }
  }, [props.props.location.state]);

  const handleSubmit = async (e) => {
    let buttonValue = e.target.innerText;
    if (registrationPage === 1) {
      const errors = await Validate(auth, userInfo, setError, setValid);
      const hasErrors = Boolean(errors.length);
      if (hasErrors) {
        return;
      }
    }
    if (registrationPage < 3) {
      setRegistrationPage(
        buttonValue === "Next" ? registrationPage + 1 : registrationPage - 1
      );
    } else if (registrationPage === 3) {
      handleConfirmAccount();
    }
  };

  const handleConfirmAccount = async (e) => {
    //console.log("confirmation Code", confirmationCode);
    const confirmationCodeWOSpaces = confirmationCode.replace(/\s/g, "");

    if (userExistsNotConfirmed && auth.user !== null) {
      console.log("auth.user is", auth.user);
      console.log("auth is ", auth);
      await auth
        .verifyAttribute(confirmationCodeWOSpaces)
        .then(async () => {
          console.log("user confirmed");
          await auth.signIn(userInfo.userName, userInfo.password);
          await auth.getUpdatedUser;
          setRegistrationPage(null);
        })
        .catch((err) => {
          console.log(err);
          setConfirmationCodeError(err.message);
        });
    } else {
      console.log("AUTH.user is not null", auth);
      await auth
        .confirmSignup(userInfo.userName, confirmationCodeWOSpaces)
        .then(async () => {
          console.log("user confirmed");
          await auth.signIn(userInfo.userName, userInfo.password);
          console.log("user successfuly signed in");
          setRegistrationPage(registrationPage + 1);
        })
        .catch((err) => {
          console.log(err);
          setConfirmationCodeError(err.message);
        });
    }
  };

  const renderNextButton = () => {
    return showNextButton ? (
      valid ? (
        <Button
          id="registrationNext"
          size="large"
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmit(e)}
        >
          Next
        </Button>
      ) : (
        <Button
          id="registrationNext"
          disabled
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Next
        </Button>
      )
    ) : null;
  };

  const renderBackButton = () => {
    return showBackButton ? (
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={(e) => handleSubmit(e)}
      >
        Back
      </Button>
    ) : null;
  };

  const renderRegistration = () => {
    return (
      <Grid direction="row" container wrap="nowrap">
        <Paper className="checkout_wrapper left" variant="outlined">
          <Grid direction="row" container>
            <Grid item xs={12}>
              <header className="page-header checkout_header">
                <div>
                  <h2>{header}</h2>

                  <span className="divider"></span>
                </div>
              </header>
            </Grid>
            {registrationPage === 1 ? (
              <Views.RegContact
                setUserInfo={setUserInfo}
                userInfo={userInfo}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
                setHeader={setHeader}
                error={error}
                setError={setError}
              />
            ) : registrationPage === 2 ? (
              <Views.RegLegal
                setUserInfo={setUserInfo}
                userInfo={userInfo}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
                setHeader={setHeader}
              />
            ) : registrationPage === 3 ? (
              <Views.RegConfirm
                setUserInfo={setUserInfo}
                userInfo={userInfo}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
                setHeader={setHeader}
                confirmationCode={confirmationCode}
                setConfirmationCode={setConfirmationCode}
                userExistsNotConfirmed={userExistsNotConfirmed}
                confirmationCodeError={confirmationCodeError}
              />
            ) : registrationPage === 4 ? (
              <Views.RegConfirmSuccess
                userInfo={userInfo}
                setHeader={setHeader}
                setShowBackButton={setShowBackButton}
                setShowNextButton={setShowNextButton}
                userExistsNotConfirmed={userExistsNotConfirmed}
              />
            ) : registrationPage === null ? (
              <Redirect to={"/forest"} />
            ) : null}

            <Grid
              container
              xs={12}
              direction="row-reverse"
              justify="space-between"
              style={{ paddingTop: "15px" }}
            >
              {renderNextButton()}
              {renderBackButton()}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className="checkout_wrapper right shaded"
          variant="outlined"
        ></Paper>
      </Grid>
    );
  };

  return renderRegistration();
};

export default RegistrationMain;
