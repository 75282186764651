import { createMuiTheme } from "@material-ui/core/styles";

//import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "#1874dc",
      main: "#38acf4",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    secondary: {
      light: "#c10f3a",
      main: "#c10f3a",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    blue: {  // should this be primary?
      light: "#1874dc",
      main: "#1874dc",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    third: {
      light: "#0f71c1",
      main: "#0f71c1",
    },
    background: {
      color: "#333",
    },
  },
  typography: {
    fontFamily: '"SJ Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontWeight: "bold",
      letterSpacing: "-2px",
    },
    h2: {
      fontWeight: "bold",
      letterSpacing: "-2px",
    },
    button: {
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
});

export default theme;
