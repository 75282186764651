import React, { useState, useEffect } from "react";
import { TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, Grid, Container, Paper } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useAuth } from "../hooks/UseAuth";
import EventLogic from "../logic/EventLogic";
import EventAuthLogic from "../logic/EventAuthLogic";
import * as QUERIES from "../graphql/queries";

const EventList = () => {
  const { loading: eventsLoading, error: eventsError, data: eventsData } = useQuery(QUERIES.getEvents);
  const [joinEventOpen, setJoinEventOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [event, setEvent] = useState({});
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    const sub = EventAuthLogic.stream.subscribe((o) => {
      if (o) {
        console.log("auth = ", o.auth);
        console.log("event = ", o.event);
      }
    })
    return () => {
      console.log("unsubscribing...");
      sub.unsubscribe();
    }
  }, [])

  const handleSetEventClick = async (ev, event) => {
    ev.preventDefault();
    await EventLogic.setEventById(event.id);
    history.push(`/live`);
  }

  const handleJoinClick = async (ev, event) => {
    ev.preventDefault();
    await EventLogic.setEventById(event.id);
    if (auth.user) {
      if (!auth.user.events?.some(e => e.eventid == event.id)) {
        await auth.registerUserToEvent(event);
      }
      history.push(`/live`);
      return;
    }
    else {
      setEvent(event)
      setJoinEventOpen(true);
    }

  }

  const handleJoinEventClose = () => {
    setJoinEventOpen(false);
    setEvent(null);
  }

  const handleUserDataChange = (event) => {
    const { id, value } = event.target;

    setUserData(user => ({
      ...user, [id]: value
    }))
  }

  const handleSendClick = async () => {
    const user = {
      username: userData.email.toLowerCase(),
      password: Date.now().toString(),
      attributes: {
        name: userData.name,
        email: userData.email.toLowerCase()
      }
    }
    try {
      await auth.signUpNoPassword(user);
      await auth.signInNoPassword(user.username);
    } catch (err) {
      console.error(err);
    }
  }

  const handleReSendClick = async () => {
    try {
      await auth.signInNoPassword(userData.email.toLowerCase());
    } catch (err) {
      console.error(err);
    }
  }

  const handleSendCodeClick = async () => {
    try {
      await auth.sendChallenge(userData.passcode);
      await auth.registerUserToEvent(event);
      // history.push(`/live`);                  MAKE SURE TO UNCOMMENT!!!
    } catch (err) {
      console.error(err);
    }
  }

  const listEvents = () => {
    if (eventsLoading) return null;
    if (eventsError) return `Error! ${eventsError}`;
    const sorted = [...eventsData.getEvents].sort((a, b) => a.id - b.id);
    return (
      <TableBody>
        {sorted.map((e) => {
          const action = (<Button variant="outlined" size="small" onClick={(event) => handleJoinClick(event, e)}>Join</Button>);
          const subAction = (<Button variant="outlined" size="small" onClick={(event) => handleSetEventClick(event, e)}>Set Event</Button>);
          const startDate = DateTime.fromMillis(parseInt(e.date_and_time), { zone: "utc" });
          const startFormatted = startDate.toLocal().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          return (
            <TableRow key={e.id} hover={true}>
              <TableCell style={{ width: "20%" }} component="th" scope="row">{e.label}</TableCell>
              <TableCell style={{ width: "30%" }}>{startFormatted}</TableCell>
              <TableCell>{e.chatgroupname}</TableCell>
              <TableCell style={{ width: "20%" }}>{action}</TableCell>
              {/* <TableCell style={{ width: "10%" }}>{subAction}</TableCell> */}
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  return (
    <div>
      <Container maxWidth="md">
        <div className="handle" style={{ width: "100%", height: "10px", backgroundColor: "#E0E0E0", borderTopRightRadius: "4px", borderTopLeftRadius: "4px", cursor: "move" }} />
        <Paper variant="outlined" style={{ padding: "0.5rem 1rem 1rem", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "50px", flexGrow: "1" }}>
                  <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "700" }}>Name</TableCell>
                          <TableCell style={{ fontWeight: "700" }}>Date</TableCell>
                          <TableCell style={{ fontWeight: "700" }}>Chat Group</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      {listEvents()}
                    </Table>
                    <div style={{ paddingTop: "10px" }}>
                      <Button variant="outlined" size="small" onClick={async () => await auth.signOut()}>Sign Out</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Dialog open={joinEventOpen} onClose={handleJoinEventClose}>
        <DialogTitle>Join {event?.label}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Full Name"
            variant="outlined"
            onChange={handleUserDataChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            variant="outlined"
            onChange={handleUserDataChange}
            fullWidth
            required
          />
          <div style={{ display: "flex", alignItems: "center", paddingTop: "8px", justifyContent: "flex-end" }}>
            <Button variant="outlined" size="large" onClick={handleSendClick} color="primary">Send</Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", paddingTop: "48px", justifyContent: "space-between" }}>
            <TextField
              margin="dense"
              id="passcode"
              label="Pass Code"
              onChange={handleUserDataChange}
              variant="outlined"
              required
            />
            <Button variant="outlined" size="large" onClick={handleSendCodeClick} color="primary">Join</Button>
          </div>
          <div>
            <Button variant="outlined" size="large" onClick={handleReSendClick} color="primary">Resend Code</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EventList;
