import React, { useRef, useEffect } from "react";
import { Route, Redirect } from "react-router";
import EventAuthLogic from "./../logic/EventAuthLogic";
import { AuthStatus } from "./../logic/AuthLogic";
import { useForceUpdate } from "../hooks/UseForceUpdate";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useRef(null);
  const event = useRef(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let sub = EventAuthLogic.stream.subscribe((o) => {
      if (o) {
        auth.current = o.auth;
        event.current = o.event;
        forceUpdate();
      }
    })
    return () => sub.unsubscribe();
  }, [forceUpdate])

  const renderRoute = ({ location }) => {
    switch (auth.current?.state) {
      case AuthStatus.authFail:
      case AuthStatus.authLogout:
      case AuthStatus.authNotConfirmed:
        let path = event.current?.requiresRegistration || event.current?.requiresRegistration === undefined ? `/login` : `/event/${event.current.uuid}`;
        return (
          <Redirect
            to={{
              pathname: path,
              state: { from: location }
            }} />
        )
      case AuthStatus.authSuccess:
        return (children);
      default:
        return null;
    }
  }

  return (
    <>
      <Route
        {...rest}
        render={renderRoute}
      />
    </>
  );
};

export default PrivateRoute;
