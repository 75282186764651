import { Button } from "@material-ui/core";
import classNames from "classnames";

const DonatePopperAnchor = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      className={classNames(props.donateBtnClassName, { "live-btn": props.isLive })}
    >
      Donate
    </Button>
  );
};

export default DonatePopperAnchor;
