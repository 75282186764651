import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "./App.scss";
import apolloClient from "./graphql/client";
import RsvpPage from "./pages/page_rsvp";
import HomePage from "./pages/page_home";
import LoginPage from "./pages/page_login";
import SignUpPage from "./pages/page_signup";
import CheckinPage from "./pages/page_checkin";
import ForgotPasswordPage from "./pages/page_forgot-pass";
import ForestPage from "./pages/page_forest";
import LoadSequinsPage from "./pages/page_load_sequins";
import LivePage from "./pages/page_live";
import ControlPage from "./pages/page_control";
import VideoPage from "./pages/page_video";
import NavBar from "./components/NavBar";
import PrivateRoute from "./components/privateRoute";
import { ProvideAuth } from "./hooks/UseAuth";
import EventList from "./components/EventList";
import IvsPlayer from "./components/IVS_Player/IVS_Player";
import EventPortal from "./components/EventPortal";
import EventLogic from "./logic/EventLogic";


const DynamicNavRoute = ({
  path,
  exact,
  component: Component,
  layout,
  ...rest
}) => {
  const [currentEvent, setCurrentEvent] = useState(null);
  useEffect(() => {
    let eventSub;
    eventSub = EventLogic.eventStream.subscribe((ev) => {
      setCurrentEvent(ev);
    });
    return () => eventSub.unsubscribe();
  }, []);
  

  switch (layout) {
    case "LIVE_NAV": {
      return (
        <div className="ui-layer-live">
          <header className="live-nav-bar-header">
            <NavBar isLive={true} includeDonate={true} includeForestBtn={true} includeUserAccount={true} includeJoinEventBtn={false}  includesSignUpLogin={false} isNoRegEvent={!currentEvent?.requiresRegistration} />
          </header>
          <PrivateRoute {...rest} path={path} exact={exact}>
            <LivePage />
          </PrivateRoute>
        </div>
      );
    }
    case "FOREST_NAV": {
      return (
        <div className="ui-layer">
          <header>
            <NavBar isLive={false} includeDonate={false} includeForestBtn={false} includeUserAccount={true} includeJoinEventBtn={false} includesSignUpLogin={true} isNoRegEvent={!currentEvent?.requiresRegistration} />
          </header>
          <Route {...rest} path={path} exact={exact} component={Component} />
        </div>
      );
    }
    case "MAIN_NAV": {
      return (
        <div className="ui-layer">
          <header className="main-nav-bar-header">
            <NavBar isLive={false} includeDonate={false} includeForestBtn={false} includeJoinEventBtn={false}  includesSignUpLogin={false} includeUserAccount={false} isNoRegEvent={!currentEvent?.requiresRegistration} />
          </header>
          <Route {...rest} path={path} exact={exact} component={Component} />
        </div>
      );
    } 
    default:
      return;
  }
};

const App = (props) => {
  return (
    <ApolloProvider client={apolloClient}>
      <ProvideAuth>
        <div className="App">
          <BrowserRouter basename={process.env.BASENAME}>
            <Switch>
              {/* <PrivateRoute
                path="/rsvp"
                exact
                component={RsvpPage}
                isAuthenticated={authUser}
                /> */}
              {/* <PrivateRoute
                path="/forest"
                exact
                component={ForestPage}
                isAuthenticated={authUser}
                /> */}
              {/* <PrivateRoute
                path="/account"
                exact
                component={AccountPage}
                isAuthenticated={authUser}
                /> */}

              <DynamicNavRoute
                path="/"
                exact
                component={HomePage}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/login"
                exact
                render={(props) => <LoginPage {...props} />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/signUp"
                exact
                render={(props) => <SignUpPage {...props} />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/forest"
                exact
                render={(props) => <ForestPage {...props} />}
                layout="FOREST_NAV"
              />
              <DynamicNavRoute
                path="/checkin"
                exact
                render={(props) => <CheckinPage {...props} />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/forgotPassword"
                exact
                render={() => <ForgotPasswordPage />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/rsvp"
                exact
                render={(props) => <RsvpPage {...props} />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute path="/live" exact layout="LIVE_NAV" />
              <DynamicNavRoute
                path="/video/:vkey"
                exact
                render={(props) => <VideoPage {...props} />}
                layout="FOREST_NAV"
              />
              <DynamicNavRoute
                path="/forest/:seq"
                exact
                render={(props) => <ForestPage {...props} />}
                layout="FOREST_NAV"
              />

              <DynamicNavRoute
                path="/control"
                exact
                render={() => <ControlPage />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/test"
                exact
                render={() => <EventList />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/vidtest"
                exact
                render={() => <IvsPlayer streamUrl="https://441f4502a308.us-east-1.playback.live-video.net/api/video/v1/us-east-1.684874039486.channel.PJm7Kn1TDQSV.m3u8" />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/event/:uuid"
                exact
                render={(props) => <EventPortal {...props} />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/loadsequins"
                exact
                render={() => <LoadSequinsPage />}
                layout="MAIN_NAV"
              />
              <DynamicNavRoute
                path="/support"
                exact
                layout="SUPPORT"
              />
            </Switch>
            <div className="background-layer"></div>
          </BrowserRouter>
        </div>
      </ProvideAuth>
    </ApolloProvider>
  );
};

export default App;
