import client from "../graphql/client";
import Api from "./ApiLogic";
import * as QUERIES from "../graphql/queries";
import { BehaviorSubject, from } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

const pollingInterval = 500;

export const GatewayStatus = {
  loading: "gateway.status.loading",
  error: "gateway.status.error",
  ready: "gateway.status.ready"
}

export default new class {
  constructor() {
    this._regions = [
      {
        name: "region1",
        treeNames: ["Curve.341", "Curve.369"],
        trackName: "introToRegionEmpty_1",
        meshes: []
      },
      {
        name: "region2",
        treeNames: ["Curve.357", "Curve.354"],
        trackName: "introToRegionEmpty_2",
        meshes: []
      },
      {
        name: "region3",
        treeNames: ["Curve.376", "Curve.332"],
        trackName: "introToRegionEmpty_3",
        meshes: []
      },
      {
        name: "region4",
        treeNames: ["Curve.374"],
        trackName: "introToRegionEmpty_4",
        meshes: []
      }
    ];
    this._status = new BehaviorSubject({
      status: GatewayStatus.loading
    });

    const transformResult = (data) => {
      let sequins = data.data.listSequinsByRegion;

      return sequins.map(s => {
        return {
          id: s.id,
          name: s.external_name,
          state: s.state,
          claimedBy: s.claimedby,
          message: s.message
        }
      });
    }

    client.query({ query: QUERIES.allRegions }).then(({ data, error }) => {
      if (error) {
        console.error(error);
        this._status.next(
          Object.assign(this.currentStatus, {
            status: GatewayStatus.error
          })
        )
        return;
      }
      data.listRegions.forEach(r => {
        let query = { query: QUERIES.sequinsByRegion, variables: { regionId: r.id } };
        let stream = Api.pollingStream(query, pollingInterval).pipe(map(data => transformResult(data)), distinctUntilChanged());
        let region = this._regions.find(reg => reg.name === r.external_name);
        if (region) region.stream = stream;
      })
      this._status.next(
        Object.assign(this.currentStatus, {
          status: GatewayStatus.ready
        })
      )
    })
  }

  allRegions = () => this._regions;

  getRegion = (name) => {
    let region = this._regions.find(r => {
      return r.name === name;
    });
    if (region) return region;

    console.error(`No region found for label ${name}`);
  }

  getRegionFromMesh = (meshName) => {
    let regionPos = meshName.indexOf("region");

    if (regionPos > -1) {
      return this._regions.find((r) => r.name === meshName.substring(regionPos));
    }

    return this._regions.find((r) => r.treeNames.includes(meshName));
  };

  get currentStatus() {
    return this._status.value;
  }

  get statusStream() {
    return this._status;
  }
}()