import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Auth } from "aws-amplify";
import { useAuth } from "../hooks/UseAuth";
import AuthLogic, { AuthStatus } from "../logic/AuthLogic";
import { withApollo } from "@apollo/client/react/hoc";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";

const ForgotPassword = ({ client }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [accountVerified, setAccountVerified] = useState(false);
  const [success, setSuccess] = useState(false);
  const [accountVerifyError, setAccountVerifyError] = useState("");
  const [resetError, setResetError] = useState("");
  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleClickShowLoginPassword = () =>
    setShowLoginPassword(!showLoginPassword);
  const handleMouseDownLoginPassword = () =>
    setShowLoginPassword(!showLoginPassword);

  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/forest" } };
  const auth = useAuth();
  const [authState, setAuthState] = useState({});
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    const sub = AuthLogic.authStatus.subscribe((state) => {
      setAuthState(state.state);
      setAuthData(state.data);
    });
    return () => sub.unsubscribe();
  }, [AuthLogic.authStatus]);

  function validateEmailForm() {
    return username.length > 0;
  }

  function validateResetForm() {
    return (
      confirmationCode.length > 0 &&
      password.length > 0 &&
      confirmPassword === password
    );
  }

  function validPassword(password) {
    let password_rgx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&-]).{8,20}$/;
    return password_rgx.test(password);
  }

  const RequestPasswordResetEmail = () => {
    Auth.forgotPassword(username)
      .then((data) => {
        //console.log("success sending out email");
        setAccountVerifyError("");
        setAccountVerified(true);
      })
      .catch((err) => {
        //console.log(err);
        setAccountVerifyError(err.message);
      });
  };

  const handlePasswordBlur = ({ target }) => {
    setError((prevState) => ({
      ...prevState,
      password: !validPassword(target.value),
      confirmPassword: confirmPassword
        ? target.value !== confirmPassword
        : false,
    }));
  };

  const handleConfirmPasswordBlur = ({ target }) => {
    setError((prevState) => ({
      ...prevState,
      confirmPassword: target.value !== password,
    }));
  };

  const confirmandReset = () => {
    Auth.forgotPasswordSubmit(username, confirmationCode, password)
      .then(() => {
        //console.log("successfully reset password");
        setResetError("");
        setSuccess(true);
      })
      .catch((err) => {
        //console.log(`Error resetting password - ${err}`);
        setSuccess(false);
        setResetError(err.message);
      });
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    RequestPasswordResetEmail();
  };

  const handleSubmitReset = (e) => {
    e.preventDefault();
    confirmandReset();
  };

  const handleChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    }

    if (e.target.name === "password") {
      setPassword(e.target.value);
    }

    if (e.target.name === "loginPassword") {
      setLoginPassword(e.target.value);
    }

    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }

    if (e.target.name === "confirmationCode") {
      setConfirmationCode(e.target.value.replace(/\s/g, ""));
    }
  };

  function renderEmailForm() {
    return (
      <Grid
        className="forgot_password_grid_container"
        direction="row"
        container
        wrap="nowrap"
      >
        <Paper className="checkout_wrapper left" variant="outlined">
          <Grid
            className="forgot_password_main_grid_container"
            direction="column"
            wrap="nowrap"
            container
          >
            <Grid item className="forgot_password_title">
              <header className="page-header forgotpasswordheader">
                <div>
                  <h2>Forgot Password</h2>
                  <span className="divider"></span>
                </div>
              </header>
            </Grid>
            <Grid
              className="forgot_password_content"
              direction="column"
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <div className="forgot-password-instruction">
                  Please enter your email address. You will receive a six digit
                  code to create a new password via your email.
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="username"
                  placeholder="Email Address"
                  variant="outlined"
                  onChange={handleChange}
                  value={username}
                  inputProps={{ "aria-label": "description" }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} className="login_error">
                <Typography color="error" variant="caption" display="block">
                  {accountVerifyError}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              className="forgot-password-next-button-grid"
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmitEmail}
                disabled={!validateEmailForm()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className="checkout_wrapper right shaded"
          variant="outlined"
        ></Paper>
      </Grid>
    );
  }

  function renderResetForm() {
    return (
      <Grid
        direction="row"
        container
        className="renderResetFormGrid"
        wrap="nowrap"
      >
        <Paper className="checkout_wrapper left" variant="outlined">
          <Grid
            direction="row"
            container
            className="forgot_password_verification_grid_container"
          >
            <Grid item xs={12} className="forgot_password_reset_title">
              <header className="page-header forgotpasswordheader">
                <div>
                  <h2>Verification Code & New Password</h2>
                  <span className="divider"></span>
                </div>
              </header>
            </Grid>

            <Grid
              direction="column"
              container
              wrap="nowrap"
              spacing={2}
              className="renderResetMainContentGrid"
            >
              <Grid item xs={12}>
                <div className="forgot-password-instruction">
                  Please enter your six digit code you received via your email
                  and your new password. Password must contain 8 to 20
                  characters with at least one digit, one upper case letter, one
                  lower case letter and one special symbol (“#$^+=!*()@%&-”).
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="confirmationCode"
                  placeholder="Six Digit Code"
                  variant="outlined"
                  value={confirmationCode}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "description" }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handlePasswordBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  value={password}
                  error={error.password}
                  helperText={
                    error.password
                      ? "Password must contain 8 to 20 characters with at least one digit, one upper case letter, one lower case letter and one special symbol (“#$^+=!*()@%&-”)"
                      : null
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  placeholder="Repeat New Password"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleConfirmPasswordBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={confirmPassword}
                  error={error.confirmPassword}
                  helperText={
                    error.confirmPassword ? "Passwords do not match" : null
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} className="login_error">
                <Typography color="error" variant="caption" display="block">
                  {resetError}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              className="forgot-password-next-button-grid"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmitReset}
                disabled={!validateResetForm()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          className="checkout_wrapper right shaded"
          variant="outlined"
        ></Paper>
      </Grid>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await client.resetStore();
    await auth.signIn(username.toLowerCase(), loginPassword);
  };

  const renderSuccess = () => {
    switch (authState) {
      case AuthStatus.authSuccess:
        //console.log("successfully logged in");
        return <Redirect to={from.pathname} />;
      default:
        return (
          <Grid
            direction="row"
            container
            wrap="nowrap"
            className="success-grid-container"
          >
            <Paper className="checkout_wrapper left" variant="outlined">
              <Grid
                className="forgot_password_success_grid_container"
                direction="row"
                container
              >
                <Grid item className="success-grid-item" xs={12}>
                  <header className="page-header forgotpasswordheader">
                    <div>
                      <h2>Success!</h2>
                      <span className="divider"></span>
                    </div>
                  </header>
                </Grid>

                <Grid
                  className="forgot_password_success_main_container"
                  direction="row"
                  container
                >
                  <Grid
                    item
                    xs={12}
                    className="forgot-password-success-instruction-grid-item"
                  >
                    <div className="forgot-password-instruction">
                      Your password has successfully been reset, you may now
                      login below.
                    </div>
                  </Grid>

                  <Grid item xs={12} className="login_field">
                    <TextField
                      id="username"
                      name="username"
                      label="Email Address"
                      required
                      variant="outlined"
                      value={username}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "description" }}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} className="login_field">
                    <TextField
                      required
                      id="loginPassword"
                      name="loginPassword"
                      value={loginPassword}
                      label="Password"
                      type={showLoginPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowLoginPassword}
                              onMouseDown={handleMouseDownLoginPassword}
                            >
                              {showLoginPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} className="login_error">
                    <Typography color="error" variant="caption" display="block">
                      {authState === AuthStatus.authFail && authData}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className="forgot-password-next-button-grid"
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {authState === AuthStatus.authStart ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Next"
                    )}
                  </Button>
                </Grid>

              </Grid>
            </Paper>
            <Paper
              className="checkout_wrapper right shaded"
              variant="outlined"
            ></Paper>
          </Grid>
        );
    }
  };

  return !accountVerified
    ? renderEmailForm()
    : !success
      ? renderResetForm()
      : renderSuccess();
};

export default withApollo(ForgotPassword);
