export async function Validate(auth, userInfo, setError, setValid) {
  const errors = [];

  const validFullName = (fullName) => {
    let name_rgx = /^[a-z ,.'-]+$/i;
    return name_rgx.test(fullName) && fullName.length > 2;
  };
  
  const validUserName = (userName) => {
    let email_rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email_rgx.test(userName) && userName.length > 0;
  };

  const availableUserName = async (userName) => {
    return await auth.userNameAvailable(userName.toLowerCase());
  };
  
  const validPhone = (phone) => {
    let formatPhone = phone.replace(/[() -]/g, "");
    return formatPhone.length > 11;
  };
  
  const validPassword = (password) => {
    let password_rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&-]).{8,20}$/;
    return password_rgx.test(password);
  };
  
  const validConfirmPassword = (confirm) => {
    return confirm === userInfo.password;
  };

  //full name
  setError((prevState) => ({
    ...prevState,
    fullName: !validFullName(userInfo.fullName),
  }));
  if(!validFullName(userInfo.fullName)){
    setValid(false);
    errors.push("fullName");
  }

  //username or email
  let isValidUserName = validUserName(userInfo.userName);
  setError((prevState) => ({
    ...prevState,
    userName: !isValidUserName,
  }));
  if(!isValidUserName){
    setValid(false);
    errors.push("userName");
  }

  let available = await availableUserName(userInfo.userName);
  if(!available){
    errors.push("userName");
    setValid(false);
    setError((prevState) => ({
      ...prevState,
      userExists: true,
    }));
  }

  //phone
  setError((prevState) => ({
    ...prevState,
    phone: !validPhone(userInfo.phone),
  }));
  if(!validPhone(userInfo.phone)){
    setValid(false);
    errors.push("phone");
  }

  //password
  setError((prevState) => ({
    ...prevState,
    password: !validPassword(userInfo.password),
  }));
  if(!validPassword(userInfo.password)){
    setValid(false);
    errors.push("password");
  }

  //confirm password
  setError((prevState) => ({
    ...prevState,
    confirmPassword: !validConfirmPassword(userInfo.confirmPassword),
  }));
  if(!validConfirmPassword(userInfo.confirmPassword)){
    setValid(false);
    errors.push("confirmPassword");
  }

  return errors;
}
