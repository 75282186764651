import React, { useEffect } from "react";
import { Grid, TextField, } from "@material-ui/core";

const CreditCardAddressForm = ({
  setAddress,
  address,
  setValid = null,
  showHeader = true,
}) => {
  useEffect(() => {
    let allValid =
      address.address1.length > 0 &&
      address.city.length > 0 &&
      address.state.length > 0 &&
      address.zip.length > 0;

    if (setValid) setValid(allValid);
  }, [address]);

  const handleChange = ({ target }) => {
    let fieldName = target.id;
    if (fieldName === "address1") {
      setAddress((prevState) => ({
        ...prevState,
        address1: target.value,
      }));
    }
    if (fieldName === "address2") {
      setAddress((prevState) => ({
        ...prevState,
        address2: target.value,
      }));
    }
    if (fieldName === "city") {
      setAddress((prevState) => ({
        ...prevState,
        city: target.value,
      }));
    }
    if (fieldName === "zip") {
      setAddress((prevState) => ({
        ...prevState,
        zip: target.value,
      }));
    }
    if (fieldName === "state") {
      setAddress((prevState) => ({
        ...prevState,
        state: target.value,
      }));
    }
  };

  return (
    <>
      <Grid container className="billing-address-container" spacing={1}>
        <Grid item xs={12} className="billing-address-address1-grid-item">
          {showHeader && [
            <h3 className="formgroup-header">Billing Address</h3>,
            <p className="formgroup-desc">
              Billing address associated with credit card.
            </p>,
          ]}
          <TextField
            name="address1"
            id="address1"
            label="Street Address 1"
            variant="outlined"
            fullWidth
            required
            value={address.address1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className="billing-address-address2-grid-item">
          <TextField
            name="address2"
            id="address2"
            label="Street Address 2"
            variant="outlined"
            fullWidth
            value={address.address2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={5} className="billing-address-city-grid-item">
          <TextField
            name="city"
            id="city"
            label="City"
            variant="outlined"
            fullWidth
            required
            value={address.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3} className="billing-address-state-grid-item">
          <TextField
            name="state"
            id="state"
            label="State"
            variant="outlined"
            fullWidth
            required
            value={address.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4} className="billing-address-zip-grid-item">
          <TextField
            name="zip"
            id="zip"
            label="Zip Code"
            variant="outlined"
            fullWidth
            required
            value={address.zip}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCardAddressForm;
