import React, { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles, Button, Grid } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";

const StyledToggleButtonGroup = withStyles((theme) => ({
  root:{
    display: "flex",
    flexDireciton: "row",
  },
  grouped: {
    //margin: theme.spacing(0, 2, 0, 2),
    //padding: "13px 30px 13px 30px",
    //border: "1",
    //borderTop: 1,
    "&:not(:first-child)": {
      borderRadius: 5,
      flexBasis: "100%",
    },
    "&:first-child": {
      borderRadius: 5,
      flexBasis: "100%",
    },
    color: blue[700],
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  root: {
    borderColor: grey[300],
    "&:hover": {
      backgroundColor: grey[200],
    },
    "&$selected": {
      backgroundColor: blue[700],
      color: grey[50],
      "&:hover": {
        backgroundColor: blue[700],
        color: grey[50],
      },
    },
  },
  selected: {},
}))(ToggleButton);

const ToggleDenominationGroup = (props) => {
  //console.log("props ", props);
  const denominations = ["5", "10", "25"];
  const [isActive, setActive] = useState();
  const handleSetActive = (event, value) => {
    setActive(value);

    if (value !== "Other") {
      props.setPledgeAmt(value);
    } else {
      props.setActiveView(value);
    }
    //console.log("setting active to " + value);
  };
  /*
  useEffect(() => {
    console.log(isActive + " is active");
  }, [isActive]);
  */

  return (
    
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          <StyledToggleButtonGroup
            value={isActive}
            exclusive
            onChange={handleSetActive}
            aria-label="amount toggle"
            size="medium"
          >
            {denominations.map((denomination) => (
              <StyledToggleButton
                size="medium"
                value={denomination}
                aria-label={denomination}
                key={denomination}
              >
                ${denomination}
              </StyledToggleButton>
            ))}

            <StyledToggleButton
              size="medium"
              value="Other"
              aria-label="Other"
              onClick={props.resetPledgeAmounts}
            >
              Other
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>

        <Grid item xs={12} sm={4} md={4} className="addDonationButtonGridItem">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={!Boolean(props.pledgeAmt)}
            onClick={() => props.addCartHandleClick()}
            fullWidth
          >
            + Add Donation
          </Button>
        </Grid>
      </Grid>
    
  );
};

export default ToggleDenominationGroup;
