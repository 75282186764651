import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import LocalActivityOutlinedIcon from "@material-ui/icons/LocalActivityOutlined";

const CCReview = ({ displayName, creditCard, setShowBackButton, setValid }) => {
  useEffect(() => {
    setShowBackButton(true);
    setValid(true);
  }, []);

  const renderView = () => {
    return (
      <Grid direction="column" container className="cc-review-grid-container" wrap="nowrap" spacing={2}>
        <Grid item className="cc-review-header-grid-item">
          <h3 className="formgroup-header">Please Confirm Your Information</h3>
        </Grid>
        <Grid item style={{ paddingTop: "0px" }} className="cc-review-msg-grid-item">
          Everything look good?
        </Grid>
        <Grid container className="cc-review-main-info-grid-container" direction="column" style={{ padding: "8px" }}>
          <Grid item className="cc-review-name-grid-item">
            <Grid container className="icon-group" wrap="nowrap">
              <Grid item>
                <LocalActivityOutlinedIcon className="icon-group-icon" />
              </Grid>
              <Grid item container direction="column" className="cc_display">
                <Grid item>
                  <h3>Display Name</h3>
                </Grid>
                <Grid item>{displayName}</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="cc-review-card-info-grid-item">
            <Grid container className="icon-group" wrap="nowrap">
              <Grid item>
                <CreditCardOutlinedIcon className="icon-group-icon" />
              </Grid>
              <Grid item container direction="column" className="cc_display">
                <Grid item>
                  <h3>Saved Credit Card</h3>
                </Grid>
                <Grid item>XXXX-XXXX-XXXX-*{creditCard.last_3}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default CCReview;
