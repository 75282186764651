import axios from "axios";
import { APISettings } from "./../utilities/ExactTargetAPI";

export default new class {
  constructor() {
  }

  send = async (data) => {
    try {
      await axios.post(APISettings.baseURL, data, { headers: APISettings.headers });
    } catch (error) {
      console.error(`Attempt to send email failed: `, error);
    }
  }

}()