/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2409ab88-0d64-43ec-957f-ae3d6c291014",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YUPyesEqn",
    "aws_user_pools_web_client_id": "4tfne9toma05m7pm9rbd9nvv48",
    "oauth": {},
};


export default awsmobile;
