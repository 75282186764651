import React from "react";
import { Button } from "@material-ui/core";
import classNames from "classnames";

const MSDonateButton = (props) => {
  return (
    <div key="donate-button-wrapper" className={props.wraperName}>
      <a
        href="https://microsoft.benevity.org/cause/840-620646012"
        target="_blank"
      >
        <Button
          variant="contained"
          color="secondary"
          size="large"
          //className="MS-donate-button"
          className={classNames(props.donateBtnClassName, "ms_donate", {
            "live-btn": props.isLive,
          })}
        >
          Donate
        </Button>
      </a>
    </div>
  );
};

export default MSDonateButton;
