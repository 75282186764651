import Api from "./ApiLogic";
import * as QUERIES from "../graphql/queries";
import { map, distinctUntilChanged } from 'rxjs/operators';

const pollingInterval = 500;

class PollLogic {
  constructor(pollId) {
    const transformResult = data => {
      const results = data.data.getPoll;

      return {
        pollId: results[0].pollid,
        pollTitle: results[0].polltitle,
        timeInSeconds: results[0].time_in_seconds,
        choices: results.reduce((arr, curr) => {
          arr.push({
            choiceId: curr.choiceid,
            experienceId: curr.experienceid,
            title: curr.title,
            description: curr.description,
            thumbnail: curr.thumbnail,
            votes: curr.votes,
            won: curr.won
          });
          return arr;
        }, []).sort((a, b) => a.choiceId - b.choiceId)
      }
    }
    let query = { query: QUERIES.getPoll, variables: { pollId: pollId } };
    this._stream = Api.pollingStream(query, pollingInterval).pipe(map(data => transformResult(data), distinctUntilChanged()));
  }

  get stream() {
    return this._stream;
  }
}

export default PollLogic;