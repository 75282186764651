import { RetryLink } from "apollo-link-retry";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 10,
    retryIf,
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: "omit",
  headers: { "x-api-key": process.env.REACT_APP_GRAPHQL_KEY }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([
  errorLink,
  retryLink,
  httpLink,
])

export default new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getInvoiceTotalByUser: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          listItemsByInvoice: {
            merge(existing, incoming) {
              return incoming;
            },
          }
        },
      },
    }
  }),
});