import React, { useState, useEffect, useRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import * as MUTATIONS from "../graphql/mutations";
import classNames from "classnames";
import ProgressBar from "./ProgressBar";

const Poll = ({ store, numberOfVoters = 10 }) => {

  const [selectedChoiceId, setSelectedChoiceId] = useState(null);
  const [hoveredChoiceId, setHoveredChoiceId] = useState(null);
  const [pollData, setPollData] = useState(null);
  const [incrementPollChoice] = useMutation(MUTATIONS.incrementPollChoice)

  useEffect(() => {
    const sub = store.stream.subscribe((data) => {
      setPollData(data);
    });
    return () => sub.unsubscribe();
  }, [])

  const handleSelect = (choiceId) => {
    let previousChoiceId = selectedChoiceId;
    setSelectedChoiceId(choiceId);
    incrementPollChoice({ variables: { input: { id: choiceId, previousId: previousChoiceId } } });
  };

  const renderCountDown = () => {
    if (!pollData.timeInSeconds) return null;
    let label = pollData.timeInSeconds > 1 ? "seconds" : "second";
    return (
      <div className="poll_countdown">
        <div>Voting Ends</div>
        <div className="poll_countdown_time">{`${pollData.timeInSeconds} ${label}`}</div>
      </div>);
  }

  return (
    <div>
      {!pollData ? (<div />) : (
        <Paper className="poll_wrapper" variant="outlined">
          <Grid container direction="column">
            <Grid container className="poll_header">
              <div className="poll_caption">{selectedChoiceId ? "Your vote is in!" : "Click a picture to vote"}</div>
              {renderCountDown()}
            </Grid>
            <Grid container className="poll_choice_wrapper">
              {pollData.choices.map((choice) => {
                let percentage = (choice.votes / numberOfVoters);
                let isTouchDevice = window.matchMedia("(hover: none), (pointer: coarse), (pointer: none)").matches;
                let progressText = isTouchDevice ? choice.title : `${Math.round(percentage * 100)}%`;
                return (
                  <Grid className="poll_choice_container" key={choice.choiceId} container direction="column">
                    <div
                      className={classNames("poll_choice",
                        {
                          preselect: !selectedChoiceId,
                          selected: choice.choiceId == selectedChoiceId,
                          unselected: selectedChoiceId && choice.choiceId != selectedChoiceId,
                          hovered: choice.choiceId == hoveredChoiceId
                        })
                      }
                      onClick={() => handleSelect(choice.choiceId)}
                      onMouseEnter={() => setHoveredChoiceId(choice.choiceId)}
                      onMouseLeave={() => setHoveredChoiceId(null)}
                    >
                      <img src={choice.thumbnail} alt="name" className="poll_choice_img" />
                      <div className="poll_choice_overlay">
                        <div className="poll_choice_title">{choice.title}</div>
                        <ProgressBar percentage={percentage} text={progressText} />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      )
      }
    </div >
  )
}

export default Poll;