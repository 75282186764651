import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Modal from "@material-ui/core/Modal";
/*
import CancelIcon from "@material-ui/icons/Cancel";
import { useQuery, useMutation } from "@apollo/client";
import * as QUERIES from "../graphql/queries";
import * as MUTATIONS from "../graphql/mutations";
import SequinForm from "./SequinForm";
*/
import Account from "./AccountManagement/Account";
import { useAuth } from "../hooks/UseAuth";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  drawer: {
    height: "auto",
    overflow: "hidden",
  }
}));

const AccountDrawer = () => {
  const auth = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  /*
  const { loading: expLoading, error: expError, data: expData } = useQuery(
    QUERIES.getRsvps
  );

  if (expData) {
    console.log("query", expData);
  } else console.log("no data", expError);
*/
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (e) => {
    setValue(e.target.innerText);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signOut = async () => {
    await auth.signOut();
    history.push("/");
  };

  const body = (
    <div className="accountMgmtContainer">
      <Account setOpen={setOpen} tab={value} />
    </div>
  );

  const list = (anchor) => (
    <div
      className={classes.list}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          {/* <h1>Hi, {user.attributes.name.split(" ")[0]}</h1> */}
          <h1>Welcome</h1>
        </ListItem>
        <Divider />
        <ListItem button onClick={handleClick}>
          <ListItemText primary="Edit Profile" />
        </ListItem>
        {/*
        <ListItem button>
          <ListItemText primary="Your Addresses" onClick={handleClick} />
        </ListItem>
        */}
        <ListItem button>
          <ListItemText primary="My Sequins" onClick={handleClick} />
        </ListItem>
        {/*
        <ListItem button>
          <ListItemText primary="My Events" onClick={handleClick} />
        </ListItem>
         */}
        <ListItem button>
          <ListItemText primary="My Credit Card" onClick={handleClick} />
        </ListItem>
        <ListItem button>
          <ListItemText primary="My Payments" onClick={handleClick} />
        </ListItem>
        <Divider />
        <ListItem onClick={signOut}>
          <Button variant="contained" color="secondary" fullWidth size="large">
            Sign Out
          </Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="user-drawer-modal-wrapper">
      {open ? (
        <Modal
          open={open}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={handleClose}
        >
          {body}
          {/* <SequinForm /> */}
        </Modal>
      ) : null}
      <React.Fragment>
        {/*<Button className="utils" variant="outlined" color="inherit" onClick={toggleDrawer("right", true)}>
          Utils
        </Button>*/}
        <AccountCircleIcon
          fontSize="large"
          style={{ cursor: "pointer" }}
          onClick={toggleDrawer("right", true)}
        />
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default AccountDrawer;
