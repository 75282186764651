import React from "react";
import CreditCardForm from "../CreditCardForm";

const RsvpPage4 = ({ setCreditCard, creditCard, setValid }) => {
  return (
    <>
      <header className="page-header">
        <div>
          <h2>Event RSVP</h2>
          <span className="divider"></span>
        </div>
      </header>
      <CreditCardForm setCreditCard={setCreditCard} creditCard={creditCard} setValid={setValid} />
    </>
  );
};

export default RsvpPage4;
