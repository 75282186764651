import { Container } from "@material-ui/core";

const PageLoading = () => {
  return (
    <Container className="checkin_outer_container">
      <div className="loader"></div>
    </Container>
  );
};

export default PageLoading;
