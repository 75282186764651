import React, { useEffect } from "react";
import CreditCardAddressForm from "./../../CreditCardAddressForm";

const NewCCAddress = ({ setAddress, address, setValid, setShowBackButton }) => {
  useEffect(() => {
    setShowBackButton(true);
  }, [])

  const renderView = () => {
    return (
      <CreditCardAddressForm
        setAddress={setAddress}
        address={address}
        setValid={setValid}
      >
      </CreditCardAddressForm>
    );
  };

  return renderView();
};

export default NewCCAddress;
