import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import EventLogic from "./../logic/EventLogic";
import { useAuth } from "./../hooks/UseAuth";
import Alert from "./Alert";
//import NavBarButton from "./NavBarButton";

const EventButton = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (!EventLogic.eventOpen || auth.user === false) {
      setOpen(true);
      return;
    }

    if (auth.userEventStatus(EventLogic.currentEvent.id, "registered")) {
      history.push("/checkin");
      return;
    }

    history.push(props.to);
  };

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {props.children}
      </Button>
      {open ? <Alert open={open} setOpen={setOpen} /> : null}
    </>
  );
};

export default EventButton;
