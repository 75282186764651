import client from "../graphql/client";
import * as QUERIES from "../graphql/queries";
import { timer, BehaviorSubject } from 'rxjs';
import { map, distinct } from 'rxjs/operators';
import { DateTime } from "luxon";

const EVENT_KEY = "current_event";

export const EventStatus = {
  preEvent: "event.status.pre",
  openEvent: "event.status.open",
  postEvent: "event.status.post"
}

export default new class {
  constructor() {
    const storedEvent = localStorage.getItem(EVENT_KEY);
    this._events = new BehaviorSubject();
    this._subscription = null;
    if (storedEvent) {
      const e = JSON.parse(storedEvent);
      this._setUpEventObservable(e);
    }
    else this._events.next({});
  }

  _transform = (e) => {
    let trans = {
      id: e.id,
      uuid: e.uuid,
      label: e.label,
      duration: e.duration,
      date: e.date_and_time,
      capacity: e.capacity,
      description: e.description,
      chatGroupName: e.chatgroupname,
      chatGroupGuid: e.chatgroupguid,
      streamKey: e.stream_key,
      requiresRegistration: e.requires_registration,
      streamUrl: e.stream_url
    };
    trans.status = this._eventStatus(e.date_and_time);
    return trans;
  }

  _eventStatus = (date) => {
    // commenting logic out for now so event always appears open (for testing)
/*     const millis = parseInt(date);
    const luxDate = DateTime.fromMillis(millis, { zone: "utc" });
    const start = luxDate.minus({ minutes: 30 });
    const stop = start.plus({ hours: 2 });
    const now = DateTime.utc();
    if (now < start) return EventStatus.preEvent;
    if (now > stop) return EventStatus.postEvent; */
    return EventStatus.openEvent;
  }

  _cacheEvent = (e) => {
    localStorage.setItem(EVENT_KEY, JSON.stringify(e));
  }

  _setEvent = (event) => {
    this._events.next(event);
    this._cacheEvent(event);
  }

  _setUpEventObservable = (event) => {
    const obs = timer(0, 1000).pipe( // every second we examine the event...
      map(() => {
        event.status = this._eventStatus(event.date); // update the event's status
        return event;
      }),
      distinct(ev => ev.status) // and publish to the internal observable if the event status (pre, open, post) has changed 
    )

    if (this._subscription) this._subscription.unsubscribe(); // unsubscribe if there's a previous subscription
    this._subscription = obs.subscribe(ev => {
      this._setEvent(ev)
    }) // if an updated event gets published to the internal observable, publish it to the public observable
  }

  setEventById = async (id) => {
    let query = { query: QUERIES.getEvent, variables: { id: id } };

    const event = await client.query(query);
    if (event.error) {
      console.error(event.error);
    }
    if (event.data?.getEvent) {
      let trans = this._transform(event.data.getEvent);
      this._setUpEventObservable(trans);
    }
    else throw Error(`No event found for id ${id}`);
  }

  setEventByUuid = async (uuid) => {
    let query = { query: QUERIES.getEventByUuid, variables: { uuid: uuid } };

    const event = await client.query(query);
    if (event.error) {
      console.error(event.error);
      throw event.error;
    }
    if (event.data?.getEventByUuid) {
      let trans = this._transform(event.data.getEventByUuid);
      this._setUpEventObservable(trans);
    }
    else throw Error(`No event found for uuid ${uuid}`);
  }

  get eventOpen() {
    return this.currentEvent.status === EventStatus.openEvent;
  }

  get currentEvent() {
    return this._events.value;
  }

  get eventStream() {
    return this._events;
  }

}()