import React, { useState, useEffect, useRef } from "react";
import {
  Popper,
  Button,
  makeStyles,
  Fab,
  createMuiTheme,
  Paper,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import * as QUERIES from "../../graphql/queries";
import * as MUTATIONS from "../../graphql/mutations";
import { useAuth } from "../../hooks/UseAuth";
import EventLogic from "./../../logic/EventLogic";
//import FetchInvoiceTotalByUser from "./DonateFetchInvoiceTotalByUser";
import ManageDonations from "./DonateManageCart";
import ToggleDenominationGroup from "./DonateButtonToggle";
import OtherAmount from "./DonateOther";
import CheckOut from "./DonateCheckOut";
import DonateThankYou from "./DonateThankYou";
import DonateButton from "./DonateStyledButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";

const theme = createMuiTheme();
theme.typography.h3 = {
  fontSize: "0.9rem",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    //width: "fit-content",
    //width: 590,
    /*
    height: "fit-content",
    minHeight: "231px",
    padding: theme.spacing(1, 2, 1, 2),
    */
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "gray",
    backgroundColor: "transparent",
    position: "absolute",
    //position: "relative",
    textAlign: "center",
    padding: "10px",
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  popper: {
    zIndex: 999,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: "0.5em",
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

const DonatePopper = (props) => {
  const userID = useAuth().user.username;
  //console.log("username " + userID);
  //console.log(useAuth().user);

  const classes = useStyles();

  const firstPage = props.firstPage;

  const DonateAnchor = props.DonatePopperAnchor;

  const popperRef = useRef(null);

  const [arrowRef, setArrowRef] = useState(null);

  const [activeView, setActiveView] = useState(firstPage); //buttonToggle, Other, manageDonations, thankYou, checkOut
  useEffect(() => {
    if (activeView !== "thankYou") setPledgeAmt(null);
  }, [activeView]);

  const [
    createInvoice,
    {
      data: createInvoiceData,
      loading: createInvoiceLoading,
      //error: createInvoiceError,
    },
  ] = useMutation(MUTATIONS.createInvoice);
  //const invoiceID = parseInt(createInvoiceData?.createInvoice?.id);
  /*
  useEffect(() => {
    console.log("invoiceid is ", invoiceID);
  }, [invoiceID]);
  */
  const [openBackDrop, setOpenBackDrop] = useState(true);

  useEffect(() => {
    if (createInvoiceLoading) {
      setOpenBackDrop(true);
    } else {
      setOpenBackDrop(false);
    }
  }, [createInvoiceLoading]);

  const updateCache = (cache, { data }) => {
    // add invoice item to cache
    const existingInvoiceItems = cache.readQuery({
      query: QUERIES.listItemsByInvoice,
      variables: { invoiceid: data.createInvoiceItem.invoiceid },
    });
    if (existingInvoiceItems) {
      const newInvoiceItem = data.createInvoiceItem;
      cache.writeQuery({
        query: QUERIES.listItemsByInvoice,
        variables: { invoiceid: data.createInvoiceItem.invoiceid },
        data: {
          listItemsByInvoice: [
            ...existingInvoiceItems.listItemsByInvoice,
            newInvoiceItem,
          ],
        },
      });
    }
    // update user total in cache
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: userID },
    });
    if (existingInvoiceTotalByUser) {
      var newInvoiceTotalByUser = JSON.parse(
        JSON.stringify(existingInvoiceTotalByUser)
      );
      newInvoiceTotalByUser.getInvoiceTotalByUser.total =
        existingInvoiceTotalByUser.getInvoiceTotalByUser.total +
        data.createInvoiceItem.amount;
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: userID },
        data: { getInvoiceTotalByUser: newInvoiceTotalByUser },
      });
    }
  };

  const [createInvoiceItem] = useMutation(MUTATIONS.createInvoiceItem, {
    update: updateCache,
  });

  // calc paper height/width (used for confetti on checkout pg)
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
      //console.log("height is ", node.getBoundingClientRect().height);
    }
  }, []);

  // set open/close states for popper
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  /*
  useEffect(() => {
    console.log("anchorEL is now ", anchorEl);
  }, [anchorEl]);
  */
  const open = Boolean(anchorEl);
  const id = open ? "donate-popper" : undefined;
  if (props.popperName) {
    props.popperIsOpen(anchorEl, props.popperName);
  }
  useEffect(() => {
    if (open) {
      setActiveView(firstPage);
    }
  }, [open, firstPage]);
  useEffect(() => {
    if (props.otherPopperIsOpen) {
      /*
      if (props.popperName) {
        console.log(
          "inside",
          props.popperName,
          ", otherPopperIsOpen? ",
          props.otherPopperIsOpen
        );
      }
      */
      setAnchorEl(false);
    }
  }, [props.otherPopperIsOpen, props.popperName]);

  useEffect(() => {
    if (anchorEl) {
      //console.log("create invoice");
      createInvoice({
        variables: {
          input: {
            eventid: EventLogic.currentEvent.id,
            userid: userID,
            label: "in event donation invoice",
          },
        },
      });
    }
  }, [anchorEl, userID, createInvoice]);

  const [pledgeAmt, setPledgeAmt] = useState(null);
  /*
  useEffect(() => {
    console.log(pledgeAmt + " is pleadged amount");
  }, [pledgeAmt]);
  */

  const addCartHandleClick = () => {
    if (createInvoiceData.createInvoice.id) {
      createInvoiceItem({
        variables: {
          input: {
            invoiceid: createInvoiceData.createInvoice.id,
            label: "in event donation",
            amount: parseFloat(pledgeAmt),
          },
        },
      });
      setActiveView("thankYou");
    }
  };

  /*
  function myCallback(dataFromChild) {
    console.log("got " + dataFromChild);
  }
  */

  //const totAmountFetched = FetchInvoiceTotalByUser(userID, "donatepopup");
  const {
    //loading,
    //error,
    data: totAmountFetchedData,
  } = useQuery(QUERIES.getInvoiceTotalByUser, {
    variables: { uid: userID },
  });
  /*
  useEffect(() => {
    console.log("total is ", totAmountFetchedData?.getInvoiceTotalByUser?.total);
  }, [totAmountFetchedData]);
  */

  useEffect(() => {
    if (
      totAmountFetchedData?.getInvoiceTotalByUser.total === null ||
      totAmountFetchedData?.getInvoiceTotalByUser.total === 0
    ) {
      //console.log("amount is zero, closing ", props.popperName);
      //setAnchorEl(false);
      if (firstPage === "manageDonations") {
        setAnchorEl(false);
      } else {
        setActiveView(firstPage);
      }
    }
  }, [totAmountFetchedData, firstPage]);

  const donateBody = (
    <div className="col-flexbox-container donatePaperWrapper">
      <Paper
        elevation={3}
        className={`${classes.paper} in-event-donation-popper-paper`}
        ref={measuredRef}
      >
        <Fab
          className="btn-close"
          onClick={() => handleClick(false)}
          color="inherit"
          fontSize="large"
        >
          <Close />
        </Fab>

        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {createInvoiceData &&
          (activeView === "buttonToggle" || activeView === "Other") && (
            <div className="col-flexbox-component add-a-donation-wrapper">
              <div className="donateTitle">Add a donation:</div>
            </div>
          )}

        {createInvoiceData &&
          (activeView === "buttonToggle" || activeView === "Other") && (
            <div className="col-flexbox-component other-wrapper">
              {activeView === "Other" && (
                <OtherAmount
                  setActiveView={setActiveView}
                  setPledgeAmt={setPledgeAmt}
                  pledgeAmt={pledgeAmt}
                  addCartHandleClick={addCartHandleClick}
                />
              )}

              {activeView === "buttonToggle" && (
                <ToggleDenominationGroup
                  setPledgeAmt={setPledgeAmt}
                  setActiveView={setActiveView}
                  pledgeAmt={pledgeAmt}
                  addCartHandleClick={addCartHandleClick}
                />
              )}
            </div>
          )}

        {createInvoiceData && activeView === "manageDonations" && (
          <ManageDonations
            popperRef={popperRef}
            invoiceID={createInvoiceData.createInvoice.id}
            userID={userID}
            setActiveView={setActiveView}
            totAmountFetched={
              totAmountFetchedData.getInvoiceTotalByUser.total
                ? totAmountFetchedData.getInvoiceTotalByUser.total
                : 0
            }
          />
        )}

        {activeView === "thankYou" && (
          <DonateThankYou
            setActiveView={setActiveView}
            pledgeAmt={pledgeAmt}
            setAnchorEl={setAnchorEl}
            setPledgeAmt={setPledgeAmt}
          />
        )}
        {activeView === "retry" && (
          <Box textAlign="center">
            <div className="col-flexbox-component error-wrapper">
              <h2>Please try again later.</h2>
              <p>Service temporarily unavailable.</p>
            </div>
          </Box>
        )}
        {createInvoiceData && activeView !== "checkOut" && (
          <div className="col-flexbox-component bottom-buttons-wrapper">
            <div className="row-flexbox-container donationCartTotal">
              <div className="row-flexbox-component donationCartTotalWrapper">
                <div className="donationCartTotal">
                  Donation Cart Total: $
                  {totAmountFetchedData.getInvoiceTotalByUser.total
                    ? totAmountFetchedData.getInvoiceTotalByUser.total
                    : 0}
                </div>
              </div>

              <div className="row-flexbox-component">
                {activeView === "manageDonations" ? (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => setActiveView("buttonToggle")}
                    className={classes.root}
                  >
                    Back to Donate {">"}
                  </Button>
                ) : (
                  <DonateButton
                    variant="outlined"
                    size="large"
                    onClick={() => setActiveView("manageDonations")}
                    disabled={
                      !Boolean(totAmountFetchedData.getInvoiceTotalByUser.total)
                    }
                    className={classes.root}
                  >
                    Manage Donations
                  </DonateButton>
                )}
              </div>

              <div className="row-flexbox-component">
                <DonateButton
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveView("checkOut")}
                  disabled={
                    !Boolean(totAmountFetchedData.getInvoiceTotalByUser.total)
                  }
                  className={classes.root}
                >
                  Check Out
                </DonateButton>
              </div>
            </div>
          </div>
        )}

        {createInvoiceData &&
          activeView === "checkOut" &&
          createInvoiceData && (
            <CheckOut
              popperRef={popperRef}
              userID={userID}
              setActiveView={setActiveView}
              invoiceID={createInvoiceData.createInvoice.id}
              setAnchorEl={setAnchorEl}
              setPledgeAmt={setPledgeAmt}
              paperHeight={height}
              paperWidth={width}
            />
          )}
      </Paper>
    </div>
  );

  return [
    <div key="donateanchor" className={props.popperName} onClick={handleClick}>
      <DonateAnchor />
    </div>,
    <div key="donatepopperkey">
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal={true}
        placement="top-end"
        className={`${classes.popper} donatePopper ${props.popperName}`}
        popperRef={popperRef}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          offset: {
            offset: "-13, 13",
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        {donateBody}
      </Popper>
    </div>,
  ];
};

export default DonatePopper;
