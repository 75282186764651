import React, { useState, useEffect } from "react";
import { Grid, TextField, InputAdornment, IconButton } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { useAuth } from "./../../../hooks/UseAuth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const RegContact = ({
  setUserInfo,
  userInfo,
  setValid,
  setShowBackButton,
  setHeader,
  error,
  setError,
}) => {
  useEffect(() => {
    setHeader(`Let's Get Started`);
    setShowBackButton(false);
  }, [setHeader, setShowBackButton]);

  const auth = useAuth();

  const [edited, setEdited] = useState({
    fullName: false,
    userName: false,
    password: false,
    confirmPassword: false,
    phone: false,
  });

  useEffect(() => {
    let allValid =
      !error.userName &&
      !error.fullName &&
      !error.password &&
      !error.confirmPassword &&
      !error.phone &&
      !error.userExists &&
      edited.fullName &&
      edited.userName &&
      edited.phone &&
      edited.password &&
      edited.confirmPassword;
    setValid(allValid);
  }, [
    error.userName,
    error.fullName,
    error.password,
    error.confirmPassword,
    error.phone,
    error.userExists,
    edited.fullName,
    edited.userName,
    edited.password,
    edited.confirmPassword,
    edited.phone,
    setValid,
  ]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validFullName = (fullName) => {
    let name_rgx = /^[a-z ,.'-]+$/i;
    return name_rgx.test(fullName) && fullName.length > 2;
  };

  const validUserName = (userName) => {
    let email_rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email_rgx.test(userName) && userName.length > 0;
  };

  const availableUserName = async (userName) => {
    return await auth.userNameAvailable(userName.toLowerCase());
  };

  const validPhone = (phone) => {
    let formatPhone = phone.replace(/[() -]/g, "");
    return formatPhone.length > 11;
  };

  const validPassword = (password) => {
    let password_rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&-]).{8,20}$/;
    return password_rgx.test(password);
  };

  const validConfirmPassword = (confirm) => {
    return confirm === userInfo.password;
  };

  const handleFullNameBlur = ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      fullName: true,
    }));

    setError((prevState) => ({
      ...prevState,
      fullName: !validFullName(target.value),
    }));
  };

  const handleUserNameBlur = async ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      userName: true,
    }));

    let valid = validUserName(target.value);
    setError((prevState) => ({
      ...prevState,
      userName: !valid,
      userExists: false,
    }));
    if (!valid) return;

    let available = await availableUserName(target.value);
    setError((prevState) => ({
      ...prevState,
      userExists: !available,
    }));
  };

  const handlePhoneBlur = ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      phone: true,
    }));

    setError((prevState) => ({
      ...prevState,
      phone: !validPhone(target.value),
    }));
  };

  const handlePasswordBlur = ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      password: true,
    }));

    setError((prevState) => ({
      ...prevState,
      password: !validPassword(target.value),
    }));
  };

  const handleConfirmPasswordBlur = ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      confirmPassword: true,
    }));

    setError((prevState) => ({
      ...prevState,
      confirmPassword: !validConfirmPassword(target.value),
    }));
  };

  const handleChange = ({ target }) => {
    setEdited((prevState) => ({
      ...prevState,
      [target.id]: true,
    }));

    if (target.id === "userName") {
      setUserInfo((prevState) => ({
        ...prevState,
        [target.id]: target.value.toLowerCase(),
      }));
    } else {
      setUserInfo((prevState) => ({
        ...prevState,
        [target.id]: target.value,
      }));
    }
  };

  const handlePhoneChange = (data) => {
    let formatPhone = data.replace(/[() -]/g, "");
    setUserInfo((prevState) => ({
      ...prevState,
      phone: formatPhone,
    }));
    setEdited((prevState) => ({
      ...prevState,
      phone: true,
    }));
  };

  const renderView = () => {
    return (
      <Grid direction="column" container wrap="nowrap" spacing={2}>
        <Grid item className="register_title">
          Contact Information
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="fullName"
            id="fullName"
            label="Full Name"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            required
            error={error.fullName}
            helperText={error.fullName ? "Please enter your full name." : null}
            onBlur={handleFullNameBlur}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="userName"
            id="userName"
            label="Email Address"
            variant="outlined"
            onChange={handleChange}
            fullWidth
            required
            error={error.userExists || error.userName}
            helperText={
              error.userExists
                ? "An account with the given email already exists."
                : error.userName
                  ? "Please enter a valid email address."
                  : null
            }
            onBlur={handleUserNameBlur}
          />
        </Grid>
        <Grid item style={{ paddingTop: "0px" }}>
          This email will be used as your login username.
        </Grid>

        <Grid item xs={12}>
          <MuiPhoneNumber
            variant="outlined"
            label="Phone"
            id="phone"
            defaultCountry={"us"}
            error={error.phone}
            fullWidth
            required
            helperText={
              error.phone ? "Please enter a valid phone number." : null
            }
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
          />
        </Grid>
        <Grid item className="register_title">
          Password
        </Grid>
        <Grid container direction="row" spacing={2} style={{ padding: "8px" }}>
          <Grid item xs={6}>
            <TextField
              className="register_password"
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              variant="outlined"
              onChange={handleChange}
              required
              error={error.password}
              onBlur={handlePasswordBlur}
              helperText={
                error.password
                  ? "Password must contain 8 to 20 characters with at least one digit, one upper case letter, one lower case letter and one special symbol (“#$^+=!*()@%&-”)"
                  : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              className="register_password"
              name="confirmPassword"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleConfirmPasswordBlur}
              required
              error={error.confirmPassword}
              value={userInfo.confirmPassword}
              helperText={
                error.confirmPassword ? "Passwords do not match" : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default RegContact;
