import React from "react";
import PollControl from "./../components/PollControl";

const ControlPage = () => {
  return (
    <PollControl />
  );
};

export default PollControl;
