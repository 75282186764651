import React, { useState, useEffect } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Typography,
  Paper,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const Legal = ({
  userInfo: { fullName },
  setValid,
  setShowBackButton,
  setHeader,
}) => {
  const firstName = fullName.substr(0, fullName.indexOf(" "));
  setShowBackButton(true);
  setHeader(`Welcome, ${firstName}`);
  const [check, setCheck] = useState({
    checkedLegal: false,
  });
  //const [readTerms, setReadTerms] = useState(false);

  useEffect(() => {
    let allValid = check.checkedLegal;
    setValid(allValid);
  }, [check.checkedLegal, setValid]);

  const handleCheck = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
    check.checkedLegal && setValid(true);
  };
  /*
  const listenScrollEvent = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setReadTerms(true);
    }
  };
  */
  return (
    <Grid direction="column" container wrap="nowrap" spacing={2}>
      <Grid item className="register_title">
        Terms and Agreements
      </Grid>

      <Grid item xs={12}>
        <Paper
          //onScroll={listenScrollEvent}
          onscroll
          className="legal-paper"
          variant="outlined"
        >
          <p>
            <spand style={styles.header}>OVERVIEW</spand>
            <br />
            American Lebanese Syrian Associated Charities, Inc. (ALSAC), the
            fundraising and awareness organization for St. Jude Children’s
            Research Hospital (“St. Jude”) operates this website and provides
            the services offered herein. Throughout the site, the terms “we”,
            “us”, and “our” refer to ALSAC. ALSAC offers this website, including
            all information, tools, and services (collectively, “Services”)
            available from this site to you, the user, conditioned upon your
            acceptance of all terms, conditions, policies, and notices stated
            here. BY USING THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF
            USE. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS OF USE,
            PLEASE EXIT THE SITE NOW. By visiting our site and/or using the
            Services, you agree to be bound by the following terms and
            conditions (“Terms”), including those additional terms and
            conditions and policies referenced herein and/or available by
            hyperlink. These Terms apply to all users of the site. Please read
            these Terms carefully before accessing or using our website. By
            accessing or using any part of the site, you agree to be bound by
            these Terms. If you do not agree to all the terms and conditions of
            this agreement, do not access this website or use any Services. Any
            new features or tools which are added to the website shall also be
            subject to the Terms. We reserve the right to update, change or
            replace any part of these Terms by posting updates and/or changes to
            this website. It is your responsibility to check this page
            periodically for changes. By continuing to use the site and/or the
            Services offered by or through the site, you are deemed to have
            accepted the Terms, as amended.
            <br />
            <spand style={styles.header}>
              SECTION 1 - USER TERMS & CONDITIONS
            </spand>
            <br />
            By agreeing to these Terms, you represent that you are at least the
            age of majority in your state or province of residence. You agree to
            provide true, accurate, current and complete information about
            yourself as prompted by the Create an Account form, and maintain and
            promptly update such account information to keep it true, accurate,
            current, and complete. If you provide untrue, inaccurate, not
            current or incomplete information, or we have reasonable grounds to
            suspect that such account information is untrue, inaccurate, not
            current or incomplete, we reserve the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site. You may not use our Services for any illegal or unauthorized
            purpose nor may you, in the use of the Services, violate any laws.
            You must not transmit any worms, malware or viruses or any code of a
            destructive nature. You may not use the Services in such a way to
            deceive third parties about your connection, affiliation or
            partnership with the ALSAC/St. Jude. A breach or violation of any of
            the Terms may result in an immediate termination of your Services.
            We reserve the right to refuse service to anyone for any reason at
            any time. You understand that your content, may be transferred
            unencrypted and involve (a) transmissions over various networks; and
            (b) changes to conform and adapt to technical requirements of
            connecting networks or devices. You agree not to reproduce,
            duplicate, copy, sell, resell, or exploit any portion of the
            Services or any contact on the website through which the service is
            provided, without express prior written permission by ALSAC. You
            agree to provide current, complete, and accurate account information
            for all transactions occurring on or through this site. You agree to
            promptly update your account and other information so that we can
            complete your transactions and contact you as needed.
            <br />
            <spand style={styles.header}>
              SECTION 2 - ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION
            </spand>
            <br />
            We are not responsible if information made available on this site is
            not accurate, complete, or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk. Information provided through the site is designed for
            educational purposes only and is not engaged in rendering medical
            advice or professional services. The information provided through
            this site should not be used for diagnosing or treating a health
            problem or a disease. It is not a substitute for professional care.
            If you have or suspect you may have a health problem, you should
            consult your health care provider. This site may contain certain
            historical information. Historical information, necessarily, is not
            current and is provided for your reference only. We reserve the
            right to modify the contents of this site at any time, but we have
            no obligation to update any information on our site. You agree that
            it is your responsibility to monitor changes to our site.
            <br />
            <spand style={styles.header}>SECTION 3 - MODIFICATIONS </spand>
            <br />
            We reserve the right at any time to modify or discontinue the
            Services (or any part or content thereof) without notice at any
            time. We reserve the right, at our sole discretion, to update,
            change or replace any part of these Terms by posting updates and
            changes to our website. It is your responsibility to check our
            website periodically for changes. Your continued use of or access to
            our website or the Services following the posting of any changes to
            these Terms constitutes your acceptance of those changes. We shall
            not be liable to you or to any third-party for any modification,
            suspension, or discontinuance of the Services.
            <br />
            <spand style={styles.header}>
              SECTION 4 – THIRD PARTY INFORMATION AND LINKS{" "}
            </spand>
            <br />
            We may provide you with access to third-party tools or materials
            over which we neither monitor nor have any control nor input. You
            acknowledge and agree that we provide access to such tools “as is”
            and “as available” without any warranties, representations or
            conditions of any kind and without any endorsement. We shall have no
            liability whatsoever arising from or relating to your use of
            optional third-party tools. Any use by you of optional tools offered
            through the site is entirely at your own risk and discretion and you
            should ensure that you are familiar with and approve of the terms on
            which tools are provided by the relevant third-party provider(s).
            Third-party links on this site may direct you to third-party
            websites that are not affiliated with us. We are not responsible for
            examining or evaluating the content or accuracy and we do not
            warrant and will not have any liability or responsibility for any
            third-party materials or websites, or for any other materials,
            products, or services of third-parties. We are not liable for any
            harm or damages related to the purchase or use of goods, services,
            resources, content, or any other transactions made in connection
            with any third-party websites. Please review carefully the
            third-party's policies and practices and make sure you understand
            them before you engage in any transaction. Complaints, claims,
            concerns, or questions regarding third-party products should be
            directed to the third-party. We may also, in the future, offer new
            services and/or features through the website or a related website
            (including, the release of new tools and resources) all of which are
            subject to these Terms.
            <br />
            <spand style={styles.header}>SECTION 5 - USER SUBMISSIONS</spand>
            <br />
            If, at our request, you send certain specific submissions or without
            a request from us you send creative ideas, suggestions, proposals,
            plans, or other materials, whether online, by email, by postal mail,
            or otherwise (collectively, 'comments'), you agree that we may, at
            any time, without restriction, edit, copy, publish, distribute,
            translate and otherwise use in any medium any comments that you
            forward to us. We are and shall be under no obligation to keep
            comments confidential, to provide compensation for any comments or
            to respond to any comments. We may, but have no obligation to,
            monitor, edit or remove content that we determine in our sole
            discretion are unlawful, offensive, threatening, libelous,
            defamatory, pornographic, obscene or otherwise objectionable or
            violates any party’s intellectual property or these Terms. You waive
            any rights to privacy, publicity, or any other rights of a similar
            nature in connection with the materials submitted through this site
            and expressly release ALSAC and St. Jude, and their affiliates,
            officers, directors, and employees, and anyone acting under direct
            or indirect authorization from the foregoing, from any and all
            claims, demands, liabilities, or causes of action, whether now known
            or unknown, for defamation, slander, libel, copyright infringement,
            trademark infringement, violation of moral rights, and invasion of
            the rights of privacy, publicity, or personality or any similar
            matter, or based upon or relating to the use of the materials. You
            hereby waive and agree never to assert any and all moral rights, or
            to support, maintain or permit any action based on any moral rights,
            that you may have in or with respect to any materials. To the extent
            that you contribute photographs, audiovisual materials, written
            materials, artistic works, musical works, or other works of
            authorship through the site and/or the Services offered therein, you
            grant to ALSAC/St. Jude and/or agents or persons authorized thereby,
            a royalty-free, perpetual, sublicensable, irrevocable license
            throughout the world to use such materials. All copyrights to
            newly-created materials including your contributions shall be owned
            exclusively by ALSAC/St. Jude, you assign, transfer, and convey all
            such copyrights to ALSAC/St. Jude, and agree further to execute any
            documents requested by ALSAC to confirm such assignment of
            copyright. You understand that ALSAC is under no obligation to
            exercise any of the rights, licenses and privileges granted herein.
            You agree that your comments will not violate any right of any
            third-party, including copyright, trademark, privacy, personality or
            other personal or proprietary right. You further agree that your
            comments will not contain libelous or otherwise unlawful, abusive or
            obscene material, or contain any computer virus or other malware
            that could in any way affect the operation of the Services or any
            related website. You may not use a false e-mail address, pretend to
            be someone other than yourself, or otherwise mislead us or
            third-parties as to the origin of any comments. You are solely
            responsible for any comments you make and their accuracy. We take no
            responsibility and assume no liability for any comments posted by
            you or any third-party.
            <br />
            <spand style={styles.header}>
              SECTION 6 - PERSONAL INFORMATION
            </spand>
            <br />
            You also grant us the right to disclose to third parties certain
            Registration Data about you. The information we obtain through your
            use of this site, including your Registration Data, is subject to
            our Privacy Policy (available at www.stjude.org/legal) which is
            specifically incorporated by reference into these Terms of Use. You
            may submit credit card information through this site, and such
            information shall be stored for future donations initiated by you.
            Review the Privacy Notice, which is incorporated herein:
            https://www.stjude.org/legal/u-s-privacy-policy-statement.html
            <br />
            <spand style={styles.header}>
              SECTION 7 - ERRORS, INACCURACIES AND OMISSIONS
            </spand>
            <br />
            Occasionally there may be information on our site or in the Services
            that contains typographical errors, inaccuracies or omissions that
            may relate to descriptions, pricing, promotions, offers, and
            availability. We reserve the right to correct any errors,
            inaccuracies or omissions, and to change or update information or
            cancel orders if any information in the Services or on any related
            website is inaccurate at any time without prior notice (including
            after you have submitted your order). We undertake no obligation to
            update, amend or clarify information in the Services or on any
            related website, including without limitation, except as required by
            law.
            <br />
            <spand style={styles.header}>SECTION 8 - PROHIBITED USES</spand>
            <br />
            Your use of the site is subject to all applicable laws and
            regulations, and you are solely responsible for your communications
            through the site. By posting information in or otherwise using any
            Services, you agree that you will not upload, share, post, or
            otherwise distribute or facilitate distribution of any content --
            including text, communications, software, images, sounds, data, or
            other information -- that: a. is unlawful, threatening, abusive,
            harassing, defamatory, libelous, deceptive, fraudulent, invasive of
            another's privacy, tortious, contains explicit or graphic
            descriptions or accounts of sexual acts (including but not limited
            to sexual language of a violent or threatening nature directed at
            another individual or group of individuals), or otherwise violates
            our rules or policies or solicits others to perform or participate
            in any such acts; b. victimizes, harasses, degrades, abuses,
            insults, harms, defames, slanders, disparages, discriminates or
            intimidates an individual or group of individuals on the basis of
            religion, gender, sexual orientation, race, ethnicity, national
            origin, age, or disability c. infringes on any patent, trademark,
            trade secret, copyright, right of publicity, or other proprietary
            right of any party or violates any international, federal,
            provincial or state regulations, rules, laws, or local ordinances;
            d. constitutes unauthorized or unsolicited advertising, junk or bulk
            email (also known as "spamming"), chain letters, any other form of
            unauthorized solicitation, or any form of lottery or gambling; e.
            contains software viruses or any other computer code, files, or
            programs that are designed or intended to disrupt, damage, or limit
            the functioning of any software, hardware, or telecommunications
            equipment or to damage or obtain unauthorized access to any data or
            other information of any third party; or f. impersonates any person
            or entity, including any of our employees, officers, directors,
            physicians, patients or representatives. g. is false or misleading
            information h. collects or tracks the personal information of
            others; or i. interferes with or circumvents the security features
            of the Services or any related website, other websites, or the
            Internet. We reserve the right to terminate your use of the Services
            or any related website for violating any of the prohibited uses. We
            neither endorse nor assume any liability for the contents of any
            material uploaded or submitted by third party users of the site. We
            have the right at their sole discretion to remove any content that,
            in our judgment, does not comply with these Terms and any other
            rules of user conduct for our site, or is otherwise harmful,
            objectionable, or inaccurate. We are not responsible for any failure
            or delay in removing such content. You hereby consent to such
            removal and waive any claim against us arising out of such removal
            of content. In addition, you may not use your account to breach
            security of another account or attempt to gain unauthorized access
            to another network or server. You shall not interfere with anyone
            else's use and enjoyment of the site or other similar services.
            Except for a single copy made for personal use only, you may not
            copy, reproduce, modify, republish, upload, post, transmit, or
            distribute any documents or information from this site in any form
            or by any means without prior written permission from us or the
            specific content provider, and you are solely responsible for
            obtaining permission before reusing any copyrighted material that is
            available on this site.
            <br />
            <spand style={styles.header}>
              SECTION 9 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
            </spand>
            <br />
            We do not guarantee, represent or warrant that your use of our
            Services will be uninterrupted, timely, secure or error-free. We do
            not warrant that the results that may be obtained from the use of
            the Services will be accurate or reliable. You agree that from time
            to time we may remove the Services for indefinite periods of time or
            cancel the Services at any time, without notice. You expressly agree
            that your use of, or inability to use, the Service is at your sole
            risk. The Services and all products and services delivered to you
            through the Services are provided 'as is' and 'as available' for
            your use, without any representation, warranties or conditions of
            any kind, either express or implied, including all implied
            warranties or conditions of merchantability, merchantable quality,
            fitness for a particular purpose, durability, title, and
            non-infringement. WITHOUT LIMITING THE FOREGOING, WE MAKE NO
            WARRANTY THAT (A) THE SERVICES AND MATERIALS WILL MEET YOUR
            REQUIREMENTS, (B) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED
            FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE,
            ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY PRODUCTS, SERVICES,
            OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM THE SITE FROM US OR
            OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES,
            ERRORS OR DEFECTS. THE USE OF THE SERVICES OR THE DOWNLOADING OR
            OTHER ACQUISITION OF ANY MATERIALS THROUGH THIS SITE IS DONE AT YOUR
            OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
            DATA THAT RESULTS FROM SUCH ACTIVITIES. In no case shall ALSAC or
            St. Jude or the ALSAC or St. Jude directors, officers, employees,
            affiliates, agents, contractors, interns, suppliers, service
            providers or licensors be liable for any injury, loss, claim, or any
            direct, indirect, incidental, punitive, special, or consequential
            damages of any kind, including, without limitation lost profits,
            lost revenue, lost savings, loss of data, replacement costs, or any
            similar damages, whether based in contract, tort (including
            negligence), strict liability or otherwise, arising from your use of
            any of the Services or any products procured using the Services, or
            for any other claim related in any way to your use of the Services
            or any product, including, but not limited to, any errors or
            omissions in any content, or any loss or damage of any kind incurred
            as a result of the use of the Services or any content (or product)
            posted, transmitted, or otherwise made available via the service,
            even if advised of their possibility. Because some states or
            jurisdictions do not allow the exclusion or the limitation of
            liability for consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
            <br />
            <spand style={styles.header}>SECTION 10 - INDEMNIFICATION</spand>
            <br />
            You agree to defend, indemnify, and hold harmless ALSAC, St. Jude,
            and their respective directors, officers, and employees from any and
            all claims, demands, liabilities, lawsuits, settlements, actions,
            damages and expenses whether or not litigation is commenced
            (including without limitation, reasonable attorneys’ fees) arising
            from or related to (i) any negligent act or omission of, by you;
            (ii) any breach or violation of these Terms or your representations
            and warranties contained herein; or (iii) your violation of the law
            or the rights of a third party. This section shall survive any
            termination, cancellation or expiration of your use of the site
            and/or the Services offered therein.
            <br />
            <spand style={styles.header}>SECTION 11 - TERMINATION</spand>
            <br />
            These Terms are effective unless and until terminated by either you
            or us. You may terminate these Terms by notifying us that you no
            longer wish to use our Services. We may terminate this site, the
            Services offered therein or your access to this site or the Services
            at any time and for any, or no, reason.
            <br />
            <spand style={styles.header}>SECTION 12 - MISCELLANEOUS</spand>
            <br />
            Section headings are for convenience only and shall not be
            considered part of the terms and conditions. These Terms and your
            agreement thereto shall be governed by and construed in accordance
            with the laws of the State of Tennessee. The parties submit to the
            exclusive jurisdiction of and venue in the appropriate courts
            located in Memphis, Tennessee. These Terms along with such other
            Terms referenced herein (stjude.org/legal) contain the entire
            agreement of the Parties and supersedes all prior agreements or
            understanding as to the subject matter covered by these Terms. These
            Terms constitutes the entire understanding and agreement, and
            supersedes any and all prior or contemporaneous representations,
            understandings, and agreements, whether oral or written, all of
            which are merged in these Terms. If any part is found to be
            unenforceable, the remaining portions of these Terms will remain in
            full force and effect. The waiver of any breach or default will not
            constitute a waiver of any other right hereunder or any subsequent
            breach or default. The “St. Jude Trademarks” are defined as: the St.
            Jude and ALSAC names, trade names, trademarks, service marks,
            designs, trade dress and logos. You must obtain St. Jude’s and
            ALSAC’s signed, written, advance approval of each use of the St.
            Jude Trademarks. You may not use the names of current or former St.
            Jude and ALSAC officers, directors, employees or affiliated
            physicians, or faculty, except upon the prior written consent of
            both ALSAC’s CEO and St. Jude’s CEO. You may not approach and may
            not use or make public the name or story of any current or former
            St. Jude patient or family for purposes of stories, profiles,
            testimonials, news, public awareness, or any other publicity. The
            Terms and Conditions shall be governed by and construed in
            accordance with the laws of the State of Tennessee. You submit to
            the exclusive jurisdiction of and venue in the appropriate courts
            located in Memphis, Tennessee. If any term or provision of these
            Terms and Conditions should be declared invalid by a court of
            competent jurisdiction, the remaining terms and conditions shall be
            unimpaired. We may terminate this site, your access to this site
            and/or the Services offered herein, and/or the Services immediately
            upon written notice if we determine, in its sole discretion, that
            any of the ALSAC or St. Jude Trademarks are being used without
            permission, improperly or wrongfully, or that liability or
            undesirable publicity will attach to ALSAC or St. Jude, or that the
            reputation and good will of ALSAC or St. Jude is jeopardized.
          </p>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <FormGroup column>
          <FormControlLabel
            control={
              <Checkbox
                checked={check.checkedLegal}
                onChange={handleCheck}
                name="checkedLegal"
                color="primary"
              />
            }
            label={
              <Typography variant="caption" className="sub-header">
                I agree to the terms and conditions
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

const styles = {
  header: {
    fontWeight: "900",
  },
};

export default Legal;
