import { BehaviorSubject } from 'rxjs';

export const ForestStates = {
  intro: "forest.state.intro",
  main: "forest.state.main",
  regionSelected: "forest.state.regionSelected",
  regionEntered: "forest.state.regionEntered",
  sequin: "forest.state.sequin",
  sequinLink: "forest.state.sequinLink"
}

export default new class {
  constructor() {
    this._viewState = new BehaviorSubject({ oldState: null, newState: null });
  }

  get current() {
    return this._viewState.value;
  }

  get stream() {
    return this._viewState;
  }

  toIntroView = () => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.intro, data: {} }
      })
    )
  }

  toMainView = () => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.main, data: {} }
      })
    )
  }

  toRegionSelected = (region) => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.regionSelected, data: region }
      })
    )
  }

  toRegionEntered = (region) => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.regionEntered, data: region }
      })
    )
  }

  toSequinView = (sequinInfo) => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.sequin, data: { id: sequinInfo.id, name: sequinInfo.name, region: sequinInfo.region } }
      })
    )
  }

  toSequinLinkView = (sequinInfo) => {
    this._viewState.next(
      Object.assign(this.current, {
        oldState: this.current.newState,
        newState: { type: ForestStates.sequinLink, data: { id: sequinInfo.id, name: sequinInfo.name, region: sequinInfo.region } }
      })
    )
  }
}()