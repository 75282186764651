import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Link,
  Grid,
  Typography,
  Collapse,
  Fade,
} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import FetchInvoiceTotalByUser from "../../ActiveEventDonation/DonateFetchInvoiceTotalByUser";
import DonationGifts from "./CheckoutDonationGifts";
import DonateManageCart from "../../ActiveEventDonation/DonateManageCart";
import { useMutation } from "@apollo/client";
import * as QUERIES from "../../../graphql/queries";
import * as MUTATIONS from "../../../graphql/mutations";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Avatar from "@material-ui/core/Avatar";

const CheckoutCart = (props) => {
  const popperRef = useRef(null);
  const total = Number(FetchInvoiceTotalByUser(props.userID, "checkoutcart"));
  const [editCart, setEditCart] = useState(false);
  const [priceTarget, setPriceTarget] = useState(null);
  const [disclaimerIsMaximized, setDisclaimerIsMaximized] = useState(false);
  const readLessRef = useRef(null);
  const readMoreRef = useRef(null);

  useEffect(() => {
    props.setShowBackButton(false);
    props.setValid(true);
  }, []);

  const scrollToReadLessIfNeeded = () => {
    //console.log("disclaimerIsMaximized", disclaimerIsMaximized);
    //console.log("readLessRef.current", readLessRef.current);
    readLessRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToReadMoreIfNeeded = () => {
    //console.log("readMoreRef.current", readMoreRef.current);
    readMoreRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [pledgeAmt, setPledgeAmt] = useState(null);

  const [upsold, setUpsold] = useState(false);
  /*
  useEffect(() => {
    console.log("upsold is ", upsold);
  }, [upsold]);
  */
  useEffect(() => {
    if (total) {
      if (!priceTarget) {
        if (total < 25) {
          setPriceTarget(25);
        } else if (total >= 25 && total < 50) {
          setPriceTarget(50);
        } else if (total >= 50 && total < 75) {
          setPriceTarget(75);
        } else if (total >= 75 && total < 100) {
          setPriceTarget(100);
        } else if (total >= 100 && total < 250) {
          setPriceTarget(250);
        } else if (total >= 250 && total < 50) {
          setPriceTarget(500);
        }
      }
      //console.log("total ", total);
      if (priceTarget) {
        //console.log("initial target", priceTarget);
        if (total >= priceTarget) {
          //console.log("upsold successful: total ", total, " is >= ", priceTarget);
          setUpsold(true);
        } else {
          //console.log("upsold NOT successful");
          setUpsold(false);
        }
      }
    }
  }, [total]);

  const updateCache = (cache, { data }) => {
    // add invoice item to cache
    const existingInvoiceItems = cache.readQuery({
      query: QUERIES.listItemsByInvoice,
      variables: { invoiceid: parseInt(props.invoiceID) },
    });
    if (existingInvoiceItems) {
      const newInvoiceItem = data.createInvoiceItem;
      cache.writeQuery({
        query: QUERIES.listItemsByInvoice,
        variables: { invoiceid: parseInt(props.invoiceID) },
        data: {
          listItemsByInvoice: [
            ...existingInvoiceItems.listItemsByInvoice,
            newInvoiceItem,
            //createInvoiceItem
          ],
        },
      });
    }

    /*
  // update total in cache
  const existingInvoiceTotal = cache.readQuery({
    query: QUERIES.getInvoiceTotal,
    variables: { invoiceid: invoiceID },
  });
  if (existingInvoiceTotal) {
    var newInvoiceTotal = JSON.parse(JSON.stringify(existingInvoiceTotal));
    newInvoiceTotal.getInvoiceTotal.total =
      existingInvoiceTotal.getInvoiceTotal.total +
      data.createInvoiceItem.amount;
    cache.writeQuery({
      query: QUERIES.getInvoiceTotal,
      variables: { invoiceid: invoiceID },
      data: { getInvoiceTotal: newInvoiceTotal },
    });
  }
  */

    // update user total in cache
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: props.userID },
    });
    if (existingInvoiceTotalByUser) {
      var newInvoiceTotalByUser = JSON.parse(
        JSON.stringify(existingInvoiceTotalByUser)
      );
      newInvoiceTotalByUser.getInvoiceTotalByUser.total =
        existingInvoiceTotalByUser.getInvoiceTotalByUser.total +
        data.createInvoiceItem.amount;
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: props.userID },
        data: { getInvoiceTotalByUser: newInvoiceTotalByUser },
      });
    }
  };

  const [createInvoiceItem] = useMutation(MUTATIONS.createInvoiceItem, {
    update: updateCache,
  });

  const addCartHandleClick = () => {
    console.log("target", priceTarget);
    //console.log("invoice id is " + invoiceID);
    createInvoiceItem({
      variables: {
        input: {
          invoiceid: parseInt(props.invoiceID),
          label: "check out amount",
          amount: parseFloat(pledgeAmt),
        },
      },
    });
  };

  const renderBody = () => {
    return !upsold ? (
      <>
        <DonationGifts total={total} />
        <Grid
          direction="row"
          container
          style={{ paddingBottom: "25px" }}
          spacing={1}
        >
          <Grid item xs={12}>
            Add an Additional Amount
          </Grid>

          <Grid item>
            <CurrencyTextField
              variant="outlined"
              currencySymbol="$"
              decimalCharacter="."
              digitGroupSeparator=","
              size="small"
              onChange={handleChange}
            ></CurrencyTextField>
          </Grid>
          <Grid item className="checkout-add-button">
            <Button
              onClick={addCartHandleClick}
              variant="contained"
              color="primary"
              disabled={!props.createInvoiceData?.createInvoice?.id}
              //key={`${!props.createInvoiceData?.createInvoice?.id}`}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </>
    ) : (
      <Grid direction="row" container className="checkout_donate_msg">
        <Grid direction="row" container style={{ paddingBottom: "85px" }}>
          <Grid item style={{ fontWeight: "bolder", color: "black" }}>
            Thank You! Your additional donation will make a difference.
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleChange = (e) => {
    setPledgeAmt(e.target.value);
  };

  const renderView = () => {
    return !editCart ? (
      <Grid
        direction="column"
        container
        wrap="nowrap"
        className={`checkout-cart`}
      >
        <Grid direction="row" container style={{ paddingBottom: "25px" }}>
          <Grid item className="checkout_title">
            Your Donation Cart:
          </Grid>
          <Grid direction="row" container>
            <Grid item>Current Total:</Grid>
            <Grid item style={{ paddingLeft: "5px" }}>
              ${total}
            </Grid>
            <Grid item style={{ paddingLeft: "35px" }}>
              <Link onClick={() => setEditCart(true)} href="#">
                Edit Cart
              </Link>
            </Grid>
          </Grid>
        </Grid>
        {renderBody()}
        <Grid item style={{ paddingBottom: "15px" }}>
          <Collapse
            in={disclaimerIsMaximized}
            collapsedHeight={30}
            className="collapse-disclaimer"
            onEntered={scrollToReadLessIfNeeded}
            onExited={scrollToReadMoreIfNeeded}
          >
            <Fade in={true}>
              <Typography
                variant="caption"
                style={{
                  fontSize: ".65rem",
                  display: "block",
                }}
                className="disclaimer-text"
              >
                Disclaimer: Items listed in this section are representative of
                services and supplies that are part of the treatment and care of
                children of St. Jude. The cost of each item or service is an
                approximation and will vary based on actual costs incurred and
                individual patient needs. Your donations will be used to cover
                the expenses of St. Jude to support breakthrough research,
                treatment and cures.
              </Typography>
            </Fade>
          </Collapse>
          {!disclaimerIsMaximized && (
            <Fade in={true}>
              <div className="expand-read-wrapper" ref={readMoreRef}>
                <div className="read-expand-text">Read More </div>
                <Avatar className="expand-avatar">
                  <ExpandMoreIcon
                    className="expand-icon"
                    onClick={() => {
                      setDisclaimerIsMaximized(!disclaimerIsMaximized);
                    }}
                  />
                </Avatar>
              </div>
            </Fade>
          )}
          {disclaimerIsMaximized && (
            <Fade in={true}>
              <div className="expand-read-wrapper" ref={readLessRef}>
                <div className="read-expand-text">Read Less </div>
                <Avatar className="expand-avatar">
                  <ExpandLessIcon
                    className="expand-icon"
                    onClick={() => {
                      setDisclaimerIsMaximized(!disclaimerIsMaximized);
                    }}
                  />
                </Avatar>
              </div>
            </Fade>
          )}
        </Grid>
      </Grid>
    ) : (
      <Grid direction="column" container wrap="nowrap">
        <Grid direction="row" container style={{ paddingBottom: "25px" }}>
          <Grid direction="row" container>
            <DonateManageCart
              userID={props.userID}
              invoiceID={props.invoiceID}
              popperRef={popperRef}
            />
          </Grid>
        </Grid>
        <Grid item style={{ paddingLeft: "35px" }}>
          <Link onClick={() => setEditCart(false)}>Go Back</Link>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default CheckoutCart;
