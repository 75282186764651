import React from "react";
import { Grid } from "@material-ui/core";

const BenevityLink = (props) => {
  const renderView = () => {
    return (
      <Grid
        direction="column"
        container
        wrap="nowrap"
        className={`checkout-cart-ms`}
      >
        <Grid direction="row" container style={{ paddingBottom: "25px" }}>
          <Grid item xs={12} className="ms_checkout_link">
            <a
              href="https://microsoft.benevity.org/cause/840-620646012"
              target="_blank"
            >
              Donate through Benevity here.
            </a>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default BenevityLink;
