import { BehaviorSubject, combineLatest } from 'rxjs';
import EventLogic from './EventLogic';
import AuthLogic from './AuthLogic';

export default new class {
  constructor() {
    this._stream = new BehaviorSubject();

    combineLatest([EventLogic.eventStream, AuthLogic.authStatus]).subscribe(([e, a]) => {
      if (e && a) {
        const o = { event: e, auth: a };
        this._stream.next(o);
      }
    })

  }

  get stream() {
    return this._stream;
  }
}()