import React, { useState, useEffect } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  //Divider,
  TextField,
  Button,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

const RsvpPage1 = ({ eventPrice, setEventPrice, setValid }) => {
  const [check, setCheck] = useState({
    checkedA: false,
  });
  const [voucher, setVoucher] = useState("");
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [voucherError, setVoucherError] = useState(false);

  console.log("voucher", voucher);
  const handleCheck = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (check.checkedA) setValid(true);
    else setValid(false);
  }, [check.checkedA, setValid]);

  const verifyVoucher = () => {
    if (voucher.toUpperCase() === "SEQUIN") {
      setEventPrice("0");
      setVoucherApplied(true);
    } else if (voucher.toUpperCase() === "TEST") {
      setEventPrice("5.00");
      setVoucherApplied(true);
    } else setVoucherError(true);
  };

  return (
    <>
      <header className="page-header">
        <div>
          <h2>Welcome, please RSVP</h2>
          <span className="divider"></span>
        </div>
      </header>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <h2 className="event-header" style={{ marginBottom: "2rem" }}>
              St. Jude Virtual Sequin Forest Beta
            </h2>
          </div>

          <Grid container className="icon-group">
            <Grid item xs={1}>
              <EventIcon className="icon-group-icon" />
            </Grid>
            <Grid item xs={11} justify-content="">
              <h3>Event Date + Time</h3>
              <p>Friday, February 19, 2021 at 1:00pm CT</p>
            </Grid>
          </Grid>

          <Grid container className="icon-group">
            <Grid item xs={1}>
              <ConfirmationNumberIcon className="icon-group-icon" />
            </Grid>
            <Grid item xs={11} justify-content="">
              <h3>Event Donation</h3>
              <p>{`$${eventPrice}`}</p>
              <p>
                100% of this amount is tax deductible to the extent allowed by
                law.
              </p>
            </Grid>
          </Grid>

          {voucherApplied ? (
            <Grid
              container
              xs={12}
              direction="row"
              style={{ paddingTop: "15px" }}
            >
              <TextField
                name="voucher"
                id="voucher"
                variant="outlined"
                defaultValue={voucher}
                InputProps={{
                  readOnly: true,
                }}
              />

              <Typography
                variant="caption"
                className="sub-header"
                style={{ margin: "1rem" }}
              >
                Your special voucher has been applied!
              </Typography>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              direction="row"
              justify="space-between"
              style={{ paddingTop: "15px" }}
            >
              <TextField
                name="voucher"
                id="voucher"
                label="Special Voucher"
                variant="outlined"
                error={voucherError}
                helperText={voucherError ? '"Invalid Voucher"' : null}
                onChange={(e) => setVoucher(e.target.value)}
              />

              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={verifyVoucher}
              >
                Apply
              </Button>
            </Grid>
          )}

          <hr />

          <FormGroup column>
            <h3 className="formgroup-header">Event Communication</h3>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkedA}
                  onClick={handleCheck}
                  name="checkedA"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className="sub-header">
                  I agree to receive essential updates about this event via
                  email (required).
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default RsvpPage1;
