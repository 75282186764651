import React from "react";
import { Button, Card, SvgIcon, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import getDateOnlyFromEpoch from "./GetDateOnlyFromEpoch";

export default function SequinCarousel(props) {
  const { backgroundColor, itemid, date_and_time } = props.content;

  return (
    <Card className="sequinCard">
      <Grid
        container
        spacing={2}
        align="center"
        justify="center"
        className="sequin-content"
      >
        <div className="account-mgm-sequin-view">
          <SvgIcon style={{ color: backgroundColor }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449 449">
              <defs />
              <path
                fillRule="evenodd"
                d="M224.5 449C100.512 449 0 348.488 0 224.5S100.512 0 224.5 0 449 100.512 449 224.5 348.488 449 224.5 449zm.5-344c22.644 0 41-18.356 41-41s-18.356-41-41-41-41 18.356-41 41 18.356 41 41 41z"
              />
            </svg>
          </SvgIcon>
        </div>
        <Grid item xs={12} className="sequin-number">
          <div className="sequin-name">Sequin #{itemid}</div>
        </Grid>
        <Grid item xs={12}>
          <div className="sequin-date">
            {getDateOnlyFromEpoch(date_and_time)}
          </div>
        </Grid>
        <Grid item xs={12} className="sequin-link">
          <div className="see-sequin-link-button-lg">
            <Button
              variant="contained"
              size="large"
              color="primary"
              component={Link}
              to={`/forest/${itemid}`}
              target="_blank"
              endIcon={<ArrowForwardIosIcon />}
            >
              See Sequin Forest
            </Button>
          </div>
          <div className="see-sequin-link-button-sm">
            <Button
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to={`/forest/${itemid}`}
              target="_blank"
              endIcon={<ArrowForwardIosIcon />}
            >
              See Sequin Forest
            </Button>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}
