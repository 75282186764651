import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";

const CreditCardForm = ({
  setCreditCard,
  creditCard,
  setValid,
  description = "",
  showHeader = true,
}) => {
  const [monthTouched, setMonthTouched] = useState(false);
  const [yearTouched, setYearTouched] = useState(false);
  const [cardError, setCardError] = useState({
    error: true,
    ccError:
      creditCard.expMonth ||
      creditCard.expYear ||
      creditCard.firstName ||
      creditCard.lastName
        ? true
        : false,
    expMonthError: false,
    expYearError: false,
    cvvError:
      creditCard.expMonth ||
      creditCard.expYear ||
      creditCard.firstName ||
      creditCard.lastName
        ? true
        : false,
  });
  const [nameError, setNameError] = useState({
    firstNameError: false,
    lastNameError: false,
  });
  const [check, setCheck] = useState({
    checkedA: false,
  });
  const handleCheck = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (creditCard.expMonth !== null && monthTouched) {
      setCardError((prevState) => ({
        ...prevState,
        expMonthError: false,
      }));
    } else if (creditCard.expMonth === null && monthTouched) {
      setCardError((prevState) => ({
        ...prevState,
        expMonthError: true,
      }));
    }
    if (creditCard.expYear !== null && yearTouched) {
      setCardError((prevState) => ({
        ...prevState,
        expYearError: false,
      }));
    } else if (creditCard.expYear === null && yearTouched) {
      setCardError((prevState) => ({
        ...prevState,
        expYearError: true,
      }));
    }
    if (
      !cardError.ccError &&
      creditCard.cc !== null &&
      !cardError.expMonthError &&
      creditCard.expMonth !== null &&
      !cardError.expYearError &&
      creditCard.expYear !== null &&
      !cardError.cvvError &&
      creditCard.cvv !== null
    ) {
      setCardError((prevState) => ({
        ...prevState,
        error: false,
      }));
    } else {
      setCardError((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }, [creditCard, monthTouched, yearTouched]);

  useEffect(() => {
    let allValid =
      !cardError.error &&
      !nameError.firstNameError &&
      !nameError.lastNameError &&
      check.checkedA;
    setValid(allValid);
  }, [cardError, nameError, check]);

  const handleYear = ({ target }) => {
    setCreditCard((prevState) => ({
      ...prevState,
      expYear: target.value,
    }));
  };

  const handleMonth = ({ target }) => {
    if (target.value !== null) {
      setCreditCard((prevState) => ({
        ...prevState,
        expMonth: target.value,
      }));
      setCardError((prevState) => ({
        ...prevState,
        expMonthError: false,
      }));
    } else
      setCardError((prevState) => ({
        ...prevState,
        expMonthError: true,
      }));
  };

  const handleChange = ({ target }) => {
    let fieldName = target.id;
    let val = target.value;
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var cvvRegEx = /^[0-9]{3,4}$/;
    let nameRegEx = /^[a-zA-Z ]+$/;

    if (fieldName === "cc") {
      setCreditCard((prevState) => ({
        ...prevState,
        cc: target.value,
      }));
      if (
        visaRegEx.test(val) ||
        mastercardRegEx.test(val) ||
        amexpRegEx.test(val) ||
        discovRegEx.test(val)
      ) {
        setCardError((prevState) => ({
          ...prevState,
          ccError: false,
        }));
      } else
        setCardError((prevState) => ({
          ...prevState,
          ccError: true,
        }));
    }
    if (fieldName === "cvv") {
      setCreditCard((prevState) => ({
        ...prevState,
        cvv: target.value,
      }));
      if (cvvRegEx.test(target.value)) {
        setCardError((prevState) => ({
          ...prevState,
          cvvError: false,
        }));
      } else
        setCardError((prevState) => ({
          ...prevState,
          cvvError: true,
        }));
    }
    if (fieldName === "firstname") {
      setCreditCard((prevState) => ({
        ...prevState,
        firstName: target.value,
      }));
      if (nameRegEx.test(target.value)) {
        setNameError((prevState) => ({
          ...prevState,
          firstNameError: false,
        }));
      } else
        setNameError((prevState) => ({
          ...prevState,
          firstNameError: true,
        }));
    }
    if (fieldName === "lastname") {
      setCreditCard((prevState) => ({
        ...prevState,
        lastName: target.value,
      }));
      if (nameRegEx.test(target.value)) {
        setNameError((prevState) => ({
          ...prevState,
          lastNameError: false,
        }));
      } else
        setNameError((prevState) => ({
          ...prevState,
          lastNameError: true,
        }));
    }
  };

  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  const years = [];
  for (let i = 21; i <= 45; i++) {
    years.push(i);
  }

  const renderMonth = () => {
    return (
      <Select
        error={cardError.expMonthError}
        variant="outlined"
        value={creditCard.expMonth}
        onChange={handleMonth}
        onClose={() => setMonthTouched(true)}
        fullWidth
        labelid="expiration_month"
        sm={12}
      >
        {months.map((item, i) => {
          return (
            <MenuItem label="Select a month" key={i} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const renderYear = () => {
    return (
      <Select
        error={cardError.expYearError}
        variant="outlined"
        value={creditCard.expYear}
        fullWidth
        onChange={handleYear}
        onClose={() => setYearTouched(true)}
        labelid="expiration_year"
        sm={12}
      >
        {years.map((item, i) => {
          return (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  return (
    <>
      <Grid container spacing={1} className="cc-form-grid-container">
        <Grid item xs={12} className="cc-form-header-grid-item">
          {showHeader && (
            <Grid item style={{ paddingBottom: "8px" }} className="cc-form-header-grid-item">
              <h3 className="formgroup-header">Credit Card</h3>
            </Grid>
          )}
          {description.length > 0 && (
            <Grid
              item
              style={{
                paddingTop: "0px",
                paddingBottom: "5px",
                marginTop: "-7px",
              }}
              className="cc-form-desc-grid-item"
            >
              <Typography variant="caption">{description}</Typography>
            </Grid>
          )}
          <TextField
            error={cardError.ccError ? true : false}
            helperText={
              cardError.ccError ? "Please provide a valid cc number" : null
            }
            name="cc"
            variant="outlined"
            required
            fullWidth
            id="cc"
            label="Card No:"
            value={creditCard.cc}
            onChange={handleChange}
            className="cc-form-card-textfield"
          />
        </Grid>
        <Grid item xs={12} className="cc-form-expiration-grid-item">
          <InputLabel id="expiration">Expiration Date</InputLabel>
        </Grid>
        <Grid item xs={3} className="cc-form-month-grid-item">
          {renderMonth()}
        </Grid>
        <Grid item xs={4} className="cc-form-year-grid-item">
          {renderYear()}
        </Grid>
        <Grid item xs={5} className="cc-form-cvv-grid-item">
          <TextField
            error={cardError.cvvError ? true : false}
            helperText={cardError.cvvError ? "Please enter a valid CVV" : null}
            variant="outlined"
            required
            fullWidth
            id="cvv"
            label="CVV"
            name="cvv"
            value={creditCard.cvv}
            onChange={handleChange}
            className="cc-form-cvv-textfield"
          />
        </Grid>
        <Grid item xs={12} className="cc-form-name-grid-item">
          <InputLabel id="expiration">Cardholder Name</InputLabel>
        </Grid>
        <Grid item xs={6} className="cc-form-first-name-grid-item">
          <TextField
            error={nameError.firstNameError}
            variant="outlined"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            value={creditCard.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} className="cc-form-last-name-grid-item">
          <TextField
            error={nameError.lastNameError}
            variant="outlined"
            required
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            value={creditCard.lastName}
            onChange={handleChange}
          />
        </Grid>

        <Grid item className="cc-form-first-checkbox-grid-item">
          <FormGroup
            group
            row
            className="checkbox"
            style={{ marginTop: "-10px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkedA}
                  onClick={handleCheck}
                  name="checkedA"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className="sub-header">
                  I understand and agree that my credit card information will be
                  saved to my profile for this event and future events happening
                  on this platform. I understand I will have the opportunity to
                  review and approve all charges on a check out screen prior to
                  them being finalized.
                </Typography>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCardForm;
