import client from "../graphql/client";
//import * as QUERIES from "../graphql/queries";
import * as MUTATIONS from "../graphql/mutations";
import { BehaviorSubject } from 'rxjs';

export default new class {
  constructor() {
    this._statusMessage = new BehaviorSubject({
      status: ""
    });
  }

  loadSequins = async (data) => {
    const sequins = data.meshes.filter(m => m.name.startsWith("sequin"));
    const regions = sequins.reduce((regions, sequin) => {
      let name = sequin.name.split("_")[1];
      if (!regions.some(r => r.name === name)) {
        regions.push({ name: name });
      }
      return regions;
    }, [])

    this._statusMessage.next({ update: false, status: `Babylon file contains ${sequins.length} sequins within ${regions.length} regions.` });
    this._statusMessage.next({ update: false, status: `Deleting existing sequins...` });
    const resp1 = await client.mutate({
      mutation: MUTATIONS.deleteAllSequins,
      variables: {
        input: {
          id: 0
        }
      }
    });
    this._statusMessage.next({ update: false, status: `Deleted ${resp1.data.deleteAllSequins.count} sequins.` });

    this._statusMessage.next({ update: false, status: `Deleting existing regions...` });
    const resp2 = await client.mutate({
      mutation: MUTATIONS.deleteAllRegions,
      variables: {
        input: {
          id: 0
        }
      }
    });
    this._statusMessage.next({ update: false, status: `Deleted ${resp2.data.deleteAllRegions.count} regions.` });

    for (const r of regions) {
      this._statusMessage.next({ update: false, status: `Creating region ${r.name}...` });
      const response = await client.mutate({
        mutation: MUTATIONS.createBulkRegions,
        variables: {
          input: {
            external_name: r.name,
            regiontype: 'block'
          }
        }
      })
      r.id = response.data.createBulkRegions.id;
    }

    this._statusMessage.next({ update: false, status: `Creating sequins...` });
    let seqCount = 0;
    let retry = false;

    do {
      const s = sequins[seqCount]
      const arr = s.name.split("_");
      const regId = regions.find(r => r.name === arr[1]).id;
      try {
        await client.mutate({
          mutation: MUTATIONS.createBulkSequins,
          variables: {
            input: {
              external_name: s.name,
              region: regId
            }
          }
        });
        this._statusMessage.next({ update: seqCount === 0 || retry ? false : true, status: `Created ${seqCount + 1} out of ${sequins.length} sequins` });
        retry = false;
      } catch (error) {
        console.error(error); // periodically an error occurs on the grapql resolve -- retrying the request "fixes" it
      }
      seqCount++;
    }
    while (seqCount < sequins.length);

    this._statusMessage.next({ update: false, status: `Done!` });
  }

  get currentStatusMessage() {
    return this._statusMessage.value;
  }

  get statusMessageStream() {
    return this._statusMessage;
  }
}()