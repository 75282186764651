/*
export const APIKEY = "SQK9LQTWAr9nhgcoaf5nB6vE3kI0sMP74CW0a79v";

export const APIBaseURL =
  "https://bnm36g369j.execute-api.us-east-1.amazonaws.com/dev";

export const ALIAS_URL =
  "https://bnm36g369j.execute-api.us-east-1.amazonaws.com/dev/alias";

export const CHARGE_URL =
  "https://bnm36g369j.execute-api.us-east-1.amazonaws.com/dev/charge";
*/

/*
export const APIKEY = "JUs9fKIewl8CWJvKM5u7OFkMdmey9WX2Hz1uHOc7";
export const APIBaseURL = "https://hbfy0lpgrg.execute-api.us-east-1.amazonaws.com/prod";
export const ALIAS_URL = "https://hbfy0lpgrg.execute-api.us-east-1.amazonaws.com/prod/alias";
export const CHARGE_URL = "https://hbfy0lpgrg.execute-api.us-east-1.amazonaws.com/prod/charge";
*/

export const APIKEY = "";
export const APIBaseURL = "http://localhost:4545";
export const ALIAS_URL = "http://localhost:4545/alias";
export const CHARGE_URL = "http://localhost:4545/charge";
