import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { useAuth } from "./../../../hooks/UseAuth";

const CurrentCC = ({
  creditCard,
  gotoCcEdit,
  setShowBackButton
}) => {
  const auth = useAuth();

  useEffect(() => {
    setShowBackButton(true);
  }, [])

  const renderView = () => {
    return (
      <Grid direction="column" container className="current-cc-grid-container" wrap="nowrap" spacing={2}>
        <Grid item className="current-cc-header-grid-item">
          <h3 className="formgroup-header">
            Please Confirm Your Credit Card Information
          </h3>
        </Grid>
        <Grid item style={{ paddingTop: "0px" }} className="current-cc-info-grid-item">
          During this event you will have the opportunity to support the kids of St. Jude.
          The card selected below will be used for any donations you choose to make
          during the event.  You will have the opportunity to review and approve all
          charges on a check out screen prior to them being finalized.
        </Grid>
        <Grid item className="current-cc-main-info-grid-item">
          <Grid container className="icon-group" wrap="nowrap">
            <Grid item className="current-cc-icon-grid-item">
              <CreditCardOutlinedIcon className="icon-group-icon" />
            </Grid>

            <Grid item container direction="column" className="cc_display">
              <Grid item className="current-cc-label-grid-item">
                <h3>Credit Card</h3>
              </Grid>
              <Grid item className="current-cc-last3-grid-item">XXXX-XXXX-XXXX-*{creditCard.last_3}</Grid>
            </Grid>
            <Grid item className="cc_edit">
              <Button
                size="small"
                variant="outlined"
                size="large"
                color="primary"
                onClick={gotoCcEdit}
                className="current-cc-edit-button"
              >
                Edit
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default CurrentCC;
