export async function ValidatePassword(
  password,
  confirmPassword,
  setError,
  currentPassword = false,
  setModifyPasswordError = false
) {
  const errors = [];
  const password_rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&-]).{8,20}$/;
  //console.log("inside validatepassword");

  const validCurrentPassword = () => {
    return password_rgx.test(currentPassword);
  };

  const validPassword = () => {
    return password_rgx.test(password);
  };

  const validConfirmPassword = () => {
    return confirmPassword === password;
  };

  const differentPassword = () => {
    return currentPassword !== password;
  };

  //current password
  setError((prevState) => ({
    ...prevState,
    currentPassword: !validCurrentPassword(),
    password: !validPassword(),
    confirmPassword: !validConfirmPassword(),
  }));
  if (!validCurrentPassword()) {
    errors.push("currentPassword");
  }

  //password
  if (!validPassword()) {
    errors.push("password");
  }

  //confirm password
  if (!validConfirmPassword()) {
    errors.push("confirmPassword");
  } else {
    // check it's a new password
    if (currentPassword) {
      if (!differentPassword()) {
        console.log("same password!!!");
        setModifyPasswordError(
          "Your new password cannot be the same as the current password. "
        );
      }
      setError((prevState) => ({
        ...prevState,
        samePassword: !differentPassword(),
      }));
      if (!differentPassword()) {
        errors.push("differentPassword");
      }
    }
  }
  return errors;
}
