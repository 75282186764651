import React from "react";
import {
  Grid,
  Divider,
} from "@material-ui/core";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import EventIcon from "@material-ui/icons/Event";

const RsvpPage6 = ({
  address,
  creditCard,
  setAddress,
  setCreditCard,
  fullName,
  userData,
  eventPrice,
}) => {
  return (
    <div className="page-content">
      <header className="page-header">
        <div>
          <h2>Receipt</h2>
          <span className="divider"></span>
        </div>
      </header>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="formgroup-header" style={{ marginBottom: "1rem" }}>
            St. Jude Virtual Sequin Forest Beta
          </h3>

          <Grid container className="icon-group">
            <Grid item xs={1}>
              <EventIcon className="icon-group-icon" />
            </Grid>
            <Grid item xs={11} justify-content="">
              <h3>Event Date + Time</h3>
              <p>Friday, February 19, 2021 at 1:00pm CT</p>
            </Grid>
          </Grid>

          <Grid container className="icon-group">
            <Grid item xs={2} sm={1}>
              <CreditCardOutlinedIcon className="icon-group-icon" />
            </Grid>

            <Grid item container xs={10} sm={11} justify="space-between">
              <Grid item xs={8}>
                <h3>Credit Card</h3>
              </Grid>

              <Grid item>
                <p>
                  XXXX-XXXX-XXXX-
                  {creditCard.cc.substr(creditCard.cc.length - 4)}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="icon-group">
            <Grid item xs={2} sm={1}>
              <MailOutlineOutlinedIcon className="icon-group-icon" />
            </Grid>

            <Grid item container xs={10} sm={11} justify="space-between">
              <Grid item xs={8}>
                <h3>Billing Address</h3>
              </Grid>

              <Grid item>
                <span className="entry-line">{`${creditCard.firstName} ${creditCard.lastName}`}</span>
                <span className="entry-line">{address.address1}</span>
                <span className="entry-line">
                  {address.city}, {address.state} {address.zip}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="icon-group">
            <Grid item xs={2} sm={1}>
              <ConfirmationNumberIcon className="icon-group-icon" />
            </Grid>

            <Grid item container xs={10} sm={11} justify="space-between">
              <Grid item xs={8}>
                <h3>Event Charges</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className="event-cost">{`$${eventPrice}`}</h3>
              </Grid>
              <Grid item>
                <p>Event Donation</p>
                <p>
                  100% of this amount is tax deductible to the extent allowed by
                  law.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <hr className="form-line"></hr>

        <Grid sm={12} item container alignItems="center" justify="flex-end">
          <h3>
            Total <span className="event-total">{`$${eventPrice}`}</span>
          </h3>
        </Grid>
        <Divider variant="middle" />
      </Grid>
    </div>
  );
};

export default RsvpPage6;
