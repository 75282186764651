import React from "react";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";
import * as QUERIES from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../hooks/UseAuth";
import ReceiptIcon from "@material-ui/icons/Receipt";
import getDateOnlyFromEpoch from "./GetDateOnlyFromEpoch";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    textAlign: "center",
  },
  expanded: {
    backgroundColor: "#f1f1f1",
  },
}));

const MyPayments = () => {
  const classes = useStyles();
  const userID = useAuth().user.username;

  const { loading, error, data } = useQuery(
    QUERIES.getPaidInvoicesByUserOrderedByEvents,
    {
      variables: { uid: userID },
    }
  );
  //console.log("called getPaidInvoicesByUserOrderedByEvents with user ID ", userID);
  if (loading) {
    console.log("Loading...");
  }
  if (error) {
    console.log(
      "GraphQL Error! getPaidInvoicesByUserOrderedByEvents",
      error.message
    );
  }
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <header className="page-header">
            <div>
              <h2>My Payments</h2>
              <span className="divider"></span>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="payments-tab-scrollable-area">
        <Grid item xs={12}>
          {/*
          <div className={classes.circularProgress}>
            <CircularProgress style={{ color: "gray" }} />
          </div>
          */}

          {loading && (
            <div className={classes.circularProgress}>
              <CircularProgress style={{ color: "gray" }} />
            </div>
          )}

          {!loading && !data.getPaidInvoicesByUserOrderedByEvents && (
            <div></div>
          )}

          {!loading &&
            data.getPaidInvoicesByUserOrderedByEvents &&
            data.getPaidInvoicesByUserOrderedByEvents.map(
              (eventWithInvoices) => (
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      classes={{ expanded: classes.expanded }}
                    >
                      <Grid item xs={12}>
                        {
                          <div className="payment">
                            <ReceiptIcon fontSize="large" />
                            <div className="eventName">
                              {eventWithInvoices.event.chatgroupname}
                            </div>
                          </div>
                        }
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer>
                        <Table aria-label="donations table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <div className="payment-card-col">Card</div>
                              </TableCell>
                              <TableCell align="center">
                                <div className="payment-date-col">Date</div>
                              </TableCell>
                              <TableCell align="right">
                                <div className="payment-donation-col">
                                  Donation
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {eventWithInvoices.invoices.map((invoice) => (
                              <TableRow key="donation-detail">
                                <TableCell>
                                  <div className="payment-last-3">
                                    ***{invoice.paymentinformation.last_3}
                                  </div>
                                </TableCell>
                                {invoice.invoicehistory.paid_at && (
                                  <TableCell align="center">
                                    <div className="payment-time">
                                      {getDateOnlyFromEpoch(
                                        invoice.invoicehistory.paid_at
                                      )}
                                    </div>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <div className="payment-amount">
                                    ${invoice.total.toFixed(2)}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                  {/*
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={4} className="payment-card-col">
                          Card
                        </Grid>
                        <Grid item xs={4} className="payment-date-col">
                          Date
                        </Grid>
                        <Grid item xs={4} className="payment-donation-col">
                          Donation
                        </Grid>
                        {eventWithInvoices.invoices.map((invoice) => (
                          <>
                            <Grid item xs={4}>
                              {
                                <div className="payment-last-3">
                                  ***
                                  {invoice.paymentinformation.last_3}
                                </div>
                              }
                            </Grid>
                            <Grid item xs={4}>
                              {invoice.invoicehistory.paid_at && (
                                <div className="payment-time">
                                  {getDateOnlyFromEpoch(
                                    invoice.invoicehistory.paid_at
                                  )}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <div className="payment-amount">
                                ${invoice.total.toFixed(2)}
                              </div>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                                  */}
                  {/* 
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      classes={{ expanded: classes.expanded }}
                    >
                      <Grid item xs={12}>
                        {
                          <div className="payment">
                            <ReceiptIcon fontSize="large" />
                            <div className="eventName">Test</div>
                          </div>
                        }
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={4} className="payment-card-col">
                          Card
                        </Grid>
                        <Grid item xs={4} className="payment-date-col">
                          Date
                        </Grid>
                        <Grid item xs={4} className="payment-donation-col">
                          Donation
                        </Grid>

                        <Grid item xs={4}>
                          <div className="payment-last-3">***123</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="payment-time">01/01/2020</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="payment-amount">$5.00</div>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                */}
                </div>
              )
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { MyPayments };
