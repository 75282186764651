import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import SequinLoader from "../logic/LoadSequinLogic";

const LoadSequins = () => {
  const [log, setLog] = useState([]);

  useEffect(() => {
    const sub = SequinLoader.statusMessageStream.subscribe(s => {
      setLog(log => {
        if (!s.update) return [...log, s.status];
        return log.map((entry, index) => {
          if (index == log.length - 1) return s.status;
          return entry;
        })
      })
    });
    return () => sub.unsubscribe();
  }, [])

  const handleLoadClick = () => {
    if (!window.confirm("WARNING!!!!\nThis will delete all current region and sequin data from the database!\n\nAre you ABSOLUTELY SURE you want to continue?"))
      return;

    fetch('/models/sequinOnlyNonPick29_l.babylon').then(response => response.json().then(data => {
      console.log(data);
      SequinLoader.loadSequins(data);
      // Code to set an inner highlight (MVP) for an already claimed sequin
      // this.hlLayer.addMesh(pickResult.pickedMesh, BABYLON.Color3.Red()); // Any color
      // this.hlLayer.outerGlow = false;
      // Code to set an inner highlight (MVP) for an already claimed sequin 
    }));
  }

  const renderLog = () => {
    return (
      <div style={{ marginTop: 10 }}>
        {log.map((m, i) => { return (<div key={i}>{m}</div>) })}
      </div>
    )
  }

  return <div>
    <Button variant="outlined" size="large" onClick={handleLoadClick}>
      Load Sequins
    </Button>
    {renderLog()}
  </div>;
};

export default LoadSequins;
