/* eslint-disable */

import { gql } from "@apollo/client";

export const sequinsByRegion = gql`
  query SequinsByRegion($regionId: Int!) {
    listSequinsByRegion(regionId: $regionId) {
      id
      uuid
      regionid
      label
      bcHash
      colorr
      colorb
      colorg
      colora
      texture
      positionx
      positiony
      positionz
      external_name
      state
      claimedby
      message
    }
  }
`;

export const getSequinsByUser = gql`
  query getSequinsByUser($uid: String!) {
    listSequinsByUser(uid: $uid) {
      id
      uuid
      regionid
      label
      bcHash
      colorr
      colorb
      colorg
      colora
      texture
      positionx
      positiony
      positionz
      external_name
      state
      claimedby
      message
      event{date_and_time description duration capacity chatgroupname label uuid stream_key id}
    }
  }
`;

export const allRegions = gql`
  query AllRegions {
    listRegions {
      id
      uuid
      label
      regiontype
      parentRegion
      external_name
    }
  }
`;

export const getPoll = gql`
  query GetPoll($pollId: Int!) {
    getPoll(pollId: $pollId) {
      pollid
      polltitle
      time_in_seconds
      choiceid
      experienceid
      title
      thumbnail
      description
      votes
      won
    }
  }
`;

export const getExperiences = gql`
  query GetExperiences($eventId: Int!, $expTypes: [String]) {
    getExperiences(eventId: $eventId, expTypes: $expTypes) {
      id
      type
      title
      url
      watched
    }
  }
`;

export const getUserEvents = gql`
  query GetUserEvents($uid: String!) {
    getEventsByUser(uid: $uid) {
      eventid
      state
    }
  }
`;

export const getPolls = gql`
  query GetPolls($eventId: Int!, $state: String) {
    getPolls(eventId: $eventId, state: $state) {
      pollid
      name
      title
      state
      time_in_seconds
      winning_choice_id
      winning_choice_title
      winning_choice_url
      winning_choice_desc
      winning_choice_thumbnail
    }
  }
`;

export const getScheduledExperiences = gql`
  query GetScheduledExperiences($eventId: Int!, $count: Int = 10) {
    getScheduledExperiences(eventId: $eventId, count: $count) {
      id
      experienceid
      type
      title
      url
      starttime
      stoptime
    }
  }
`;

export const getCurrentExperiences = gql`
  query GetCurrentExperiences($eventId: Int!) {
    getCurrentExperiences(eventId: $eventId) {
      id
      experienceid
      type
      title
      url
      starttime
      stoptime
    }
  }
`;

export const getSequin = gql`
  query GetSequin($id: Int!) {
    getSequin(id: $id) {
      id
      uuid
      external_name
      region_name
      label
      state
      claimedby
    }
  }
`;

export const getEvent = gql`
  query GetEvent($id: Int!) {
    getEvent(id: $id) {
      id
      uuid
      label
      date_and_time
      description
      chatgroupname
      chatgroupguid
      stream_key
      requires_registration
      stream_url
    }
  }
`;

export const getEventByUuid = gql`
  query GetEventByUuid($uuid: String!) {
    getEventByUuid(uuid: $uuid) {
      id
      uuid
      label
      date_and_time
      description
      chatgroupname
      chatgroupguid
      stream_key
      requires_registration
      stream_url
    }
  }
`;

export const getEvents = gql`
  query GetEvents {
    getEvents {
      id
      uuid
      label
      date_and_time
      description
      chatgroupname
      chatgroupguid
      stream_key
      requires_registration
      stream_url
    }
  }
`;

export const getNextAvailableSequin = gql`
  query getNextAvailableSequin {
    getNextAvailableSequin(limit: 1) {
      id
      uuid
      label
      colorr
      colorb
      colorg
      texture
      positionx
      positiony
      positionz
    }
  }
`;

export const getMediaBySequin = gql`
  query getMediaBySequin($sequin: Int!) {
    getMediaBySequin(sequin: $sequin) {
      id
      sequinid
      type
      label
      url
      bodytext
      displayname
      hometown
    }
  }
`;

export const getRsvps = gql`
  query getRsvps($eventId: Int!, $uid: String) {
    getRsvps(eventId: $eventId, uid: $uid) {
      id
      uuid
      eventid
      userid
      label
    }
  }
`;

export const listItemsByInvoice = gql`
  query listItemsByInvoice($invoiceid: Int!) {
    listItemsByInvoice(invoiceid: $invoiceid) {
      invoiceid
      id
      uuid
      label
      amount
    }
  }
`;

export const getInvoicesByUser = gql`
  query getInvoicesByUser($uid: String!, $state: String, $eventid: Int) {
    getInvoicesByUser(uid: $uid, state: $state, eventid: $eventid) {
      id
      label
      userid
      uuid
      stateid
      state
      total
      invoicehistory {
        paid_at
        created_at
      }
      paymentinformation {
        last_3
      }
      event {
        description
        duration
        capacity
        chatgroupname
        label
        uuid
        stream_key
        id
      }
    }
  }
`;

export const getInvoicesByUserPartitionedByEvent = gql`
  query getInvoicesByUserPartitionedByEvent($uid: String!, $state: String, $eventid: Int) {
    getInvoicesByUserPartitionedByEvent(uid: $uid, state: $state, eventid: $eventid) {
      partitionedEventName
      id
      label
      userid
      uuid
      stateid
      state
      total
      invoicehistory {
        paid_at
        created_at
      }
      paymentinformation {
        last_3
      }
      event {
        description
        duration
        capacity
        chatgroupname
        label
        uuid
        stream_key
        id
      }
    }
  }
`;

export const getInvoiceTotal = gql`
  query getInvoiceTotal($invoiceid: Int!) {
    getInvoiceTotal(id: $invoiceid) {
      total
    }
  }
`;

export const getInvoiceTotalByUser = gql`
  query getInvoiceTotalByUser($uid: String!) {
    getInvoiceTotalByUser(uid: $uid) {
      total
    }
  }
`;

export const getInvoice = gql`
  query getInvoice($id: Int!){
    getInvoice(id: $id) {
      id
      stateid
      state
      total
    }
  }
`;

export const getAccountByUser = gql`
  query getAccountByUser($uid: String!) {
    getAccountByUser(uid: $uid) {
      userid
      paymentmethod
      last_3
      address
      city
      state
      postal_code
      first_name
      last_name
    }
  }
`;

export const getPaymentInformationByUser = gql`
  query getPaymentInformationByUser($uid: String!) {
    getPaymentInformationByUser(uid: $uid) {
      id
      userid
      paymentmethod
      last_3
      address
      city
      state
      postal_code
      first_name
      last_name
      expiration
      aliasid
      created_at
      deleted
      active
      expired
    }
  }
`;

export const getActiveAliasByUser = gql`
  query getActiveAliasByUser($uid: String!) {
    getActiveAliasByUser(uid: $uid) {
      id
      userid
      paymentmethod
      last_3
      address
      city
      state
      postal_code
      first_name
      last_name
      expiration
      aliasid
      created_at
      deleted
      active
      expired
    }
  }
`;
export const getPaidInvoicesByUserOrderedByEvents = gql`
  query getPaidInvoicesByUserOrderedByEvents($uid: String!){
    getPaidInvoicesByUserOrderedByEvents(uid: $uid){
      invoices{
        id
        total
        paymentinformation{last_3}
        invoicehistory{paid_at}
      }
      event{
        date_and_time 
        chatgroupname
        id
      } 
    }
  }
`;