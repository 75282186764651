import React from "react";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/core/Debug/debugLayer"; // Augments the scene with the debug methods
import "@babylonjs/inspector"; // Injects a local ES6 version of the inspector to prevent automatically relying on the none compatible version

import Scene from "./3dScene";
import Gateway from "../logic/GatewayLogic";
import ForestState, { ForestStates } from "../logic/ForestStateLogic";
import EventManager from "../utilities/EventManager";

export default class Forest extends React.Component {
  constructor(props) {
    super();

    this.sceneLoadedHandler = props.sceneLoaded;
    this.forestState$ = undefined;
    this.data$ = undefined;
    this.scene = undefined;
    this.hlLayer = undefined;
    this.camera = undefined;
    this.materials = [];
  }

  componentWillUnmount() {
    // unsubscribe from all observables
    if (this.forestState$) this.forestState$.unsubscribe();
    if (this.data$) this.data$.unsubscribe();
  }

  _updateSequin = (sequinInfo, region) => {
    const sequin = this.scene.getMeshByName(sequinInfo.name);
    sequin.isPickable = true;
    sequin.metadata = {
      id: sequinInfo.id,
      name: sequinInfo.name,
      region: region,
    };
    this.hlLayer.addMesh(sequin, BABYLON.Color3.Blue()); // Any color
  };

  _animateCamera = (trackName, speed = 1, reverse = false) => {
    const track = this.scene.getMeshByName(trackName);
    const keysLength = track.animations[0]._keys.length;
    const start = 0;
    const end = track.animations[0]._keys[keysLength - 1].frame;
    const startFrame = reverse ? end : start;
    const endFrame = reverse ? start : end;
    this.camera.parent = track;
    this.camera.fov = 0.5200; // fill more of camera frame with trees camera.fov = 0.5200;
    return this.scene
      .beginAnimation(track, startFrame, endFrame, false, speed)
      .waitAsync();
  };

  _handleClickMain = () => {
    const scene = this.scene;
    let pickResult = scene.pick(scene.pointerX, scene.pointerY);

    if (pickResult.hit) {
      console.log("picked mesh: " + pickResult.pickedMesh.name);
      let region = Gateway.getRegionFromMesh(pickResult.pickedMesh.name);
      if (region) {
        ForestState.toRegionSelected(region);
      }
    }
  };

  _handleMouseMoveMain = () => {
    this.hlLayer.removeAllMeshes();

    let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

    if (pickResult.hit) {
      const r = Gateway.getRegionFromMesh(pickResult.pickedMesh.name);
      if (r) {
        r.meshes.forEach((m) =>
          this.hlLayer.addMesh(m, BABYLON.Color3.Green())
        );
      }
    }
  };

  _handleClickRegion = () => {
    const scene = this.scene;
    const region = ForestState.current.newState.data.name;
    let pickResult = scene.pick(scene.pointerX, scene.pointerY);

    if (pickResult.hit) {
      const mesh = pickResult.pickedMesh;
      console.log("picked mesh: " + mesh);
      // make sure a sequin was picked and the sequin is in the current region
      if (mesh.name.indexOf("sequin") === -1 || mesh.name.indexOf(region) === -1)
        return;

      ForestState.toSequinView(mesh.metadata);
    }
  };

  _handleStateChange = async (oldState, newState) => {
    if (oldState) this._cleanUpOldState(oldState);
    if (newState) await this._setUpNewState(oldState, newState);
  };

  _cleanUpOldState = (oldState) => {
    switch (oldState.type) {
      case ForestStates.main:
      case ForestStates.regionEntered:
        EventManager.removeAllListeners(window, "click");
        EventManager.removeAllListeners(window, "mousemove");
        break;

      default:
        break;
    }
  };

  _setUpNewState = async (oldState, newState) => {
    switch (newState.type) {
      case ForestStates.intro:
        await this._animateCamera("introToWorldEmpty_2", 1.6);
        ForestState.toMainView();
        break;

      case ForestStates.main:
        if (this.data$) this.data$.unsubscribe();

        if (oldState.type === ForestStates.regionEntered) {
          const region = oldState.data;
          await this._animateCamera(region.trackName, 2, true);
        }
        EventManager.addListener(window, "click", this._handleClickMain);
        EventManager.addListener(
          window,
          "mousemove",
          this._handleMouseMoveMain
        );
        break;

      case ForestStates.regionSelected:
        this.hlLayer.removeAllMeshes();
        let regionSelected = newState.data;
        if (oldState.type === ForestStates.main) {
          await this._animateCamera(regionSelected.trackName, 2);
        }
        ForestState.toRegionEntered(regionSelected);
        break;

      case ForestStates.regionEntered:
        let regionEntered = newState.data;
        this.data$ = Gateway.getRegion(regionEntered.name).stream.subscribe(
          (sequins) => {
            sequins.forEach((seq) => {
              this._updateSequin(seq, regionEntered);
            });
          }
        );
        setTimeout(
          () =>
            EventManager.addListener(window, "click", this._handleClickRegion),
          200
        ); // slight pause prevents the sequin info modal from immediately popping back open
        break;

      case ForestStates.sequin:
        if (this.data$) this.data$.unsubscribe();
        break;

      case ForestStates.sequinLink:
        let regionLinked = newState.data.region;
        await this._animateCamera(regionLinked.trackName, 2);
        ForestState.toSequinView(newState.data);
        break;

      default:
        break;
    }
  };

  onSceneReady = async (e) => {
    let that = this;
    const { scene } = e;
    this.scene = scene;

    // maybe use DeviceOrientationCamera so that tilting works on devices?
    this.camera = new BABYLON.UniversalCamera(
      "mainCamera",
      new BABYLON.Vector3(0, 0, 0),
      scene
    );

    // 1 * Math.PI / 3, Math.PI / 2.5, 100, BABYLON.Vector3.Zero(),

    /* 
        // PT-710 / provide User camera movement with limited horizional Angle and Zoom
        var camerasBorderFunction = function () {
          let alpha = -Math.PI / 2;
          let beta = Math.PI / 2;
          console.log("----  this.camera.beta ----" + beta);
          if (this.camera.beta < 0.1) this.camera.beta = 0.1; //Angle; // 0.1
          else if (this.camera.beta > (Math.PI / 2) * 0.9) this.camera.beta = (Math.PI / 2) * 0.9;   //Zoom
          if (this.camera.radius > 150) this.camera.radius = 150;
          if (this.camera.radius < 30) this.camera.radius = 30;
        };
        scene.registerBeforeRender(camerasBorderFunction);
        this.camera.attachControl(this.canvas, true);
    
     */
    const light1 = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 1, 0),
      scene
    );
    light1.intensity = 1.8;

    var defaultPipeline = new BABYLON.DefaultRenderingPipeline(
      "default",
      true,
      scene,
      [this.camera]
    );
    var curve = new BABYLON.ColorCurves();
    curve.globalHue = 200;
    curve.globalDensity = 80;
    curve.globalSaturation = 80;
    curve.highlightsHue = 240; // 20;
    curve.highlightsDensity = 100; // 80;
    curve.highlightsSaturation = 100; // -80;
    curve.shadowsHue = 2;
    curve.shadowsDensity = 80;
    curve.shadowsSaturation = 40;
    defaultPipeline.imageProcessing.colorCurves = curve;
    defaultPipeline.depthOfField.focalLength = 150;
    // sequin Bloom
    defaultPipeline.bloomEnabled = true;
    defaultPipeline.bloomThreshold = 0.8;
    defaultPipeline.bloomWeight = 0.3;
    defaultPipeline.bloomKernel = 64;
    defaultPipeline.bloomScale = 0.5;
    defaultPipeline.imageProcessingEnabled = true;

    const skybox = BABYLON.Mesh.CreateBox("skyBox", 600.0, scene); // 1500.0
    const skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    // WORKS with out below line
    skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
      "/textures/skybox/TropicalSunnyDay",
      scene
    );
    skyboxMaterial.reflectionTexture.coordinatesMode =
      BABYLON.Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    skyboxMaterial.disableLighting = true;
    skybox.material = skyboxMaterial;
    skybox.position.y = -30.0;
    skybox.isPickable = false;
    // --- original skyBox -------------------------------------------------------------------------------------------------------
    // let hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("/textures/environment/environment.dds",scene);
    // let hdrBox = scene.createDefaultSkybox(hdrTexture, true, 10000);
    // hdrBox.isPickable = false;
    // var hdrRotation = 180; // in degrees
    // hdrTexture.rotationY = BABYLON.Tools.ToRadians(hdrRotation);
    // --- original skyBox -------------------------------------------------------------------------------------------------------
    // --let hdrTexture = new BABYLON.HDRCubeTexture("/textures/firework.hdr", scene, 128, false, true, false, true);
    let hdrTexture = new BABYLON.HDRCubeTexture("/textures/environment/firework3.hdr", scene, 128, false, true, false, true);
    //  let hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("/textures/environment/studio.env", scene);
    //scene.createDefaultSkybox(new BABYLON.CubeTexture("textures/environment.env", scene),false,100,0,false);
    let hdrBox = scene.createDefaultSkybox(hdrTexture, true, 10000);

    hdrBox.isPickable = false;
    //  var hdrRotation = -60; // in degrees //-50
    //  hdrTexture.rotationY = BABYLON.Tools.ToRadians(hdrRotation);


    this.hlLayer = new BABYLON.HighlightLayer("hl1", scene);
    // Exclude Mesh from roll over
    this.hlLayer.addExcludedMesh(skybox);

    // PLEASE ADJUST AS NEEDED //
    // Description: Sets any mesh with 'sequin' in the mesh name to be pickable
    // init state for setSequinsPickable();
    //   this.hasCompletedSequinPickableInitRun = false;
    // Call once all the meshes are loaded > setSequinsPickable(hasCompletedSequinPickableInitRun)
    /*     this.setSequinsPickable = function (hasCompletedSequinPickableInitRun) {
            if (hasCompletedSequinPickableInitRun == false) {
                for (var i = 0; i < scene.meshes.length; i++) {
                    var mesh = scene.meshes[i];
                    var re = /sequin/gi;
                    var str = mesh.name;
                    if (str.search(re) == -1) {
                       // console.log("Does not contain sequin");
                    } else {
                        if (mesh.isPickable == false) {
                           // console.log("Sequin named " + str + " is pickable " + mesh.isPickable);
                           // console.log(" ---- setting pickable ----");
                            mesh.isPickable = true;
                        }
                    }
                }
            }
            return hasCompletedSequinPickableInitRun = true;
        } */
    // PLEASE ADJUST AS NEEDED //

    let bunchOfTrees = function () {
    // front  near row
 /*      for (var i = 0; i < 10; i++) {
        createTree("treeLowPoly_" + i, random(-14, 35), 0, random(13, 38), 2.0, true, scene, woodMaterial, grassMaterial); // random(-14, 35), 0, random(13, 38),
      } */
    // front  far row
/*       for (var j = 0; j < 20; j++) {
        createTree("treeLowPoly_0" + i, random(5, 40), 0, random(37, 55), 1.9, false, scene, woodMaterial, grassMaterial); // random(5, 40), 0, random(37, 55)
      } */
      // back far row
      for (var k = 0; k < 30; k++) {
        createTree("treeLowPolyBackFar_" + k, random(-46, -5), 0, random(52, 60), 6.0, false, scene, woodMaterial, grassMaterial); //  random(-46, -5), 0, random(52, 60),
      }
      // back near row
      for (var l = 0; l < 30; l++) {
        createTree("treeLowPolyBackNear_" + l, random(30, -60), 0, random(1, 20), 5.9, false, scene, woodMaterial, grassMaterial); //  random(30,-60), 0, random(1,20),
      }
    } 


    //Material declaration
    var woodMaterial = new BABYLON.StandardMaterial("lowPolyPineTrunk", scene);
    woodMaterial.diffuseColor = new BABYLON.Color3(0.76, 0.47, 0);// brown

    var grassMaterial = new BABYLON.StandardMaterial("lowPolyPineLeaves", scene);
    grassMaterial.diffuseColor = new BABYLON.Color3(0.0, 0.5, 0.0);
    grassMaterial.specularColor = new BABYLON.Color3(0.0, 0.5, 0.0);



    var createTree = function (name, x, y, z, sizeInput, strict, scene, woodMaterial, grassMaterial) {

      var size, l_height, l_width, trunk, leafs_1, leafs_2, leafs_3, leafs_4, leafs_5, leafs;
      if (strict) { //strict
        size = l_width = sizeInput;
        l_height = size * 0.2;
      } else { //randomize tree thickness & height, hopefully with realistic results.
        size = random(sizeInput * 0.80, sizeInput * 1.25);
        l_height = size * 0.2; // 0.2;
        l_width = random(size * 0.65, size * 0.8); // size * 0.65, size * 1.1
      }

      trunk = BABYLON.Mesh.CreateCylinder(name + "trunk", 1, 1, 1, 12, 1, scene);
      trunk.scaling = new BABYLON.Vector3(l_width * 0.2, size * 0.25, l_width * 0.2);
      trunk.position = new BABYLON.Vector3(x, y + (trunk.scaling.y * 0.5), z);
      trunk.material = woodMaterial;

      leafs_1 = BABYLON.Mesh.CreateCylinder(name + "leafs", l_height, l_width * 0.5, l_width * 1.0, 6, 1, scene, false);
      leafs_1.position = new BABYLON.Vector3(x, y + (trunk.scaling.y * 0.5) + (l_height), z);
      leafs_1.material = grassMaterial;

      leafs_2 = BABYLON.Mesh.CreateCylinder(name + "leafs", l_height, l_width * 0.4, l_width * 0.85, 6, 1, scene, false);
      leafs_2.position = new BABYLON.Vector3(x, (leafs_1.position.y) + (l_height), z);
      leafs_2.material = grassMaterial;

      leafs_3 = BABYLON.Mesh.CreateCylinder(name + "leafs", l_height, l_width * 0.3, l_width * 0.65, 6, 1, scene, false);
      leafs_3.position = new BABYLON.Vector3(x, (leafs_2.position.y) + (l_height), z);
      leafs_3.material = grassMaterial;

      leafs_4 = BABYLON.Mesh.CreateCylinder(name + "leafs", l_height, l_width * 0.2, l_width * 0.50, 6, 1, scene, false);
      leafs_4.position = new BABYLON.Vector3(x, (leafs_3.position.y) + (l_height), z);
      leafs_4.material = grassMaterial;

      leafs_5 = BABYLON.Mesh.CreateCylinder(name + "leafs", l_height * 1.5, l_width * 0, l_width * 0.325, 6, 1, scene, false);
      leafs_5.position = new BABYLON.Vector3(x, (leafs_4.position.y) + (l_height * 1.25), z);
      leafs_5.material = grassMaterial;

      leafs = BABYLON.Mesh.MergeMeshes([leafs_1, leafs_2, leafs_3, leafs_4, leafs_5], true, false, false);

      var treeLowPoly = BABYLON.Mesh.MergeMeshes([trunk, leafs], true, true, undefined, false, true);

      // RECENTER PIVOT at 0,0,0 ?
      const pivotAt = new BABYLON.Vector3(0, 0, 0); // 1, 1, 1
      const translation = treeLowPoly.position.subtract(pivotAt)
      treeLowPoly.setPivotMatrix(BABYLON.Matrix.Translation(translation.x, translation.y, translation.z));
      // RECENTER PIVOT ?
      treeLowPoly.isPickable = false;

    }

    function random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
      //create 5 strict and 5 non-strict trees
      for (var i = 0; i < 5; i++) {
        var randx = random(1, 25);
        randx *= random(1, 2) == 1 ? 1 : -1;
        var randz = random(20, 20);
        randz *= random(1, 2) == 1 ? 1 : -1;
      }
   // }

var newMeshes = [];
    let createStaticTrees = function (ground) {
      newMeshes[0].material.opacityTexture = null;
      newMeshes[0].material.backFaceCulling = false;
      newMeshes[0].isVisible = false;
      newMeshes[0].position.y = ground.getHeightAtCoordinates(0, 0); // Getting height from ground object
      // shadow2.getShadowMap().renderList.push(newMeshes[0]);
      var range = 60;
      var count = 100;
      for (var index = 0; index < count; index++) {
        var newInstance = newMeshes[0].createInstance("i" + index);
        var x = range / 2 - Math.random() * range;
        var z = range / 2 - Math.random() * range;
        var y = ground.getHeightAtCoordinates(x, z); // Getting height from ground object
        newInstance.position = new BABYLON.Vector3(x, y, z);
        newInstance.rotate(BABYLON.Axis.Y, Math.random() * Math.PI * 2, BABYLON.Space.WORLD);
        var scale = 0.5 + Math.random() * 2;
        newInstance.scaling.addInPlace(new BABYLON.Vector3(scale, scale, scale));
        //  shadow2.getShadowMap().renderList.push(newInstance);
      }
      // shadow2.getShadowMap().refreshRate = 0; // We need to compute it just once
      //  shadow2.usePoissonSampling = true;
    }  

    BABYLON.SceneLoader.ShowLoadingScreen = false;
    bunchOfTrees();

    await Promise.all([
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "setNoSequin_1c.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinOnlyNonPick29_l.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinCameraIntro_1.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinCameraRegion1.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinCameraRegion2.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinCameraRegion3.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "sequinCameraRegion4.babylon",
        scene
      ),
      BABYLON.SceneLoader.AppendAsync(
        "/models/",
        "cloud001.babylon",
        scene
        // cloud mesh and animation of 'Curve.001'(cloud001) Action
      ),
    ]);

    // preload region tree meshes
    Gateway.allRegions().forEach((r) => {
      r.treeNames.forEach((n) => {
        r.meshes.push(scene.getMeshByName(n));
      });
    });

    if (this.sceneLoadedHandler) this.sceneLoadedHandler();

    this.forestState$ = ForestState.stream.subscribe(
      async ({ oldState, newState }) => {
        await this._handleStateChange(oldState, newState);
      }
    );

    // var createReflectionProbes = function () {
    let probe = new BABYLON.ReflectionProbe("main", 512, scene);
    //  slower ? >   probe.refreshRate = BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONEVERYFRAME;
    probe.renderList.push(hdrBox); // probe.renderList.push(skybox);
    //}

    this.camera.onViewMatrixChangedObservable.add(function () {
      if (that.camera) {
        // console.log("rotation: " + that.camera.rotation.x + ", " + that.camera.rotation.y + ", " + that.camera.rotation.z);
        // console.log("position: " + that.camera.position.x + ", " + that.camera.position.y + ", " + that.camera.position.z);
      } else {
        console.log("no camera!");
      }
    });

    // scene.debugLayer.show();
    // not sure where the code for rthe debugLayer is, but needs below line
    // var nodeMaterial = new BABYLON.NodeMaterial("node material", scene, { emitComments: true });
    // debug GUI needs above line otherwise Inspector window hides when mesh selected.
  };

  onSceneRender = (e) => { };

  renderScene() {
    return (
      <Scene
        antialias={true}
        onSceneReady={this.onSceneReady}
        onRender={this.onSceneRender}
        className="forest-canvas"
      />
    );
  }

  render() {
    return <div className="forest-container">{this.renderScene()}</div>;
  }
}
