import React, { useEffect, useState } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMutation } from "@apollo/client";
import axios from "axios";
import * as QUERIES from "./../../graphql/queries";
import * as MUTATIONS from "../../graphql/mutations";
import RsvpPage1 from "./RsvpPage1";
//import RsvpPage2 from "./RsvpPage2";
import RsvpPage3 from "./RsvpPage3";
import RsvpPage4 from "./RsvpPage4";
import RsvpPage5 from "./RsvpPage5";
import RsvpPage6 from "./RsvpPage6";
import { Redirect } from "react-router";
import * as PaymentAPI from "../../utilities/PaymentAPI";
import { useAuth } from "../../hooks/UseAuth";
//import { zip } from "rxjs";
import EventLogic from "./../../logic/EventLogic";
import produce from "immer";

const Rsvp = (props) => {
  const auth = useAuth();
  const [valid, setValid] = useState(false);
  const [rsvpPage, setRsvpPage] = useState(1);
  const [eventPrice, setEventPrice] = useState("25.00");
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [creditCard, setCreditCard] = useState({
    cc: null,
    expMonth: null,
    expYear: null,
    cvv: null,
    firstName: "",
    lastName: "",
  });
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [purchaseSequin] = useMutation(MUTATIONS.purchaseSequin);
  const [createInvoice] = useMutation(MUTATIONS.createInvoice);
  const updateInvoiceCache = (cache, { data }) => {
    // add invoice item to cache
    const existingInvoiceItems = cache.readQuery({
      query: QUERIES.listItemsByInvoice,
      variables: { invoiceid: data.invoiceid },
    });
    if (existingInvoiceItems) {
      const newInvoiceItem = data.createInvoiceItem;
      cache.writeQuery({
        query: QUERIES.listItemsByInvoice,
        variables: { invoiceid: data.invoiceid },
        data: {
          listItemsByInvoice: [
            ...existingInvoiceItems.listItemsByInvoice,
            newInvoiceItem,
          ],
        },
      });
    }

    // update user total in cache
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: auth.user.username },
    });
    if (existingInvoiceTotalByUser) {
      var newInvoiceTotalByUser = JSON.parse(
        JSON.stringify(existingInvoiceTotalByUser)
      );
      newInvoiceTotalByUser.getInvoiceTotalByUser.total =
        existingInvoiceTotalByUser.getInvoiceTotalByUser.total +
        data.createInvoiceItem.amount;
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: auth.user.username },
        data: { getInvoiceTotalByUser: newInvoiceTotalByUser },
      });
    }

  };
  const [createInvoiceItem] = useMutation(MUTATIONS.createInvoiceItem, {
    update: updateInvoiceCache,
  });
  const updateMyPaymentsAndTotalCache = (cache, { data }) => {
    // update user total in cache back to 0
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: auth.user.username },
    });
    if (existingInvoiceTotalByUser) {
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: auth.user.username },
        data: { getInvoiceTotalByUser: 0 },
      });
    }
    // add paid invoice to cache
    try {
      const existingMyPayments = cache.readQuery({
        query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
        variables: { uid: auth.user.username },
      });
      if (existingMyPayments) {
        cache.writeQuery({
          query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
          variables: { uid: auth.user.username },
          data: {
            getPaidInvoicesByUserOrderedByEvents: produce(
              existingMyPayments.getPaidInvoicesByUserOrderedByEvents,
              (eventWithPayments) => {
                for (
                  var index = 0;
                  index <
                  existingMyPayments.getPaidInvoicesByUserOrderedByEvents
                    .length;
                  index++
                ) {
                  console.log(
                    "index",
                    existingMyPayments.getPaidInvoicesByUserOrderedByEvents[
                    index
                    ]
                  );
                  if (
                    existingMyPayments.getPaidInvoicesByUserOrderedByEvents[
                      index
                    ].event.id === EventLogic.currentEvent.id
                  ) {
                    const newPayment = data.updateInvoiceState;
                    eventWithPayments[index].invoices.push(newPayment);
                  }
                }
              }
            ),
          },
        });
      }
    } catch (error) {
      console.log("ERROR querying existing payments in cache: ", error);
    }
  };
  const [updateInvoiceState] = useMutation(MUTATIONS.updateInvoiceState, {
    update: updateMyPaymentsAndTotalCache,
  });

  const updateAliasCache = (cache, { data }) => {
    // update active alias
    const existingActiveAliasByUser = cache.readQuery({
      query: QUERIES.getActiveAliasByUser,
      variables: { uid: auth.user.username },
    });
    if (existingActiveAliasByUser) {
      var newActiveAliasByUser = JSON.parse(
        JSON.stringify(existingActiveAliasByUser)
      );
      newActiveAliasByUser = data;
      console.log(newActiveAliasByUser);
      cache.writeQuery({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: auth.user.username },
        data: { getActiveAliasByUser: newActiveAliasByUser },
      });
    }
  };

  const [createActivePaymentInformation] = useMutation(
    MUTATIONS.createActivePaymentInformation,
    {
      update: updateAliasCache,
    }
  );

  const getLast3DigitsOfCC = (cc) => {
    const last3 = `${cc.slice(cc.length - 3)}`;
    return last3;
  };

  const getExpirationForDB = (month, year) => {
    if (String(month).length === 2) {
      return String(month) + "20" + String(year);
    } else {
      return "0" + String(month) + "20" + String(year);
    }
  };

  const saveCreditAlias = async () => {
    setLoading(true);
    return await axios
      .post(
        PaymentAPI.ALIAS_URL,
        {
          userid: auth.user.username,
          first_name: creditCard.firstName,
          last_name: creditCard.lastName,
          address: address.address1,
          city: address.city,
          state: address.state,
          postal_code: address.zip,
          ccNumber: creditCard.cc,
          ccExpMonth: creditCard.expMonth,
          ccExpYear: creditCard.expYear,
          cvv: creditCard.cvv,
        },
        {
          headers: {
            "x-api-key": PaymentAPI.APIKEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("payment alias result", res);
        if (res.data.result === "Alias Created") {
          setPaymentError(false);
          console.log(res.data);
          createActivePaymentInformation({
            variables: {
              input: {
                aliasid: res.data.aliasid,
                userid: res.data.userid,
                first_name: creditCard.firstName,
                last_name: creditCard.lastName,
                address: address.address1,
                city: address.city,
                state: address.state,
                postal_code: address.zip,
                paymentmethod: res.data.paymentmethod,
                last_3: getLast3DigitsOfCC(creditCard.cc),
                expiration: getExpirationForDB(
                  creditCard.expMonth,
                  creditCard.expYear
                ),
              },
            },
          });
        }
        if (eventPrice === "0") setRsvpPage(rsvpPage + 1);
        console.log("aliasid returning is", res.data.aliasid);
        return res.data.aliasid;
      })
      .catch((err) => {
        console.log(err);
        setPaymentError(true);
        setLoading(false);
      });
  };

  const createAliasAndChargeAlias = async (invoiceID) => {
    setLoading(true);
    saveCreditAlias()
      .then((aliasRes) => {
        console.log("aliasRes", aliasRes);
        if (eventPrice !== "0" && aliasRes) {
          axios
            .post(
              PaymentAPI.CHARGE_URL,
              {
                aliasid: aliasRes,
                transactionAmount: eventPrice,
              },
              {
                headers: {
                  "x-api-key": PaymentAPI.APIKEY,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("payment charge res", res);
              if (res.data) {
                console.log("invoiceID is ", invoiceID);
                updateInvoiceState({
                  variables: {
                    input: {
                      id: parseInt(invoiceID),
                      stateid: 2,
                    },
                  },
                });
                setLoading(false);
                setRsvpPage(rsvpPage + 1);
              }
            })
            .catch((err) => {
              console.log(err);
              setPaymentError(true);
              setLoading(false);
            });
        } else {
          console.log("eventprice", eventPrice);
          console.log("aliasid", aliasRes);
        }
      })
      .catch((error) => console.log(error));
  };

  const submitPayment = async () => {
    setPaymentError(false);

    if (eventPrice) {
      console.log("creating invoice");
      try {
        await createInvoice({
          variables: {
            input: {
              eventid: EventLogic.currentEvent.id,
              userid: auth.user.username,
              label: "event fee invoice",
            },
          },
        }).then(async (data) => {
          console.log("createinvoice data", data);
          const invoiceID = data.data.createInvoice.id;
          if (invoiceID) {
            console.log("invoiceID is ", invoiceID);
            createInvoiceItem({
              variables: {
                input: {
                  invoiceid: invoiceID,
                  label: "event fee",
                  amount: parseFloat(eventPrice),
                },
              },
            });
            createAliasAndChargeAlias(invoiceID);

            await auth.registerUserToEvent(EventLogic.currentEvent.id);

            await purchaseSequin({
              variables: {
                input: {
                  eventid: EventLogic.currentEvent.id,
                  userid: auth.user.username,
                  price: 25.0,
                },
              },
            }); // TODO: where do eventid and price come from???
          }
        });

      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderActionButton = () => {
    let label = "";
    let action = null;

    switch (rsvpPage) {
      case 1:
        label = "Next";
        action = () => setRsvpPage(rsvpPage + 1);
        break;
      case 2:
        label = "Next";
        action = () => setRsvpPage(rsvpPage + 1);
        break;
      case 3:
        label = "Next";
        action = () => setRsvpPage(rsvpPage + 1);
        break;
      case 4:
        label = loading ? <CircularProgress color="white" /> : "Submit";
        action = submitPayment;
        break;
      case 5:
        label = "Close";
        action = () => setRsvpPage(null);
        break;
      default:
        break;
    }
    return valid ? (
      <Button
        className="button"
        variant="contained"
        size="large"
        color="primary"
        onClick={action}
      >
        {label}
      </Button>
    ) : (
      <Button
        disabled
        className="button"
        variant="contained"
        size="large"
        color="primary"
        onClick={action}
      >
        {label}
      </Button>
    );
  };

  return (
    <Grid direction="row" container wrap="nowrap">
      <Paper className="rsvp_wrapper left" variant="outlined">
        {rsvpPage === 1 ? (
          <RsvpPage1
            setValid={setValid}
            eventPrice={eventPrice}
            setEventPrice={setEventPrice}
          />
        ) : rsvpPage === 2 ? (
          <RsvpPage4
            setValid={setValid}
            setCreditCard={setCreditCard}
            creditCard={creditCard}
          />
        ) : rsvpPage === 3 ? (
          <RsvpPage3
            setAddress={setAddress}
            address={address}
            setValid={setValid}
          />
        ) : rsvpPage === 4 ? (
          <RsvpPage5
            userData={props.userData}
            eventPrice={eventPrice}
            fullName={props.fullName}
            address={address}
            creditCard={creditCard}
            setAddress={setAddress}
            setCreditCard={setCreditCard}
            paymentError={paymentError}
          />
        ) : rsvpPage === 5 ? (
          <RsvpPage6
            userData={props.userData}
            eventPrice={eventPrice}
            fullName={props.fullName}
            address={address}
            creditCard={creditCard}
            setAddress={setAddress}
            setCreditCard={setCreditCard}
          />
        ) : (
          <Redirect to="/" />
        )}
        {/* {rsvpPage === 1 ? (
          <RsvpPage1 />
        ) : rsvpPage === 2 ? (
          <RsvpPage2 />
        ) : rsvpPage === 3 ? (
          <RsvpPage3 setAddress={setAddress} />
        ) : rsvpPage === 4 ? (
          <RsvpPage4 setCreditCard={setCreditCard} creditCard={creditCard} />
        ) : rsvpPage === 5 ? (
          <RsvpPage5
            fullName={props.fullName}
            address={address}
            creditCard={creditCard}
            setAddress={setAddress}
            setCreditCard={setCreditCard}
          />
        ) : (
          <Redirect to="/" />
        )} */}{" "}
        <Grid className="form-actions" container>
          <Grid item xs={6}>
            {" "}
            {rsvpPage > 1 && rsvpPage !== 5 ? (
              <Button
                className="button"
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => {
                  if (rsvpPage === 2 || rsvpPage === 3) {
                    //console.log("rsvpPage", rsvpPage);
                    setCreditCard((prevState) => ({
                      ...prevState,
                      cc: null,
                      cvv: null,
                    }));
                    setValid(false);
                  }
                  setRsvpPage(rsvpPage - 1);
                }}
              >
                Back
              </Button>
            ) : null}
          </Grid>
          <Grid item container justify={"flex-end"} xs={6}>
            {renderActionButton()}
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className="checkout_wrapper right shaded"
        variant="outlined"
      ></Paper>
    </Grid>
  );
};

export default Rsvp;
