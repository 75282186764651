import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Container,
  Grid,
  Paper,
  Fab,
  SvgIcon,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { REACT_APP_BASE_URL, SocialShare } from "./SocialShare";

import * as QUERIES from "../graphql/queries";

const SequinInfo = ({ sequin = 11457, visible = false, onClose = null }) => {
  const [open, setOpen] = useState(visible);

  useEffect(() => {
    if (visible !== open) {
      setOpen(visible);
    }
  }, [visible, open]);

  const handleClose = () => {
    if (onClose) onClose();
  };
  const {
    loading: mediaLoading,
    error: mediaError,
    data: mediaData,
  } = useQuery(QUERIES.getMediaBySequin, {
    variables: { sequin },
  });

  const sequinUrl = `${REACT_APP_BASE_URL}/forest/${sequin}`;

  const body = mediaLoading ? (
    <h2>Loading...</h2>
  ) : mediaError ? (
    <h2>There has been an error.</h2>
  ) : (
    <div className="info-side">
      <Fab className="btn-close" onClick={handleClose}>
        <Close />
      </Fab>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <header className="page-header">
            <div className="page-info">
              <h2>{mediaData?.getMediaBySequin?.label}</h2>
              <span className="divider"></span>
            </div>
          </header>
        </Grid>

        <div className="sequin-view">
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449 449">
              <defs />
              <path
                fillRule="evenodd"
                d="M224.5 449C100.512 449 0 348.488 0 224.5S100.512 0 224.5 0 449 100.512 449 224.5 348.488 449 224.5 449zm.5-344c22.644 0 41-18.356 41-41s-18.356-41-41-41-41 18.356-41 41 18.356 41 41 41z"
              />
            </svg>
          </SvgIcon>
        </div>

        <Grid item xs={12} className="sequin-owner">
          <div>
            <h2>{mediaData?.getMediaBySequin?.displayname}</h2>
            <h3>{mediaData?.getMediaBySequin?.hometown}</h3>
          </div>
        </Grid>

        <Grid item xs={12} className="sequin-detail">
          <div>
            {mediaData?.getMediaBySequin?.bodytext}
            {/* <p>{mediaData.getMediaBySequin.url}</p> */}
          </div>
        </Grid>
      </Grid>
      <div className="sequin-social">
        <SocialShare
          //label={mediaData.getMediaBySequin.label}
          //postBody={mediaData.getMediaBySequin.bodytext}
          url={sequinUrl}
          description="I contributed to St. Jude Children’s Research Hospital lifesaving work in the St. Jude Virtual Sequin Forest. Check out my sequin!"
          title="My Sequin for the St. Jude Virtual Sequin Forest"
          postBody="I contributed to St. Jude Children’s Research Hospital lifesaving work in the St. Jude Virtual Sequin Forest. Check out my sequin!"
          displayMode="list"
        />
      </div>
    </div>
  );

  return open ? (
    <div className="darken">
      <Container maxWidth="xs">
        <Paper className="form-page">{body}</Paper>
      </Container>
    </div>
  ) : null;
};

export default SequinInfo;
