export async function ValidateContactInfo(
  auth,
  fullName,
  email,
  phone,
  setError,
  currentEmail
) {
  const errors = [];

  const validFullName = () => {
    let name_rgx = /^[a-z ,.'-]+$/i;
    return name_rgx.test(fullName) && fullName.length > 2;
  };

  const validEmail = () => {
    let email_rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email_rgx.test(email) && email.length > 0;
  };

  const availableEmail = async () => {
    return await auth.userNameAvailable(email.toLowerCase());
  };

  const validPhone = () => {
    let formatPhone = phone.replace(/[() -]/g, "");
    return formatPhone.length > 11;
  };

  //full name
  let isValidFullName = validFullName();
  setError((prevState) => ({
    ...prevState,
    fullName: !isValidFullName,
  }));
  if (!isValidFullName) {
    errors.push("fullName");
  }

  // email
  let isValidEmail = validEmail();
  setError((prevState) => ({
    ...prevState,
    email: !isValidEmail,
  }));
  if (!isValidEmail) {
    console.log("wrong email format");
    errors.push("email");
    setError((prevState) => ({
      ...prevState,
      email: true,
    }));
  }

  let available = await availableEmail();
  if (!available && currentEmail !== email) {
    console.log("already claimed");
    console.log("current email is ", currentEmail);
    console.log("new email is ", email);
    errors.push("email");
    setError((prevState) => ({
      ...prevState,
      userExists: true,
    }));
  }

  //phone
  let isValidPhone = validPhone();
  setError((prevState) => ({
    ...prevState,
    phone: !isValidPhone,
  }));
  if (!isValidPhone) {
    errors.push("phone");
  }

  return errors;
}
