import React from "react";
import Fade from "./Fade";
import { CircularProgress } from '@material-ui/core';


const LoadingScreen = ({ show }) => {

  return (
    <Fade show={show}>
      <div className="loading-screen">
        <h2 className="loading-text">Loading Experience ...</h2>
        <CircularProgress className="loading-spinner" size="5rem"/>
      </div>
    </Fade>
  );
};

export default LoadingScreen;
