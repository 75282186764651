import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';

const DonateButton = withStyles({
    root: {
      textTransform: 'none',
      //fontSize: 16,
      //padding: '6px 12px',
      border: '0.5px solid',
      //lineHeight: 1.5,
      //backgroundColor: '#0063cc',
      borderColor: grey[300],
      '&:hover': {
        backgroundColor: grey[200],
      },
    },
  })(Button);

export default DonateButton;