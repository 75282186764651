import React from "react";
import { Container } from "@material-ui/core";

import Login from "./../components/Login";

const PageLogin = () => {
  return (
    <div className="login_container_wrapper">
      <Container className="login_container">
        <Login />
      </Container>
    </div>
  );
};

export default PageLogin;
