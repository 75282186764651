import React, { useState, useEffect } from "react";
import { useSubscription } from "observable-hooks";
import {
  TextField,
  CircularProgress,
  Link,
  Button,
  Grid,
  Container,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/UseAuth";
import { AuthStatus } from "../logic/AuthLogic";
import EventLogic, { EventStatus } from "../logic/EventLogic";
import ChatLogic from "../logic/ChatLogic";
import EventAuthLogic from "../logic/EventAuthLogic";
import EventNotStarted from "./EventNotStarted";
import PageLoading from "./PageLoading";

const SecCodeStatus = {
  unsent: "unsent",
  sent: "sent",
  resent: "resent",
  timedOut: "timedOut",
  incorrectCode: "incorrectCode",
};
const email_rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const EventPortal = ({ match }) => {
  const [userData, setUserData] = useState({});
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [secCodeStatus, setSecCodeStatus] = useState(SecCodeStatus.unsent);
  const [displayForm, setDisplayForm] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    // set the event (globally) based on the uuid in the url
    async function initEvent() {
      if (match.params.uuid) {
        try {
          await EventLogic.setEventByUuid(match.params.uuid);
        } catch (err) {
          history.push(`/`); // EventLogic couldn't find an event for the uuid (or something else went wrong) so bail
          return;
        }
      } else {
        // no event uuid in the url so bail
        history.push(`/`);
        return;
      }
    }

    initEvent();
  }, [match, history]);

  useSubscription(EventAuthLogic.stream, async (o) => {
    if (!o) return;

    let e = o.event;
    let a = o.auth;

    setEvent(e);
    if (e.status === EventStatus.postEvent) history.push(`/forest`);
    if (e.status === EventStatus.openEvent) {
      switch (a.state) {
        case AuthStatus.authFail:
        case AuthStatus.authLogout:
        case AuthStatus.authNotConfirmed:
          setDisplayForm(true);
          break;

        case AuthStatus.authSuccess:
          await auth.registerUserToEvent(e); // register user to event if not already registered
          await auth.updateUserEventStatus(e.id, "checkedin");
          setLoading(false);
          history.push(`/live`); // the user is authenticated and registered so send him to the event
          break;

        default:
          break;
      }
    }
  });

  useEffect(() => {
    if (secCodeStatus === SecCodeStatus.unsent)
      setValid(
        userData.name?.length > 0 &&
        userData.email?.length > 0 &&
        email_rgx.test(userData.email)
      );
    else setValid(userData.passcode?.length > 0);
  }, [secCodeStatus, userData]);

  const handleUserDataChange = (event) => {
    const { id, value } = event.target;

    setUserData((user) => ({
      ...user,
      [id]: value,
    }));
  };

  const handleSendClick = async () => {
    setUserData((user) => ({
      ...user,
      passcode: "",
    }));

    const user = {
      username: userData.email.toLowerCase(),
      password: Date.now().toString(),
      attributes: {
        name: userData.name,
        email: userData.email.toLowerCase(),
      },
    };
    try {
      setLoading(true);
      await auth.signUpNoPassword(user);
      const signedInUser = await auth.signInNoPassword(user.username);
      await ChatLogic.updateUser(
        signedInUser.challengeParam.USERNAME,
        user.attributes.name
      );
      setLoading(false);
      setSecCodeStatus(SecCodeStatus.sent);
    } catch (err) {
      console.error(err);
    }
  };

  const handleReSendClick = async () => {
    setSecCodeStatus(SecCodeStatus.resent);
    try {
      await auth.signInNoPassword(userData.email.toLowerCase());
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendCodeClick = async () => {
    try {
      setLoading(true);
      await auth.sendChallenge(userData.passcode);
    } catch (err) {
      setLoading(false);
      switch (err.message) {
        case "Invalid code":
          setSecCodeStatus(SecCodeStatus.incorrectCode);
          break;

        case "Invalid session for the user.":
          setSecCodeStatus(SecCodeStatus.timedOut);
          break;

        default:
          throw err;
      }
    }
  };

  const renderResendMessage = () => {
    if (secCodeStatus === SecCodeStatus.incorrectCode) {
      return (
        <div className="verifyErrorMessage">
          Sorry, the code you entered is incorrect. Please enter the correct
          code or try&nbsp;
          <Link style={{ fontWeight: "bold" }} onClick={handleReSendClick}>
            resending a verification code.
          </Link>
        </div>
      );
    }
    if (secCodeStatus === SecCodeStatus.timedOut) {
      return (
        <div className="verifyErrorMessage">
          Sorry, your session has timed out. Please try&nbsp;
          <Link style={{ fontWeight: "bold" }} onClick={handleReSendClick}>
            resending a verification code.
          </Link>
        </div>
      );
    }
    if (secCodeStatus === SecCodeStatus.resent) {
      return (
        <div className="verifyMessage">
          The verification code has be re-sent. Please check your inbox.
        </div>
      );
    }
    return (
      <div className="verifyMessage">
        Didn't receive the code?&nbsp;
        <Link style={{ fontWeight: "bold" }} onClick={handleReSendClick}>
          Resend verification code.
        </Link>
      </div>
    );
  };

  const renderNameView = () => {
    return (
      <Grid direction="column" container wrap="nowrap" spacing={2}>
        <Grid item>
          <h3 className="formgroup-header">Contact Information</h3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            id="name"
            value={userData.name || ""}
            label="Your Display Name"
            variant="outlined"
            onChange={handleUserDataChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item style={{ paddingTop: "0px" }}>
          This will be used as your display name throughout the event.
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            id="email"
            value={userData.email ? userData.email : ""}
            label="Email Address"
            variant="outlined"
            onChange={handleUserDataChange}
            fullWidth
            required
          />
        </Grid>
      </Grid>
    );
  };

  const renderSecCodeView = () => {
    return (
      <Grid direction="column" container wrap="nowrap" spacing={2}>
        <Grid item style={{ paddingTop: "50px", fontSize: "18px" }}>
          A verification code has been sent to your email. It may take 1-3
          minutes.
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="passcode"
            id="passcode"
            value={userData.passcode}
            label="Verification code"
            variant="outlined"
            onChange={handleUserDataChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item style={{ paddingTop: "0px" }}>
          {renderResendMessage()}
        </Grid>
      </Grid>
    );
  };

  const renderView = () => {
    return secCodeStatus === SecCodeStatus.unsent
      ? renderNameView()
      : renderSecCodeView();
  };

  const renderNextButton = () => {
    let nextAction;
    let label;

    if (secCodeStatus === SecCodeStatus.unsent) {
      nextAction = handleSendClick;
      label = "Next";
    } else {
      nextAction = handleSendCodeClick;
      label = "Join";
    }

    return valid ? (
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={nextAction}
        style={{ width: "5rem", height: "2.7rem" }}
      >
        {loading ? <CircularProgress color="white" /> : label}
      </Button>
    ) : (
      <Button
        disabled
        size="large"
        variant="contained"
        color="primary"
        style={{ width: "5rem", height: "2.7rem" }}
      >
        {label}
      </Button>
    );
  };

  const renderBackButton = () => {
    return secCodeStatus !== SecCodeStatus.unsent ? (
      <Button
        size="large"
        variant="outlined"
        color="primary"
        style={{ width: "5rem", height: "2.7rem" }}
        onClick={() => setSecCodeStatus(SecCodeStatus.unsent)}
      >
        Back
      </Button>
    ) : null;
  };

  return (
    <>
      {!event ? (<PageLoading />) :
        event.status === EventStatus.preEvent ? (
          <EventNotStarted />
        ) : (
          <>
            {displayForm ? (
              <Container className="checkin_outer_container">
                <Grid
                  direction="row"
                  container
                  wrap="nowrap"
                  className="checkin-grid-container"
                >
                  <Paper className="checkout_wrapper left" variant="outlined">
                    <Grid
                      direction="column"
                      container
                      className="checkin_container"
                    >
                      <Grid item>
                        <header className="page-header">
                          <div>
                            <h2>
                              {secCodeStatus === SecCodeStatus.unsent
                                ? "Welcome"
                                : "Please Verify Your Email"}
                            </h2>
                            <span className="divider"></span>
                          </div>
                        </header>
                      </Grid>
                      {renderView()}
                      <Grid
                        container
                        direction="row-reverse"
                        justify="space-between"
                        className="checkin_button_container"
                      >
                        {renderNextButton()}
                        {renderBackButton()}
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    className="checkout_wrapper right shaded"
                    variant="outlined"
                  ></Paper>
                </Grid>
              </Container>
            ) : (<PageLoading />)}
          </>
        )}
    </>
  );
};

export default EventPortal;
