import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Grid,
  TextField,
  CircularProgress,
  SvgIcon,
  withStyles,
  Tooltip
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import ExactTargetAPI from "../utilities/ExactTargetAPI";
import EmailLogic from "../logic/EmailLogic";
import * as QUERIES from "../graphql/queries";
import * as MUTATIONS from "../graphql/mutations";
import EventLogic from "../logic/EventLogic";
import { useAuth } from "../hooks/UseAuth";
import Filter from "bad-words";

import { useHistory } from "react-router-dom";

const Button = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto"
    }
  }
})(MuiButton);

const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick
  };
  return (
    <Tooltip title={tooltipText}>
      <Button {...other} {...adjustedButtonProps} />
    </Tooltip>
  );
};

export async function Validate(displayName, hometown, message, setError) {
  const filter = new Filter();
  const errors = [];

  const validDisplayName = () => {
    if(filter.isProfane(displayName)){
      setError((prevState) => ({
        ...prevState,
        displayNameError: true,
      }));
    }else{
      setError((prevState) => ({
        ...prevState,
        displayNameError: false,
      }));
    }
    if (displayName.length > 50) {
      //console.log("displayname too long");
      setError((prevState) => ({
        ...prevState,
        displayNameLengthError: true,
      }));
    } else {
      //console.log("display name length ok");
      setError((prevState) => ({
        ...prevState,
        displayNameLengthError: false,
      }));
    }
    return !filter.isProfane(displayName) && displayName.length <= 50;
  }

  const validHometown = () => {
    if(filter.isProfane(hometown)){
      setError((prevState) => ({
        ...prevState,
        hometownError: true,
      }));
    }else{
      //console.log("hometown", hometown," is not profane");
      setError((prevState) => ({
        ...prevState,
        hometownError: false,
      }));
    }
    if (hometown.length > 50) {
      setError((prevState) => ({
        ...prevState,
        hometownLengthError: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        hometownLengthError: false,
      }));
    }
    return !filter.isProfane(hometown) && hometown.length <= 50;
  }

  const validMessage = () => {
    if(filter.isProfane(message)){
      setError((prevState) => ({
        ...prevState,
        messageError: true,
      }));
    }else{
      setError((prevState) => ({
        ...prevState,
        messageError: false,
      }));
    }
    if (message.length > 200) {
      setError((prevState) => ({
        ...prevState,
        messageLengthError: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        messageLengthError: false,
      }));
    }
    return !filter.isProfane(message) && message.length <= 200;
  }  

  if(!validDisplayName()){
    errors.push("displayName");
  }

  if(!validHometown()){
    errors.push("hometown");
  }

  if(!validMessage()){
    errors.push("message");
  }

  return errors;
}


const SequinForm = ({ setShowBackButton }) => {
  const filter = new Filter();

  const history = useHistory();

  const [review, setReview] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [hometown, setHometown] = useState("");
  const [message, setMessage] = useState("");
  const [claimingSequin, setClaimingSequin] = useState(false);
  const [error, setError] = useState({
    error: true,
    displayNameError: false,
    displayNameLengthError: false,
    hometownError: false,
    hometownLengthError: false,
    messageError: false,
    messageLengthError: false,
    errorText: "Please Retry",
    lengthExceededErrorText: "Please enter 200 characters or less",
    hometownLengthExceededErrorText: "Please enter 50 characters or less",
    displayNameLengthExceededErrorText: "Please enter 50 characters or less",
  });

  const {
    loading: sequinLoading,
    error: sequinError,
    data: sequinData,
  } = useQuery(QUERIES.getNextAvailableSequin);
  const [claimAvailableSequin] = useMutation(MUTATIONS.claimSequinWithMedia);
  const auth = useAuth();

  const handleReview = async () => {
    const errors = await Validate(displayName, hometown, message, setError);
    //console.log("errors.length", errors.length);
    //console.log("errors", errors);
    const hasErrors = Boolean(errors.length);
    if (!hasErrors) {
      setReview(!review);
    }
  };

  const claimSequin = () => {
    setClaimingSequin(true);
    claimAvailableSequin({
      variables: {
        input: {
          user_id: auth.user.username,
          eventid: EventLogic.currentEvent.id,
          sequinid: sequinData.getNextAvailableSequin.id,
          type: "text",
          label: "Sequin",
          url: "someURL",
          price: 35.0,
          bodytext: message.replace(/'/g, "''"),
          displayname: displayName.replace(/'/g, "''"),
          hometown: hometown.replace(/'/g, "''"),
        },
      },
    }).then(() => {
      auth
        .updateUserEventStatus(EventLogic.currentEvent.id, "leftmark")
        .then(() => {
          setClaimingSequin(false);
          const sequinPath = `/forest/${sequinData.getNextAvailableSequin.id}`;
          const request = ExactTargetAPI.createSendSequinRequest(auth.user.attributes.email, `${window.location.origin}${sequinPath}`)
          EmailLogic.send(request);
          history.push(sequinPath);
        });
    });
  };

  useEffect(() => {
    setShowBackButton(false);
  }, []);

  useEffect(() => {
    if (displayName.length > 50) {
      //console.log("displayname too long");
      setError((prevState) => ({
        ...prevState,
        displayNameLengthError: true,
      }));
    } else {
      //console.log("display name length ok");
      setError((prevState) => ({
        ...prevState,
        displayNameError: false,
        displayNameLengthError: false,
      }));
    }
  }, [displayName]);

  useEffect(() => {
    if (hometown.length > 50) {
      setError((prevState) => ({
        ...prevState,
        hometownLengthError: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        hometownError: false,
        hometownLengthError: false,
      }));
    }
  }, [hometown]);

  useEffect(() => {
    if (message.length > 200) {
      setError((prevState) => ({
        ...prevState,
        messageLengthError: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        messageError: false,
        messageLengthError: false,
      }));
    }
  }, [message]);

  const handleChange = ({ target }) => {
    let fieldName = target.id;
    //console.log("target is ", target.value);
    if (fieldName === "displayName" && filter.isProfane(target.value)) {
      setError((prevState) => ({
        ...prevState,
        displayNameError: true,
      }));
    } else if (fieldName === "displayName" && !filter.isProfane(target.value)) {
      setDisplayName(target.value);
    }
    if (fieldName === "hometown" && filter.isProfane(target.value)) {
      setError((prevState) => ({
        ...prevState,
        hometownError: true,
      }));
    } else if (fieldName === "hometown" && !filter.isProfane(target.value)) {
      setHometown(target.value);
    }
    if (fieldName === "message" && filter.isProfane(target.value)) {
      setError((prevState) => ({
        ...prevState,
        messageError: true,
      }));
    } else if (fieldName === "message" && !filter.isProfane(target.value)) {      
      setMessage(target.value);
    }
  };

  const sequinForm = (
    <>
      <Grid container className="sequin-form-main-content">
        <Grid item className="sequin-form-grid-item">
          You have completed your journey and it is now time to leave your
          unique mark on this masterpiece. Please personalize your sequin that
          will live in the St. Jude Virtual Sequin Forest.
        </Grid>
        <Grid item xs={12} className="sequin-form-grid-item">
          <TextField
            id="displayName"
            value={displayName}
            required
            error={error.displayNameError || error.displayNameLengthError}
            helperText={error.displayNameLengthError
              ? error.displayNameLengthExceededErrorText
              : error.displayNameError
                ? error.errorText
                : ""
            }
            label="Your Display Name"
            required
            variant="outlined"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className="sequin-form-grid-item">
          <TextField
            id="hometown"
            value={hometown}
            error={error.hometownError || error.hometownLengthError}
            helperText={error.hometownLengthError 
              ? error.hometownLengthExceededErrorText
              : error.hometownError 
                ? error.errorText
                : ""
            }
            label="Your Hometown"
            variant="outlined"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} className="sequin-form-grid-item">
          <TextField
            id="message"
            value={message}
            error={error.messageError || error.messageLengthError}
            helperText={
              error.messageLengthError
                ? error.lengthExceededErrorText
                : error.messageError
                  ? error.errorText
                  : ""
            }
            label="Your Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container className="sequin-form-submit-button-wrapper">
        <Grid
          item
          //xs={12}
          //sm={3}
          container
          className="sequin-form-submit-grid-item"
        >
          {!error.displayNameError &&
            displayName.length >= 1 &&
            !error.messageError &&
            !error.displayNameError &&
            !error.hometownError &&
            !error.messageLengthError &&
            !error.displayNameLengthError &&
            !error.hometownLengthError ? 
          (
            <Button
              style={{ marginTop: "1rem" }}
              className="leave-mark-submit-button"
              variant="contained"
              size="large"
              color="primary"
              onClick={handleReview}
            >
              Review
            </Button>
          ) : (
            <ButtonWithTooltip
              tooltipText={displayName.length === 0 ? "Enter at least one character in the display name": ""}
              style={{ marginTop: "1rem" }}
              disabled
              className="leave-mark-submit-button"
              variant="contained"
              size="large"
              color="primary"
              onClick={handleReview}
            >
              Review
            </ButtonWithTooltip>
          )}
        </Grid>
      </Grid>
    </>
  );

  const reviewSequin = !sequinLoading ? (
    <>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className="sequin-review-main-content-container"
      >
        <div className="sequin-review-container">
          <div className="sequin-review">
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449 449">
                <defs />
                <path
                  fillRule="evenodd"
                  d="M224.5 449C100.512 449 0 348.488 0 224.5S100.512 0 224.5 0 449 100.512 449 224.5 348.488 449 224.5 449zm.5-344c22.644 0 41-18.356 41-41s-18.356-41-41-41-41 18.356-41 41 18.356 41 41 41z"
                />
              </svg>
            </SvgIcon>
          </div>

          <div className="sequin-content-checkout">
            <div className="displayname">
              <h2>{displayName}</h2>
            </div>
            <div className="hometown">
              <h2>{hometown}</h2>
            </div>
            <div className="msg">
              <h2>{message}</h2>
            </div>
          </div>
        </div>
      </Grid>

      <div className="review-leave-your-mark-buttons-grid-container">
        {claimingSequin ? (
          <div className="build-progress">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="back-to-edit-grid-item">
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleReview}
                fullWidth
                className="back-to-edit-button"
              >
                Back to Edit
              </Button>
            </div>
            <div className="build-grid-item">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={claimSequin}
                fullWidth
                className="build-button"
              >
                Build
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  ) : null;

  return (
    <>
      {!sequinLoading ? (
        <>{!review ? sequinForm : reviewSequin}</>
      ) : (
        <div className="sequin-form-circular-progress">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default SequinForm;
