import React from "react";
//import { Container, Paper } from "@material-ui/core";

import Home from "./../components/Home";

const PageHome = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default PageHome;
