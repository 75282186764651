import React, { useState, useEffect } from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import Fade from "./Fade";

const PollWinner = ({ pollChoice }) => {

  const [winningChoice, setWinningChoice] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setWinningChoice(pollChoice);
    }, 2000);
  }, [])

  const renderWinnerMessage = () => {
    if (!winningChoice) return null;
    return (
      <Fade show={winningChoice}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
          <img src={winningChoice.winningChoiceThumbnail} alt="name" style={{ width: "300px" }} />
          <div style={{ fontSize: "24px", fontWeight: "800", marginTop: "15px", textAlign: "center" }}>{winningChoice.winningChoiceTitle}</div>
          <img style={{ width: "8rem", marginTop: "2rem" }} src="trophy.svg"></img>
          <div style={{ fontSize: "1.25rem", fontWeight: "700", marginTop: "1.5rem", marginBottom: "1rem" }}>Loading experience ...</div>
        </div>
      </Fade>);
  }

  return (
    <Paper className="poll_wrapper" variant="outlined">
      <Grid container direction="column" style={{ marginTop: "20px", justifyContent: "center" }}>
        <Grid container direction="column" style={{ fontSize: "21px", fontWeight: "700", paddingBottom: "15px", alignItems: "center" }}>And the winner is ...</Grid>
        <Grid container>
          <Grid item style={{ width: "45%" }} />
          <Grid item style={{ width: "10%", borderTopStyle: "solid", borderTopWidth: "4px" }} />
          <Grid item style={{ width: "45%" }} />
        </Grid>
        {renderWinnerMessage()}
      </Grid>
    </Paper>
  )
}

export default PollWinner;
