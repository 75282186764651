import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';

const UnblockPlayerSoundModal = (props) => {
    const { onClose, open } = props;
    const handleClose = () => {
        onClose();
    }
    return (
        <Dialog className="join-stream-modal-dialog" aria-labelledby="join-stream-modal" open={open}>
          <DialogTitle className="join-stream-modal-title" id="join-stream-modal-dialog-title">Live stream has begun</DialogTitle>
            <div className="join-stream-button-wrapper">
              <Button onClick={handleClose} variant="contained" color="primary">
                Join
              </Button>
            </div>
          </Dialog>
    );
}

export default UnblockPlayerSoundModal;