import React from "react";
import {
  Button,
  Grid,
  Typography
} from "@material-ui/core";
import { useAuth } from "../../../hooks/UseAuth";
import { Link } from "react-router-dom";

const Legal = ({
  userInfo,
  setShowBackButton,
  setShowNextButton,
  setHeader,
  userExistsNotConfirmed,
}) => {
  const auth = useAuth();
  const firstName = userExistsNotConfirmed
    ? "Your Account is Confirmed!"
    : userInfo.fullName.substr(0, userInfo.fullName.indexOf(" "));
  console.log(auth.user.attributes);
  setShowBackButton(false);
  setShowNextButton(false);
  setHeader(`Welcome, ${firstName}`);
  console.log("user info", userInfo);

  return (
    <Grid direction="column" container wrap="nowrap" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption" className="sub-header">
          <span style={styles.header}>Congrats!</span> You've created your
          account. Now let's finish up.
        </Typography>
      </Grid>

      <Grid
        container
        xs={12}
        direction="row"
        justify="center"
        style={{ paddingTop: "15px" }}
      >
        <Link
          to={{
            pathname: `/rsvp`,
            state: {
              userInfo: userInfo,
            },
          }}
          style={{ textDecoration: "none" }}
        >
          <Button size="large" variant="contained" color="primary">
            Register For an Event
          </Button>
        </Link>

        {/* Button to close after succesfully confirming account and user does not want to RSVP */}

        {/* <Link
          to={`${process.env.PUBLIC_URL}`}
          style={{ textDecoration: "none" }}
        >
          <Button size="large" variant="contained" color="primary">
            Close
          </Button>
        </Link> */}
      </Grid>
    </Grid>
  );
};

const styles = {
  header: {
    fontWeight: "900",
  },
};

export default Legal;
