import React from "react";
import { Fab } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { withRouter } from "react-router-dom";

import VideoPlayer from "./../components/VideoPlayer";

const PageVideo = ({ match, ...props }) => {
  let src, nextRoute;
  if (match.params.vkey === "introduction") {
    src =
      "https://sjexperience-media.s3.amazonaws.com/videos/ve_experience_intro.mp4";
    nextRoute = "/forest";
  } else {
    src =
      "https://sjexperience-media.s3.amazonaws.com/videos/ve_experience_splash.mp4";
    nextRoute = "/video/introduction";
  }

  const handleNavOnEnd = () => {
    props.history.push(nextRoute);
  };

  return (
    <>
      <div className="ui-controls nav full">
        <div className="middle right">
          <Fab
            aria-label="skip"
            onClick={() => {
              props.history.push(nextRoute);
            }}
            className={"skip-button"}
          >
            <ArrowForwardIcon />
          </Fab>
        </div>
      </div>
      <VideoPlayer src={src} onEnd={handleNavOnEnd} />
    </>
  );
};

export default withRouter(PageVideo);
