import React, { useState } from "react";
import { Button, 
  //makeStyles, 
  TextField, InputAdornment } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

/*
const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-error": {
      //color: "blue",
      //position: "absolute"
    },
  },
}));
*/

const OtherAmount = (props) => {
  //const classes = useStyles();

  const initialInputs = {
    id: "otherAmount",
    value: "",
    error: false,
    helperText: "",
    getHelperText: (error) =>
      error ? "Donations must be at least $5 and in a whole number." : "",
    isValid: (value) => /^(([5-9])+|(\d){2,})$/g.test(value),
  };

  const [inputs, setInputs] = useState(initialInputs);

  const resetPledgeAmounts = () => {
    props.setActiveView("buttonToggle");
    props.setPledgeAmt(null);
    clearOtherAmount();
    //console.log("setting back to empty");
  };

  const otherAmountOnChange = ({ target: { id, value } }) => {
    const newInputs = { ...inputs };
    const inputsCopy = inputs;
    const isValid = inputsCopy.isValid(value);
    //console.log(value, " is valid? ", isValid);
    newInputs.value = value;
    newInputs.error = !isValid;
    newInputs.helperText = inputs.getHelperText(!isValid);
    setInputs(newInputs);

    isValid ? props.setPledgeAmt(value) : props.setPledgeAmt(0);
  };

  const clearOtherAmount = () => {
    setInputs(initialInputs);
    props.setPledgeAmt(null);
  };

  return (
    <div className="row-flexbox-container otherTextFieldContainer">
      <div className="row-flexbox-component otherTextFieldWrapper">
        <TextField
          class="otherTextField"
          id={inputs.id}
          variant="outlined"
          value={inputs.value}
          onChange={otherAmountOnChange}
          error={inputs.error}
          helperText={inputs.helperText}
          size="medium"
          fullWidth={true}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div>$</div>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Cancel
                      style={{ fill: "grey", fontSize: "32px" }}
                      onClick={() => clearOtherAmount()}
                    />
              </InputAdornment>
            ),
            style: ({height: 48}),
          }}
        />
      </div>

      <div className="row-flexbox-component otherButtonWrapper">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={resetPledgeAmounts}
          fullWidth={true}
        >
          Other
        </Button>
      </div>

      <div className="row-flexbox-component addDonationWrapper">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={!Boolean(props.pledgeAmt)}
          onClick={() => props.addCartHandleClick()}
          fullWidth={true}
        >
          + Add Donation
        </Button>
      </div>
    </div>
  );
};

export default OtherAmount;
