import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Views from "./Views";
import { useAuth } from "./../../hooks/UseAuth";
import EventLogic from "./../../logic/EventLogic";
import client from "./../../graphql/client";
import * as QUERIES from "./../../graphql/queries";
import * as PaymentAPI from "./../../utilities/PaymentAPI";
import * as MUTATIONS from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

const CheckinMain = () => {
  const auth = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [useExistingCC, setUseExistingCC] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [checkinPage, setCheckinPage] = useState(1);
  const [valid, setValid] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [header, setHeader] = useState("Let's get you checked in");
  const [hasCurrentCC, setHasCurrentCC] = useState(false);
  const [screenHistory, setScreenHistory] = useState([]);
  const [aliasError, setAliasError] = useState(false);
  const updateAliasCache = (cache, { data }) => {
    // update active alias
    const existingActiveAliasByUser = cache.readQuery({
      query: QUERIES.getActiveAliasByUser,
      variables: { uid: auth.user.username },
    });
    if (existingActiveAliasByUser) {
      var newActiveAliasByUser = JSON.parse(
        JSON.stringify(existingActiveAliasByUser)
      );
      newActiveAliasByUser = data;
      console.log(newActiveAliasByUser);
      cache.writeQuery({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: auth.user.username },
        data: { getActiveAliasByUser: newActiveAliasByUser },
      });
    }
  };
  const [createActivePaymentInformation] = useMutation(
    MUTATIONS.createActivePaymentInformation,
    {
      update: updateAliasCache,
    }
  );

  const getLast3DigitsOfCC = (cc) => {
    const last3 = `${cc.slice(cc.length - 3)}`;
    return last3;
  };

  const getExpirationForDB = (month, year) => {
    if (String(month).length === 2) {
      return String(month) + "20" + String(year);
    } else {
      return "0" + String(month) + "20" + String(year);
    }
  };

  const [currentCC, setCurrentCC] = useState({
    method: "",
    last_3: "",
  });
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [creditCard, setCreditCard] = useState({
    cc: null,
    expMonth: null,
    expYear: null,
    cvv: null,
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    if (!auth.user) return;

    client
      .query({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: auth.user.username },
      })
      .then((data) => {
        const cc = data.data.getActiveAliasByUser;
        if (cc) {
          setCurrentCC({ method: cc.paymentmethod, last_3: cc.last_3 });
          setHasCurrentCC(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth]);

  const saveCreditAlias = () => {
    return axios
      .post(
        PaymentAPI.ALIAS_URL,
        {
          userid: auth.user.username,
          first_name: creditCard.firstName,
          last_name: creditCard.lastName,
          address: address.address1,
          city: address.city,
          state: address.state,
          postal_code: address.zip,
          ccNumber: creditCard.cc,
          ccExpMonth: creditCard.expMonth,
          ccExpYear: creditCard.expYear,
          cvv: creditCard.cvv,
        },
        {
          headers: {
            "x-api-key": PaymentAPI.APIKEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("payment alias result", res);
        if (res.data.result === "Alias Created") {
          setAliasError(false);
          console.log(res.data);
          createActivePaymentInformation({
            variables: {
              input: {
                aliasid: res.data.aliasid,
                userid: res.data.userid,
                first_name: creditCard.firstName,
                last_name: creditCard.lastName,
                address: address.address1,
                city: address.city,
                state: address.state,
                postal_code: address.zip,
                paymentmethod: res.data.paymentmethod,
                last_3: getLast3DigitsOfCC(creditCard.cc),
                expiration: getExpirationForDB(
                  creditCard.expMonth,
                  creditCard.expYear
                ),
              },
            },
          });
        }
      })
      .catch((err) => {
        setAliasError(true);
        console.log(err);
        console.log(PaymentAPI.ALIAS_URL);
      });
  };
  const handleSubmit = async (e) => {
    setScreenHistory((prevState) => [...prevState, checkinPage]);

    if (checkinPage === 1) {
      let nextPage = hasCurrentCC ? 2 : 3;
      setLoading(true);
      auth.updateChatDisplayName(displayName).then(() => {
        setLoading(false);
        setCheckinPage(nextPage);
      });
      return;
    }
    if (checkinPage === 2) {
      setUseExistingCC(true);
      setCheckinPage(5);
      return;
    }
    if (checkinPage === 5) {
      setLoading(true);
      if (!useExistingCC) await saveCreditAlias();
      auth.updateUserEventStatus(EventLogic.currentEvent.id, "checkedin").then(() => {
        setLoading(false);
        history.push("/live");
      });
      return;
    }
    setCheckinPage(checkinPage + 1);
  };

  const handleBackButton = (e) => {
    const tempHistory = screenHistory;
    const prevPage = tempHistory.pop();
    //console.log("prevPage is ", prevPage);
    if (prevPage === 1 || prevPage === 3){
      console.log("prevPage", prevPage);
      setCreditCard((prevState) => ({
        ...prevState,
        cc: null,
        cvv: null,
      }));
      setValid(false);
    }
    setScreenHistory(tempHistory);
    setCheckinPage(prevPage);
  };

  const renderNextButton = () => {
    return valid ? (
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={(e) => handleSubmit(e)}
      >
        {loading ? <CircularProgress /> : "Next"}
      </Button>
    ) : (
      <Button
        disabled
        size="large"
        variant="contained"
        color="primary"
      >
        Next
      </Button>
    );
  };

  const renderBackButton = () => {
    return showBackButton ? (
      <Button
        size="large"
        variant="outlined"
        color="primary"
        onClick={handleBackButton}
      >
        Back
      </Button>
    ) : null;
  };

  const renderCheckin = () => {
    return (
      <Grid direction="row" container className="checkin-grid-container" wrap="nowrap" 
      //style={{ height: "600px" }}
      >
        <Paper className="checkout_wrapper left" variant="outlined">
          <Grid direction="column" container className="checkin_container">
            <Grid item className="checkin-page-header-grid-item">
              <header className="page-header">
                <div className="check-in-header-wrapper">
                  <h2>{header}</h2>
                  <span className="divider"></span>
                </div>
              </header>
            </Grid>
            {checkinPage === 1 && (
              <Views.DisplayName
                setDisplayName={setDisplayName}
                displayName={displayName}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
              />
            )}
            {checkinPage === 2 && (
              <Views.CurrentCC
                creditCard={currentCC}
                setShowBackButton={setShowBackButton}
                gotoCcEdit={() => {
                  setUseExistingCC(false);
                  setCheckinPage(3);
                }}
              />
            )}
            {checkinPage === 3 && (
              <>
                <Views.NewCC
                  setCreditCard={setCreditCard}
                  creditCard={creditCard}
                  setValid={setValid}
                  setShowBackButton={setShowBackButton}
                />
                {aliasError && (
                  <Grid item container className="alias-error-grid-container" alignItems="center" sm={12} justify="center">
                    <Typography
                      style={{ color: "red" }}
                      variant="caption"
                      className="sub-header"
                    >
                      Something went wrong. Please try again later.
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {checkinPage === 4 && (
              <Views.NewCCAddress
                setAddress={setAddress}
                address={address}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
              />
            )}
            {checkinPage === 5 && (
              <Views.CCReview
                displayName={displayName}
                creditCard={useExistingCC ? currentCC : { last_3: creditCard.cc.substr(creditCard.cc.length - 3) }}
                setValid={setValid}
                setShowBackButton={setShowBackButton}
              />
            )}
            <Grid
              container
              direction="row-reverse"
              justify="space-between"
              className="checkin_button_container"
            >
              {renderNextButton()}
              {renderBackButton()}
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className="checkout_wrapper right shaded"
          variant="outlined"
        ></Paper>
      </Grid>
    );
  };

  return renderCheckin();
};

export default CheckinMain;
