import { CometChat } from '@cometchat-pro/chat';

const ChatConstants = {
  userKey: "CHATUSER",
  appId: "2678183dd7c3885",
  region: "US",
  authKey: "7759b383dd64cd90562d20857dbd36c5b8e522fa"
}

export default new class {
  constructor() {
  }

  init = () => {
    var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(ChatConstants.region).build();
    return CometChat.init(ChatConstants.appId, appSetting).then(() => {
      if (CometChat.setSource) {
        CometChat.setSource("ui-kit", "web", "reactjs");
      }
      console.log("Initialization completed successfully");
    },
      error => {
        console.log("Initialization failed with error:", error);
      }
    );
  }

  register = (uid, displayName) => {
    return CometChat.createUser({ uid: uid, name: displayName }, ChatConstants.authKey).then(() => {
      console.log(`Chat user ${displayName} created successfully.`);
    },
      error => {
        console.log("Chat user creation failed with error:", error);
      }
    )
  }

  signIn = async (uid) => {
    try {
      const user = await CometChat.login(uid, ChatConstants.authKey);
      if (user) {
        console.log('CometChatLogic Auth Succeeded');
        return user;
      } else {
        console.log('CometChatLogin Authentication Failed');
        return null;
      }
    }
    catch (err) {
      console.log('CometChatLogin Failed', err);
      throw (err);
    }
  }

  joinGroup = async (groupGuid) => {
    try {
      await CometChat.joinGroup(groupGuid, CometChat.GROUP_TYPE.PUBLIC, "");
      console.log("Joined group " + groupGuid);
    }
    catch (err) {
      if (err.code == "ERR_ALREADY_JOINED") {
        console.log("Already a member of group " + groupGuid);
      }
      else throw err;
    }
  }

  getGroup = async (guid) => {
    return CometChat.getGroup(guid);
  }

  getModerators = async (groupGuid) => {
    try{
      var scopes = ["admin"];
      var groupMemberRequest = new CometChat.GroupMembersRequestBuilder(groupGuid).setLimit(30).setScopes(scopes).build();
      return groupMemberRequest.fetchNext();
    }catch(err){
      console.log(err);
    }
  }
  
  signOut = () => {
    return CometChat.logout();
  }

  getUser = async () => {
    try {
      const user = await CometChat.getLoggedinUser();
      return user;
    }
    catch (err) {
      console.error(err);
      return null;
    }
  }

  updateUser = async (uid, displayName) => {
    return CometChat.updateUser({ uid: uid, name: displayName }, ChatConstants.authKey).then(() => {
      console.log(`Chat user ${displayName} updated successfully.`);
    },
      error => {
        console.log("Chat user update failed with error:", error);
      }
    )
  }
}()