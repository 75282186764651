import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";

const VideoPlayer = ({ src, onEnd = () => {} }) => {
  const player = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const [playerState, setPlayerState] = useState({
    played: 0,
    loaded: 0,
  });
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const playVideo = () => {
      let timer = setTimeout(function () {
        setPlaying(true);
        setMuted(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    };
    if (videoLoaded) playVideo();

    return function cleanup() {
      setPlaying(false);
      setMuted(true);
      setVideoLoaded(false);
      setPlayerState({
        played: 0,
        loaded: 0,
      });
    };
  }, [videoLoaded]);

  return (
    <div
      className={classNames("standard-react-player-container", {
        loading: !videoLoaded,
      })}
    >
      <ReactPlayer
        ref={player}
        url={src}
        className={classNames("standard-react-player", {
          loading: !videoLoaded,
        })}
        playing={playing}
        volume={1}
        playsinline
        //config={reactPlayerConfig} //USED FOR SUBTITLES
        controls={false}
        muted={muted}
        onProgress={setPlayerState}
        onEnded={() => {
          setPlaying(false);
          onEnd();
        }}
        onReady={() => {
          setVideoLoaded(true);
        }}
        onPause={() => {
          setPlaying(false);
        }}
        preload="auto"
        width={"auto"}
        height={"auto"}
      />
    </div>
  );
};

export default VideoPlayer;
