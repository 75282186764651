import * as QUERIES from "../../graphql/queries";
import { useQuery } from "@apollo/client";

export default function FetchInvoiceTotalByUser(userID, compName) {
  const { loading, error, data } = useQuery(QUERIES.getInvoiceTotalByUser, {
    variables: { uid: userID },
  });
  //console.log("called FetchInvoiceTotal with user id ", userID);
  if (error) {
    console.log("GRAPHQL FetchInvoiceTotalByUser Error!", error.message);
    return null;
  }
  if(loading){
    //console.log(compName, ": Loading...in", compName);
  }
  if (data) {
    //console.log(compName, ": data is ", data);
    var total = data.getInvoiceTotalByUser.total;
    //console.log(compName, ": total by user ", userID, " is ",  total);
    if (total === null) {
      return 0;
    }
    return parseFloat(total).toLocaleString('en');
  }
}
