import React, { useEffect, useRef, useState } from 'react';
import canAutoPlay from 'can-autoplay';
import { isMobileSafari, isSafari, isMobile, isAndroid } from 'react-device-detect';
import PlayerDebugInfo from './PlayerDebugInfo';
import Placeholder from './Placeholder';
import PlayerControls from './PlayerControls';
import PlayerSettings from './PlayerSettings';
import UnblockPlayerSoundModal from './UnblockPlayerSoundModal';

const IvsPlayer = ({ streamUrl }) => {
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;

  const [placeHolderStatus, setPlaceHolderStatus] = useState('loading');
  const [latency, setLatency] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isAudioBlocked, setIsAudioBlocked] = useState(false);
  const [openSoundUnblockingModal, setOpenSoundUnblockingModal] = useState(false);

  const player = useRef(null);
  const videoEl = useRef(null);
  const trackEl = useRef(null);
  const playerWrapper = useRef(null);

  useEffect(() => {
    const { BUFFERING, ENDED, IDLE, PLAYING, READY } = IVSPlayer.PlayerState;
    const { ERROR, REBUFFERING } = IVSPlayer.PlayerEventType;

    if (!isPlayerSupported) {
      console.warn('The current browser does not support the Amazon IVS player.');
      return;
    }

    const onStateChange = () => {
      const playerState = player.current.getState();

      console.log(`Player State - ${playerState}`);
      setIsPlaying(playerState === PLAYING);

      if (playerState === PLAYING) {
        setPlaceHolderStatus(null);
        if (trackEl && trackEl.current) {
          trackEl.current.track.mode = 'showing';
        }
      } else if (playerState === ENDED) {
        setPlaceHolderStatus('This live stream has ended');
      }
    };

    const onError = (err) => {
      console.warn('Player Event - ERROR:', err);
      setPlaceHolderStatus('This live stream is currently offline');
    };

    const onRebuffering = () => {
      console.log('Player State - Rebuffering');
      player.current.setRebufferToLive(true);
    };

    const onFullScreenChange = (event) => {
      if (document.fullscreenElement || document.webkitCurrentFullScreenElement) {
        console.log(`setting isFullScreen to true`);
        setIsFullscreen(true);
      } else {
        console.log(`setting isFullScreen to false`);
        setIsFullscreen(false);
      }
    };

    player.current = IVSPlayer.create();
    player.current.attachHTMLVideoElement(videoEl.current);

    player.current.addEventListener(READY, onStateChange);
    player.current.addEventListener(PLAYING, onStateChange);
    player.current.addEventListener(BUFFERING, onStateChange);
    player.current.addEventListener(IDLE, onStateChange);
    player.current.addEventListener(ENDED, onStateChange);

    player.current.addEventListener(ERROR, onError);
    player.current.addEventListener(REBUFFERING, onRebuffering);

    player.current.load(streamUrl);

    // Ask if the browser allows autoplay with sound
    canAutoPlay.video({ muted: false, inline: true, timeout: 1000 }).then(({ result, error }) => {
      if (result) {
        player.current.play();
      } else {
        console.warn(error);
        setIsAudioBlocked(true);
        setOpenSoundUnblockingModal(true);
        //canAutoplayMuted();
      }
    });

    // Ask for autoplay without sound
    const canAutoplayMuted = () => canAutoPlay.video({ muted: true, inline: true, timeout: 1000 }).then(({ result, error }) => {
      if (result) {
        player.current.setMuted(true);
        player.current.play();
      } else {
        // User interaction is required
        setPlaceHolderStatus('Auto-play is blocked');
        console.warn(error);
      }
    });

    player.current.isLiveLowLatency() ? setLatency(2) : setLatency(4);

    const video = playerWrapper.current.getElementsByTagName('video')[0];
    if (isMobileSafari) {
      video.addEventListener('webkitendfullscreen', onFullScreenChange);
    } else if (isSafari) {
      document.addEventListener('webkitfullscreenchange', onFullScreenChange);
    } else {
      document.addEventListener('fullscreenchange', onFullScreenChange);
    }

    return () => {
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(BUFFERING, onStateChange);
      player.current.removeEventListener(IDLE, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);

      player.current.removeEventListener(ERROR, onError);
      player.current.removeEventListener(REBUFFERING, onRebuffering);

      if (isMobileSafari) {
        video.removeEventListener('webkitendfullscreen', onFullScreenChange);
      } else if (isSafari) {
        document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
      } else {
        document.removeEventListener('fullscreenchange', onFullScreenChange);
      }
    };
  }, [IVSPlayer, isPlayerSupported, streamUrl]);

  const buttonSettings = {
    showPlayPause: false,
    showVolume: true,
    showCaption: false,
    showFullScreen: !isMobile || isAndroid,
    showSettings: false
  }

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const toggleDebugInfo = () => {
    setShowDebugInfo(!showDebugInfo);
  };

  const toggleFullscreen = () => {
    const elem = document;
    const videoWrapper = playerWrapper.current;
    const vid = videoEl.current;

    if (isFullscreen) {
      if (elem.exitFullscreen) {
        elem.exitFullscreen();
      } else if (elem.webkitExitFullscreen) { /* Safari */
        elem.webkitExitFullscreen();
      } else if (elem.msExitFullscreen) { /* IE11 */
        elem.msExitFullscreen();
      } else if (vid.webkitExitFullScreen) { /* IOS */
        vid.webkitExitFullScreen();
      }
      setIsFullscreen(false);
    } else {
      if (videoWrapper.requestFullscreen) {
        videoWrapper.requestFullscreen();
      } else if (videoWrapper.webkitRequestFullscreen) { /* Safari */
        videoWrapper.webkitRequestFullscreen();
      } else if (videoWrapper.msRequestFullscreen) { /* IE11 */
        videoWrapper.msRequestFullscreen();
      } else if (vid.webkitEnterFullscreen) { /* IOS */
        vid.webkitEnterFullscreen();
      }
      setIsFullscreen(true);
    }
  };

  if (!isPlayerSupported) {
    return null;
  }

  const handleCloseSoundUnblockingModal = () => {
    player.current.setMuted(false);
    player.current.play();
    setIsAudioBlocked(false);
    setOpenSoundUnblockingModal(false);
  };

  return (
    <div className='stream-wrapper' ref={playerWrapper}>
      <div className='aspect-16x9'>
        {placeHolderStatus && <Placeholder status={placeHolderStatus} />}
        <div className='player'>

          <video ref={videoEl} className='video-el' playsInline preload='metadata' crossOrigin='anonymous'></video>

          <UnblockPlayerSoundModal open={openSoundUnblockingModal} onClose={handleCloseSoundUnblockingModal} />

          <div className='player-ui'>
            {showDebugInfo && <PlayerDebugInfo player={player.current} />}

            {showSettings && (
              <PlayerSettings
                toggleSettings={toggleSettings}
                toggleDebugInfo={toggleDebugInfo}
                showDebugInfo={showDebugInfo}
              />)}

            {player.current && (
              <PlayerControls
                player={player.current}
                showCaptions={'hidden'}
                toggleCaption={null}
                openSettings={toggleSettings}
                isFullscreen={isFullscreen}
                toggleFullscreen={toggleFullscreen}
                startsMuted={isAudioBlocked}
                buttonSettings={buttonSettings}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IvsPlayer;