import React, { useEffect } from "react";
import { Paper, Box, Fab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Close } from "@material-ui/icons";

import {
  EditProfile,
  //YourAddresses,
  MySequins,
  //YourEvents,
  MyCreditCard,
  MyPayments,
} from "./components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="accountMgmtPage">
          <Typography className="accountMgmtPageWrapper">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Account = (props) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const { tab } = props;
    if (tab === "Edit Profile") {
      setValue(0);
    } /*else if (tab === "Your Addresses") {
      setValue(1);
    } */ else if (
      tab === "My Sequins"
    ) {
      setValue(1);
    } /*else if (tab === "My Events") {
      setValue(2);
    } */ else if (
      tab === "My Credit Card"
    ) {
      setValue(2);
    } else if (tab === "My Payments") {
      setValue(3);
    } else setValue(0);
  }, [props]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="account-mgmt-pg">
      <Fab
        className="btn-close"
        onClick={() => props.setOpen(false)}
        color="inherit"
        fontSize="large"
      >
        <Close />
      </Fab>

      <div className="mobile-admin-close-btn">
        <Close 
        style={{ fontSize: 35 }} 
        onClick={() => props.setOpen(false)}/>
      </div>

      <Paper className="accountMgmtWrapper">
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Horizontal tabs"
          className="account-tabs-horizontal"
        >
          <Tab label="Edit Profile" {...a11yProps(0)} />
          <Tab label="My Sequins" {...a11yProps(1)} />
          <Tab label="My Credit Card" {...a11yProps(2)} />
          <Tab label="My Payments" {...a11yProps(3)} />
        </Tabs>

        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          className="account-tabs"
        >
          <Tab label="Edit Profile" {...a11yProps(0)} />
          {/*<Tab label="Your Addresses" {...a11yProps(1)} />*/}
          <Tab label="My Sequins" {...a11yProps(1)} />
          {/*<Tab label="My Events" {...a11yProps(2)} />*/}
          <Tab label="My Credit Card" {...a11yProps(2)} />
          <Tab label="My Payments" {...a11yProps(3)} />
        </Tabs>
        <TabPanel className="tab-panel" value={value} index={0}>
          <EditProfile />
        </TabPanel>
        {/* 
          <TabPanel className="tab-panel" value={value} index={1}>
            <YourAddresses />
          </TabPanel>
          */}
        <TabPanel className="tab-panel" value={value} index={1}>
          <MySequins />
        </TabPanel>
        {/* 
          <TabPanel className="tab-panel" value={value} index={2}>
            <YourEvents />
          </TabPanel>
          */}
        <TabPanel className="tab-panel" value={value} index={2}>
          <MyCreditCard />
        </TabPanel>
        <TabPanel className="tab-panel" value={value} index={3}>
          <MyPayments />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default Account;
