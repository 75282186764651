import React, { useState, useEffect } from "react";
import { Fab } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import classNames from "classnames";
import client from "../graphql/client";
import * as QUERIES from "../graphql/queries";
import Forest from "../components/Forest";
import ForestState, { ForestStates } from "../logic/ForestStateLogic";
import Gateway from "../logic/GatewayLogic";
import SequinInfo from "../components/SequinInfo";
import LoadingScreen from "../components/LoadingScreen";
import { REACT_APP_BASE_URL, SocialShare } from "../components/SocialShare";
import grey from "@material-ui/core/colors/grey";

const PageForest = ({ match }) => {
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [sequinInfo, setSequinInfo] = useState({});
  const [showSequinInfo, setShowSequinInfo] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (match.params.seq) {
      client
        .query({
          query: QUERIES.getSequin,
          variables: { id: parseInt(match.params.seq) },
        })
        .then(({ data, error }) => {
          if (error) {
            console.error(error);
            ForestState.toIntroView();
          }
          if (data) {
            let region = Gateway.getRegion(data.getSequin.region_name);
            ForestState.toSequinLinkView({
              id: data.getSequin.id,
              name: data.getSequin.external_name,
              region,
            });
          }
        });
    } else {
      ForestState.toIntroView();
    }
  }, [match]);

  useEffect(() => {
    const sub = ForestState.stream.subscribe(({ newState }) => {
      if (!newState) return;

      switch (newState.type) {
        case ForestStates.sequin:
          setSequinInfo(newState.data);
          setShowSequinInfo(true);
          setShowBackButton(false);
          break;
        case ForestStates.main:
          setShowBackButton(false);
          break;
        case ForestStates.regionEntered:
          setShowBackButton(true);
          break;
        default:
          break;
      }
    });
    return () => sub.unsubscribe();
  }, []);

  const handleClose = () => {
    setShowSequinInfo(false);
    ForestState.toRegionEntered(sequinInfo.region);
  };

  const handleSceneLoaded = () => {
    setTimeout(() => {
      setSceneLoaded(true);
    }, 500);
  };

  return (
    <>
      <SequinInfo
        sequin={sequinInfo.id}
        visible={showSequinInfo}
        onClose={handleClose}
      />
      <LoadingScreen show={!sceneLoaded} />
      <Forest sceneLoaded={handleSceneLoaded} />

      <SocialShare
        url={REACT_APP_BASE_URL + "/forest"}
        classname="social-share"
        iconStyle={{ color: grey[50] }}
      />

      <div className="ui-controls nav">
        <Fab
          aria-label="back"
          onClick={() => {
            ForestState.toMainView();
          }}
          className={classNames("back-button", { hidden: !showBackButton })}
        >
          <ArrowBackIcon />
        </Fab>
      </div>
    </>
  );
};

export default PageForest;
