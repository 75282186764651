import React, { useState, useEffect, Fragment } from "react";
import { Button, Grid, Paper, CircularProgress, Grow } from "@material-ui/core";
import axios from "axios";
import axiosRetry from "axios-retry";
import * as Views from "./Views";
import SequinForm from "../SequinForm";
import { useAuth } from "./../../hooks/UseAuth";
import EventLogic from "./../../logic/EventLogic";
import client from "./../../graphql/client";
import { useMutation } from "@apollo/client";
import * as QUERIES from "./../../graphql/queries";
import * as MUTATIONS from "./../../graphql/mutations";
import * as PaymentAPI from "./../../utilities/PaymentAPI";
import FetchInvoiceTotalByUser from "../ActiveEventDonation/DonateFetchInvoiceTotalByUser";
import produce from "immer";
import { useQuery } from "@apollo/client";

const CheckoutMain = (props) => {
  const auth = useAuth();
  const total = FetchInvoiceTotalByUser(auth.user.username, "checkoutmain");
  const [totalAmt, setTotalAmt] = useState(null);
  const [checkoutPage, setCheckoutPage] = useState(1);
  const [valid, setValid] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [hasCurrentCC, setHasCurrentCC] = useState(false);
  const [useExistingCC, setUseExistingCC] = useState(false);
  const [loading, setLoading] = useState(false);
  const setDonatedAtCheckOut = props.setDonatedAtCheckOut;
  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);
  const [screenHistory, setScreenHistory] = useState([]);
  const [paymentError, setPaymentError] = useState(false);
  useEffect(() => {
    console.log("paymentError", paymentError);
  }, [paymentError]);
  const [saveCardError, setSaveCardError] = useState(false);
  const {
    //loading: getActiveAliasLoading,
    //error: getActiveAliasError,
    data: activeAliasData,
  } = useQuery(QUERIES.getActiveAliasByUser, {
    variables: { uid: auth.user.username },
  });
  const updateAliasCache = (cache, { data }) => {
    // update active alias
    const existingActiveAliasByUser = cache.readQuery({
      query: QUERIES.getActiveAliasByUser,
      variables: { uid: auth.user.username },
    });
    if (existingActiveAliasByUser) {
      var newActiveAliasByUser = JSON.parse(
        JSON.stringify(existingActiveAliasByUser)
      );
      newActiveAliasByUser = data;
      console.log(newActiveAliasByUser);
      cache.writeQuery({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: auth.user.username },
        data: { getActiveAliasByUser: newActiveAliasByUser },
      });
    }
  };
  const [createActivePaymentInformation] = useMutation(
    MUTATIONS.createActivePaymentInformation,
    {
      update: updateAliasCache,
    }
  );

  const getLast3DigitsOfCC = (cc) => {
    const last3 = `${cc.slice(cc.length - 3)}`;
    return last3;
  };

  const getExpirationForDB = (month, year) => {
    if (String(month).length === 2) {
      return String(month) + "20" + String(year);
    } else {
      return "0" + String(month) + "20" + String(year);
    }
  };

  const [currentCC, setCurrentCC] = useState({
    method: "",
    last_3: "",
  });
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [creditCard, setCreditCard] = useState({
    cc: null,
    expMonth: null,
    expYear: null,
    cvv: null,
    firstName: "",
    lastName: "",
  });

  const [createInvoice, { data: createInvoiceData }] = useMutation(
    MUTATIONS.createInvoice
  );

  const { data: getInvoiceData } = useQuery(QUERIES.getInvoice, {
    skip: !createInvoiceData,
    variables: { id: parseInt(createInvoiceData?.createInvoice.id) },
  });

  const updateMyPaymentsAndTotalCache = (cache, { data }) => {
    // update user total in cache back to 0
    const existingInvoiceTotalByUser = cache.readQuery({
      query: QUERIES.getInvoiceTotalByUser,
      variables: { uid: auth.user.username },
    });
    if (existingInvoiceTotalByUser) {
      cache.writeQuery({
        query: QUERIES.getInvoiceTotalByUser,
        variables: { uid: auth.user.username },
        data: { getInvoiceTotalByUser: 0 },
      });
    }
    // add paid invoice to cache
    try {
      const existingMyPayments = cache.readQuery({
        query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
        variables: { uid: auth.user.username },
      });
      if (existingMyPayments) {
        cache.writeQuery({
          query: QUERIES.getPaidInvoicesByUserOrderedByEvents,
          variables: { uid: auth.user.username },
          data: {
            getPaidInvoicesByUserOrderedByEvents: produce(
              existingMyPayments.getPaidInvoicesByUserOrderedByEvents,
              (eventWithPayments) => {
                for (
                  var index = 0;
                  index <
                  existingMyPayments.getPaidInvoicesByUserOrderedByEvents
                    .length;
                  index++
                ) {
                  console.log(
                    "index",
                    existingMyPayments.getPaidInvoicesByUserOrderedByEvents[
                    index
                    ]
                  );
                  if (
                    existingMyPayments.getPaidInvoicesByUserOrderedByEvents[
                      index
                    ].event.id === EventLogic.currentEvent.id
                  ) {
                    const newPayment = data.updateInvoiceState;
                    eventWithPayments[index].invoices.push(newPayment);
                  }
                }
              }
            ),
          },
        });
      }
    } catch (error) {
      console.log("ERROR querying existing payments in cache: ", error);
    }
  };

  const [updateInvoiceState] = useMutation(MUTATIONS.updateInvoiceState, {
    update: updateMyPaymentsAndTotalCache,
  });

  useEffect(() => {
    if (!createInvoiceData) {
      console.log("creating new invoice");
      createInvoice({
        variables: {
          input: {
            userid: auth.user.username,
            label: "check out invoice",
            eventid: EventLogic.currentEvent.id,
          },
        },
      });
    }

    if (
      createInvoiceData &&
      createInvoiceData.createInvoice.id &&
      getInvoiceData &&
      getInvoiceData.getInvoice.stateid !== 1
    ) {
      console.log(
        "creating invoice since invoice ",
        createInvoiceData.createInvoice.id,
        " is no longer open"
      );
      createInvoice({
        variables: {
          input: {
            userid: auth.user.username,
            label: "check out invoice",
            eventid: EventLogic.currentEvent.id,
          },
        },
      });
    }
  }, [createInvoice, createInvoiceData, getInvoiceData, auth.user.username]);

  useEffect(() => {
    if (total) {
      setTotalAmt(total);
    }
  }, [total, totalAmt]);

  useEffect(() => {
    if (!auth.user) return;

    client
      .query({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: auth.user.username },
      })
      .then((data) => {
        const cc = data.data.getActiveAliasByUser;

        if (cc) {
          setCurrentCC({ method: cc.paymentmethod, last_3: cc.last_3 });
          setHasCurrentCC(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [auth]);

  const saveCreditAlias = async () => {
    return await axios
      .post(
        PaymentAPI.ALIAS_URL,
        {
          userid: auth.user.username,
          first_name: creditCard.firstName,
          last_name: creditCard.lastName,
          address: address.address1,
          city: address.city,
          state: address.state,
          postal_code: address.zip,
          ccNumber: creditCard.cc,
          ccExpMonth: creditCard.expMonth,
          ccExpYear: creditCard.expYear,
          cvv: creditCard.cvv,
        },
        {
          headers: {
            "x-api-key": PaymentAPI.APIKEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("payment alias result", res);
        if (res.data.result === "Alias Created") {
          setSaveCardError(false);
          console.log("SUCCESS", res.data);
          const last3 = getLast3DigitsOfCC(creditCard.cc);
          setCurrentCC(last3);
          console.log("last3 is ", last3);
          createActivePaymentInformation({
            variables: {
              input: {
                aliasid: res.data.aliasid,
                userid: res.data.userid,
                first_name: creditCard.firstName,
                last_name: creditCard.lastName,
                address: address.address1,
                city: address.city,
                state: address.state,
                postal_code: address.zip,
                paymentmethod: res.data.paymentmethod,
                last_3: last3,
                expiration: getExpirationForDB(
                  creditCard.expMonth,
                  creditCard.expYear
                ),
              },
            },
          });

          setLoading(false);
          setCheckoutPage(2);
          setScreenHistory((prevState) => [...prevState, checkoutPage]);

          return res.data.aliasid;
        }
      })
      .catch((err) => {
        setSaveCardError(true);
        setLoading(false);
        setCheckoutPage(4);
        console.log(err);
      });
  };

  const handlePayment = async () => {
    /*
    // used for UI testing
    setLoading(false);
    setCheckoutPage(5);
    setScreenHistory((prevState) => [...prevState, checkoutPage]);
    */
    const client = axios.create();
    axiosRetry(client, {
      retries: 5,
      retryDelay: (retryCount, error) => {
        console.log("retry attempt: ", retryCount);
        console.log("error", error);
        return retryCount * 500;
      },
      retryCondition: axiosRetry.isRetryableError,
    });

    const options = {
      url: PaymentAPI.CHARGE_URL,
      method: "POST",
      headers: {
        "x-api-key": PaymentAPI.APIKEY,
        "Content-Type": "application/json",
      },
      data: {
        aliasid: activeAliasData?.getActiveAliasByUser?.aliasid,
        transactionAmount: totalAmt,
      },
    };

    return await client(options)
      .then((response) => {
        console.log(response);
        if (response.data) {
          setLoading(false);
          setCheckoutPage(5);
          setScreenHistory((prevState) => [...prevState, checkoutPage]);
          // after successful payment, update invoice state
          updateInvoiceState({
            variables: {
              input: {
                id: createInvoiceData.createInvoice.id,
                stateid: 2,
              },
            },
          });
        }
      })
      .catch((err) => {
        setPaymentError(true);
        setCheckoutPage(2);
        console.log("CAUGHT payment error", err);
        if (err.response) {
          console.log("5xx or 4xx");
          console.log(err.response);
        } else if (err.request) {
          console.log("err.request", err.request);
        }
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    //setCheckoutPage(checkoutPage);
    if (checkoutPage !== 2 && checkoutPage !== 4) {
      setScreenHistory((prevState) => [...prevState, checkoutPage]);
    }
    console.log("handleSubmit: ", checkoutPage);
    if (checkoutPage === 1) {
      let nextPage = total === 0 || total === null ? 6 : 2;
      setCheckoutPage(nextPage);
      return;
    }
    if (checkoutPage === 2) {
      setUseExistingCC(true);
      setLoading(true);
      handlePayment();
      //console.log("paymentError is now", paymentError);
      //setCheckoutPage(paymentError ? 2 : 5);
      return;
    }
    if (checkoutPage === 4) {
      setLoading(true);
      saveCreditAlias();
      return;
    }
    setCheckoutPage(checkoutPage + 1);
  };

  const handleBackButton = (e) => {
    //console.log("screenHistory", screenHistory);
    const tempHistory = screenHistory;
    const prevPage = tempHistory.pop();
    setScreenHistory(tempHistory);
    setCheckoutPage(prevPage);
    //console.log("back button", tempHistory, prevPage);

    if (prevPage >= 2) {
      setCreditCard((prevState) => ({
        ...prevState,
        cvv: null,
        cc: null,
      }));
      setValid(true);
    }

    setSaveCardError(false);
  };

  const renderHeader = () => {
    let header = "";

    switch (checkoutPage) {
      case 1:
        header = "Let's get you checked out";
        break;
      case 2:
        header = "Finalize your Donation";
        break;
      case 3:
        header = "Enter New Credit Card";

        break;
      case 4:
        header = "Billing Address";

        break;
      case 5:
        header = "";
        break;
      case 6:
        header = "Leave Your Mark";
        break;
      default:
        break;
    }
    return header;
  };

  const renderButtonLabel = () => {
    let label = "";

    switch (checkoutPage) {
      case 1:
        label = "Next";
        break;
      case 2:
        label = "Submit";
        break;
      case 3:
        label = "Next";

        break;
      case 4:
        label = "Add Card";

        break;
      case 5:
        label = "Next";
        break;
      case 6:
        label = "Review";
        break;
      default:
        break;
    }
    return label;
  };

  const renderNextButton = () => {
    return valid ? (
      <Button
        size="large"
        variant="contained"
        color="primary"
        fullWidth
        onClick={(e) => {
          handleSubmit(e);
        }}
        disabled={!createInvoiceData}
      >
        {loading ? (
          <CircularProgress size={20} style={{ color: "white" }} />
        ) : (
          renderButtonLabel()
        )}
      </Button>
    ) : (
      <Button
        disabled
        size="large"
        fullWidth
        variant="contained"
        color="primary"
      >
        {renderButtonLabel()}
      </Button>
    );
  };

  const renderBackButton = () => {
    return showBackButton ? (
      <Button
        size="large"
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleBackButton}
      >
        Back
      </Button>
    ) : null;
  };

  useEffect(() => {
    if (checkoutPage === 5) {
      setDonatedAtCheckOut(true);
    } else {
      setDonatedAtCheckOut(false);
    }
  }, [checkoutPage]);

  const renderCheckout = () => {
    return (
      <Fragment>
        <Grid
          direction="row"
          container
          wrap="nowrap"
          className="checkout_grid"
        //style={{ height: "500px" }}
        >
          <Paper className="checkout_wrapper left" variant="outlined">
            <Grid
              container
              direction="column"
              wrap="nowrap"
              className="checkout-grid-wrap"
            >
              <Grid item className="checkout-page-header-grid-item">
                <header className="page-header checkout_header">
                  <div>
                    <h2>{renderHeader()}</h2>
                    {checkoutPage !== 5 && <span className="divider"></span>}
                  </div>
                </header>
              </Grid>

              {checkoutPage === 1 && (
                <Grow in={true}>
                  <Views.CheckoutCart
                    userID={auth.user.username}
                    setValid={setValid}
                    setShowBackButton={setShowBackButton}
                    invoiceID={createInvoiceData?.createInvoice?.id}
                    createInvoiceData={createInvoiceData}
                  />
                </Grow>
              )}
              {checkoutPage === 2 && (
                <Grow in={true}>
                  <Views.CheckoutConfirmation
                    paymentError={paymentError}
                    totalAmt={totalAmt}
                    creditCard={currentCC}
                    setShowBackButton={setShowBackButton}
                    gotoCcEdit={() => {
                      setUseExistingCC(false);
                      setCheckoutPage(3);
                      setScreenHistory((prevState) => [
                        ...prevState,
                        checkoutPage,
                      ]);
                    }}
                  />
                </Grow>
              )}
              {checkoutPage === 3 && (
                <Grow in={true}>
                  <Views.CheckoutNewCC
                    setCreditCard={setCreditCard}
                    creditCard={creditCard}
                    setValid={setValid}
                    setShowBackButton={setShowBackButton}
                  />
                </Grow>
              )}
              {checkoutPage === 4 && (
                <Grow in={true}>
                  <Views.CheckoutNewCCAddress
                    saveCardError={saveCardError}
                    setAddress={setAddress}
                    address={address}
                    setValid={setValid}
                    setShowBackButton={setShowBackButton}
                  />
                </Grow>
              )}
              {checkoutPage === 5 && (
                <Grow in={true}>
                  <Views.CheckoutCartThanks
                    userID={auth.user.username}
                    setValid={setValid}
                    paymentError={paymentError}
                    setShowBackButton={setShowBackButton}
                  />
                </Grow>
              )}
              {checkoutPage === 6 && (
                <Grow in={true}>
                  <SequinForm setShowBackButton={setShowBackButton} />
                </Grow>
              )}
              {/* <Views.CheckoutCartThanks /> */}

              <Grid
                container
                justify="space-between"
                className="checkout_button_container"
              >
                <Grid item className="back-button-grid-item" xs={12} sm={3}>
                  {renderBackButton()}
                </Grid>
                <Grid item className="next-button-grid-item" xs={12} sm={3}>
                  {checkoutPage !== 6 && renderNextButton()}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            className="checkout_wrapper right shaded"
            variant="outlined"
          ></Paper>
        </Grid>
      </Fragment>
    );
  };

  return renderCheckout();
};

export default CheckoutMain;
