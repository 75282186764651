import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { useAuth } from "../../../hooks/UseAuth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Auth } from "aws-amplify";

const Confirm = ({
  setVerified,
  userInfo: { userName, fullName, phone, password },
  setValid,
  setShowBackButton,
  setHeader,
  setConfirmationCode,
  confirmationCode,
  userExistsNotConfirmed,
  confirmationCodeError,
}) => {
  setValid(confirmationCode !== null ? true : false);
  console.log("user exists", userExistsNotConfirmed);
  const firstName = userExistsNotConfirmed
    ? "Your Account is not Confirmed"
    : fullName.substr(0, fullName.indexOf(" "));
  setShowBackButton(userExistsNotConfirmed ? false : true);
  setHeader(`Welcome, ${firstName}`);
  const auth = useAuth();
  //const [open, setOpen] = useState(false);
  const [resendMessage, setResendMessage] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmationSent, setConfirmationSent] = useState(false);
  //const [severity, setSeverity] = useState("");

  const [loading, setLoading] = useState(false);

  const handleConfirm = (e) => {
    if (e.target.id === "confirmationCode") {
      setConfirmationCode(e.target.value);
    }
    //console.log("confirmation", confirmationCode);
  };

  /*
  useEffect(() => {
    console.log("userExistsNotConfirmed? ", userExistsNotConfirmed);
    console.log("confirmationSent? ", confirmationSent);
  }, [userExistsNotConfirmed, confirmationSent]);
  */
  const sendConfirmation = async () => {
    setLoading(true);

    if (userExistsNotConfirmed && auth.user !== null) {
      await auth.getUpdatedUser;
      console.log("sending change email verification");
      console.log(auth.user);
      try {
        await auth.resendEmailVerification();
        setLoading(false);
        setConfirmMessage(
          "Code is on its way! Please wait 1-3 minutes to appear in inbox"
        );
        setConfirmationSent(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      try {
        await auth.signUp({
          username: userName,
          password: password,
          attributes: {
            name: fullName,
            email: userName,
            phone_number: phone,
            address: "",
          },
        });
        setLoading(false);
        setConfirmMessage(
          "Code is on its way! Please wait 1-3 minutes to appear in inbox"
        );
        setConfirmationSent(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  /*const handleSubmit = () => {
    auth.confirmSignup(userName, confirmationCode);
  };*/
  const resendConfirmationCode = async () => {
    setLoading(true);
    setConfirmMessage(null);
    if (auth.user !== null) {
      try {
        await auth.resendEmailVerification();
        setResendMessage("Verification code resent successfully!");
        //setSeverity("success");
        //setOpen(true);
        setLoading(false);
      } catch (err) {
        console.log("error resending code: ", err);
        setResendMessage(err.message);
        //setSeverity("error");
        //setOpen(true);
        setLoading(false);
      }
    } else {
      try {
        await Auth.resendSignUp(userName);
        console.log("code resent successfully");
        setResendMessage("Verification code resent successfully!");
        //setSeverity("success");
        //setOpen(true);
        setLoading(false);
      } catch (err) {
        console.log("error resending code: ", err);
        setResendMessage(err.message);
        //setSeverity("error");
        //setOpen(true);
        setLoading(false);
      }
    }
  };

  /*const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };*/

  return (
    <>
      <Grid direction="column" container wrap="nowrap" spacing={2}>
        <Grid item className="register_title">
          Confirm Your Account
        </Grid>

        {/* <Grid item xs={12}>
          <FormGroup column>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkedPhone}
                  onChange={handleCheck}
                  name="checkedPhone"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className="sub-header">
                  Recieve confirmation by text
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkedEmail}
                  onChange={handleCheck}
                  name="checkedEmail"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className="sub-header">
                  Receive confirmation by email
                </Typography>
              }
            />
          </FormGroup>
        </Grid> */}
        <Grid
          container
          direction="row"
          wrap="nowrap"
          style={{ margin: ".5rem" }}
        >
          <Grid item xs={7}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={sendConfirmation}
              //onClick={getUserInfo}
              disabled={confirmationSent ? true : false}
            >
              Send Confirmation Code
            </Button>
          </Grid>
          {resendMessage !== null && !loading && (
            <Grid item xs={5}>
              <Typography variant="caption" className="sub-header">
                {resendMessage}
              </Typography>
            </Grid>
          )}
          {confirmMessage !== null && (
            <Grid item xs={5}>
              <Typography variant="caption" className="sub-header">
                {confirmMessage}
              </Typography>
            </Grid>
          )}
          {loading && (
            <Grid item xs={5}>
              <CircularProgress color="white" />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="confirmationCode"
            id="confirmationCode"
            label="Confirmation Code"
            variant="outlined"
            onChange={handleConfirm}
            inputProps={{ "aria-label": "description" }}
          />
          <p>
            Didn't receive the email?{" "}
            <span
              onClick={resendConfirmationCode}
              style={{
                textDecoration: "underline",
                color: "primary",
                cursor: "pointer",
              }}
            >
              Resend Confirmation Code
            </span>
          </p>
        </Grid>
        <Grid item xs={12} className="login_error">
          <Typography color="error" variant="caption" display="block">
            {confirmationCodeError}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Confirm;
