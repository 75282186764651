import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import { Facebook, Twitter, LinkedIn, Email, Share } from "@material-ui/icons";

import MetaData from "./MetaData";
import CopyLink from "./CopyLink";
import classNames from "classnames";

const REACT_APP_BASE_URL = "https://experience.501ug.com";

const SocialShare = ({
  url = "",
  postBody = "",
  classname = "",
  buttonClasses = [],
  separator = "\n\n",
  iconStyle = {},
  hashtags = ["StJude", "StJudeExperience"],
  title = "St. Jude Virtual Sequin Forest",
  description = "Discover St. Jude Children’s Research Hospital lifesaving work by exploring the St. Jude Virtual Sequin Forest.",
  open = false,
  displayMode = "menu",
  onClick = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const ShareClasses = classNames("social-share-button", buttonClasses);
  const socialMenuOpen = Boolean(anchorEl);

  const handleSocialButtonClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSocialMenuClose = () => {
    setAnchorEl(null);
  };

  const ItemContainer = (props) => {
    return (
      <MenuItem key={props.id} id={props.id} onClick={onClick}>
        {props.children}
      </MenuItem>
    );
  };

  return displayMode === "menu" ? (
    <div className={classname}>
      <MetaData title={title} description={description} />
      <IconButton
        aria-label="social media sharing"
        onClick={handleSocialButtonClick}
      >
        <Share />
      </IconButton>
      <Menu
        className="share-menu"
        open={socialMenuOpen}
        anchorEl={anchorEl}
        onClose={handleSocialMenuClose}
      >
        <ItemContainer id="fb_share">
          <FacebookShareButton
            url={url}
            quote={postBody}
            hashtag={"#" + hashtags[0]}
            className={ShareClasses}
          >
            <Facebook style={iconStyle} />
          </FacebookShareButton>
        </ItemContainer>

        <ItemContainer id="twitter_share">
          <TwitterShareButton
            url={url}
            title={postBody}
            hashtags={hashtags}
            className={ShareClasses}
          >
            <Twitter style={iconStyle} />
          </TwitterShareButton>
        </ItemContainer>

        <ItemContainer id="li_share">
          <LinkedinShareButton url={url} title={"title"}>
            <LinkedIn style={iconStyle} />
          </LinkedinShareButton>
        </ItemContainer>

        <ItemContainer id="email_share">
          <EmailShareButton
            url={url}
            body={description}
            subject={title}
            separator={separator}
          >
            <Email style={iconStyle} />
          </EmailShareButton>
        </ItemContainer>

        <ItemContainer id="cp_share">
          <CopyLink url={url} iconStyle={iconStyle} />
        </ItemContainer>
      </Menu>
    </div>
  ) : (
    <div className={classname}>
      <MetaData title={title} description={description} />
      <FacebookShareButton
        url={url}
        quote={postBody}
        hashtag={"#" + hashtags[0]}
        className={ShareClasses}
      >
        <Facebook style={iconStyle} />
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={postBody}
        hashtags={hashtags}
        className={ShareClasses}
      >
        <Twitter style={iconStyle} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={"title"}>
        <LinkedIn style={iconStyle} />
      </LinkedinShareButton>

      <EmailShareButton
        url={url}
        body={description}
        subject={title}
        separator={separator}
      >
        <Email style={iconStyle} />
      </EmailShareButton>

      <CopyLink url={url} iconStyle={iconStyle} />
    </div>
  );
};

export { SocialShare, REACT_APP_BASE_URL };
