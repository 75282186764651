import React, { useState, useEffect } from "react";
import {
  Button,
  Link,
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Redirect } from "react-router";
import { useAuth } from "../hooks/UseAuth";
import AuthLogic, { AuthStatus } from "../logic/AuthLogic";
import { withApollo } from "@apollo/client/react/hoc";

const Login = ({ client }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [authState, setAuthState] = useState({});
  const [authData, setAuthData] = useState({});

  const auth = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/forest" } };

  useEffect(() => {
    const sub = AuthLogic.authStatus.subscribe((state) => {
      setAuthState(state.state);
      setAuthData(state.data);
    });
    return () => sub.unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(username, password);
    await client.resetStore();
    await auth.signIn(username.toLowerCase(), password);
  };

  const handleChange = (e) => {
    if (e.target.id === "username") {
      setUsername(e.target.value);
    }

    if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  let photoStyle = {
    background: "black",
    backgroundImage: "../assets/fonts/sjs-regular-msdf/sjs-regular.png",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const renderLogin = () => {
    switch (authState) {
      case AuthStatus.authSuccess:
        return <Redirect to={from.pathname} />;
      case AuthStatus.authNotConfirmed:
        return (
          <Redirect
            to={{
              pathname: "/signup",
              state: { userConfirmed: false, username, password },
            }}
          />
        );
      default:
        return (
          <div className="login_wrapper">
            <Paper className="login_header">
              <Grid item xs={12}>
                <header className="login_title">
                  <div>
                    <h2>Login</h2>
                  </div>
                </header>
              </Grid>
            </Paper>
            <Paper className="login_form" variant="outlined">
              <Grid container spacing={2} className="login_form_container">
                <Grid container className="login_fields">
                  <Grid item xs={12} className="login_field">
                    <TextField
                      id="username"
                      label="Email Address"
                      required
                      variant="outlined"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "description" }}
                      fullWidth
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} className="login_field">
                    <TextField
                      required
                      id="password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "description" }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Link
                      component={RouterLink}
                      className="forgot-password-link-button"
                      to={`/forgotPassword`}
                    >
                      Forgot Password
                    </Link>
                  </Grid>

                  <Grid item xs={12} className="login_error">
                    <Typography
                      color="error"
                      variant="caption"
                      display="block"
                    >
                      {authState === AuthStatus.authFail && authData}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="space-between"
                  className="login_form_bottom"
                >
                  <Link
                    className="link-button"
                    component={RouterLink}
                    to={`/signup`}
                  >
                    Create Account
                  </Link>

                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {authState === AuthStatus.authStart ? (
                      <CircularProgress
                        size={24}
                        style={{ color: "white" }}
                      />
                    ) : (
                      "Next"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
    }
  };

  return renderLogin();
};

export default withApollo(Login);
