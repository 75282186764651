import React from "react";
import { Container } from "@material-ui/core";

import CheckinMain from "./../components/Checkin/CheckinMain";

const PageSignUp = (props) => {
  return (
    <>
      <Container
        className="checkin_outer_container"
      >
        <CheckinMain />
      </Container>
    </>
  );
};

export default PageSignUp;
