import React, { useEffect } from "react";
import CreditCardForm from "./../../CreditCardForm";

const NewCC = ({ setCreditCard, creditCard, setValid, setShowBackButton }) => {
  useEffect(() => {
    setShowBackButton(true);
  }, [])

  const explanation = "During this event you will have the opportunity to support the kids of St. Jude. The card selected below will be used for any donations you choose to make during the event.  You will have the opportunity to review and approve all charges on a check out screen prior to them being finalized.";

  const renderView = () => {
    return (
      <CreditCardForm
        setCreditCard={setCreditCard}
        creditCard={creditCard}
        setValid={setValid}
        description={explanation}
      >
      </CreditCardForm>
    );
  };

  return renderView();
};

export default NewCC;
