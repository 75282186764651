import React from "react";
import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
import * as QUERIES from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../hooks/UseAuth";
import SequinCarousel from "./SequinCarousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    textAlign: "center",
  },
}));

const MySequins = () => {
  const classes = useStyles();
  const colors = ["#0083ef", "#ff58de", "#00f87d", "#ffe084", "#d9d9d9"];
  const colorsSize = colors.length;
  const userID = useAuth().user.username;
  const { loading, error, data } = useQuery(QUERIES.getSequinsByUser, {
    variables: { uid: userID },
  });
  //console.log("called getSequinsByUser with user ID ", userID);
  if (error) console.log("GraphQL Error! getSequinsByUser", error.message);
  //if (data) console.log(data);
  //const numSequins = data?.listSequinsByUser?.length;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      //items: numSequins >= 3 ? 3 : 1,
      //slidesToSlide: numSequins >= 3 ? 3 : 1,
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Grid className="sequinPage">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <header className="page-header">
            <div>
              <h2>My Sequins</h2>
              <span className="divider"></span>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid container className="sequinsWrapper">
        <Grid item xs={12} className="sequinGridItem">
          {loading && (
            <div className={classes.circularProgress}>
              <CircularProgress style={{ color: "gray" }} />
            </div>
          )}

          {!loading && data.listSequinsByUser && (
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={false}
              infinite={true}
              //autoPlay={this.props.deviceType !== "mobile" ? true : false}
              //autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="sequin-carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              //deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="sequin-carousel-item"
            //centerMode={true}
            >
              {data.listSequinsByUser.map((sequin, index) => (
                <SequinCarousel
                  content={{
                    backgroundColor: colors[index % colorsSize],
                    itemid: sequin.id,
                    date_and_time: sequin.event.date_and_time,
                  }}
                />
              ))}
            </Carousel>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { MySequins };
