import React, { useState, useEffect } from "react";
import { Check, InsertLink } from "@material-ui/icons";
import { Link } from "@material-ui/core";
import copy from "copy-to-clipboard";

const CopyLink = ({ url, resetTimeout = 4000, iconStyle = {} }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout;
    if (isCopied) {
      timeout = setTimeout(() => setIsCopied(false), resetTimeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetTimeout]);

  const handleCopy = () => {
    console.log("JOOPY", url);
    if (typeof url === "string" || typeof url == "number") {
      copy(url.toString());
      setIsCopied(true);
    }
  };

  return isCopied ? (
    <Check style={iconStyle} />
  ) : (
    <Link
      color="inherit"
      onClick={() => {
        handleCopy();
      }}
    >
      <InsertLink style={iconStyle} />
    </Link>
  );
};

export default CopyLink;
