import React from "react";
import { Container } from "@material-ui/core";

import Rsvp from "./../components/RSVP/Rsvp";

const PageRsvp = (props) => {
  console.log("props", props);
  return (
    <>
      <Container
        maxWidth="sm"
        className="page-wrapper rsvp-wrapper"
      >
        <Rsvp />
      </Container>
    </>
  );
};

export default PageRsvp;
