import React, { useEffect } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { useAuth } from "../../../hooks/UseAuth";
import { useQuery } from "@apollo/client";
import * as QUERIES from "../../../graphql/queries";

const CheckoutConfirmation = ({
  totalAmt,
  creditCard,
  gotoCcEdit,
  setShowBackButton,
  paymentError,
}) => {
  useEffect(() => {
    setShowBackButton(true);
  }, []);
  const userID = useAuth().user.username;
  const { loading, error, data } = useQuery(QUERIES.getActiveAliasByUser, {
    variables: { uid: userID },
  });
  return (
    <Grid direction="column" container className="checkout-confirmation-container" wrap="nowrap" spacing={2}>
      <Grid item>
        <h3 className="formgroup-header">You will now be billed ${totalAmt}</h3>
        <h3 className="formgroup-header" style={{ margin: "2rem 0 2rem 0" }}>
          Thank you so much for your gift to the kids of St. Jude.
        </h3>
      </Grid>

      <Grid item>
        <Grid container className="icon-group" wrap="nowrap">
          <Grid item>
            <CreditCardOutlinedIcon className="icon-group-icon" />
          </Grid>

          <Grid item container direction="column" className="cc_display">
            <Grid item>
              <h3>Credit Card</h3>
            </Grid>
            <Grid item className="confirmation-cc-last3-grid-item">
              XXXX-XXXX-XXXX-*{data?.getActiveAliasByUser?.last_3}
            </Grid>
          </Grid>
          <Grid item className="cc_edit">
            <Button
              size="small"
              variant="outlined"
              //size="large"
              color="primary"
              onClick={gotoCcEdit}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {paymentError && (
        <Grid
          item
          container
          alignItems="center"
          xs={12}
        >
          <Typography
            style={{ color: "red", paddingBottom: "1rem" }}
            variant="caption"
            className="sub-header"
          >
            Something went wrong. Please retry.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CheckoutConfirmation;
