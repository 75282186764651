import { Grid, Paper, Container } from "@material-ui/core";

const EventNotStarted = () => {
  return (
    <Container className="checkin_outer_container">
      <Paper variant="outlined" className="event-not-started-paper">
        <Grid
          container
          direction="row"
          className="event-not-started-grid-container"
        >
          <Grid item className="event-not-started-text-grid-item">
            Event has not begun yet. Check back later.
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default EventNotStarted;
