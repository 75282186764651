import React from "react";
//import { useState, useEffect } from "react";
import classNames from "classnames";
//import FetchInvoiceTotalByUser from "./DonateFetchInvoiceTotalByUser";
//import NavBarButton from "../NavBarButton";
import Button from "@material-ui/core/Button";
import { useAuth } from "../../hooks/UseAuth";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import * as QUERIES from "../../graphql/queries";
import { useQuery } from "@apollo/client";

const DonateAmountWidget = (props) => {
  const userID = useAuth().user.username;
  //const total = FetchInvoiceTotalByUser(userID);

  const { loading, error, data } = useQuery(QUERIES.getInvoiceTotalByUser, {
    variables: { uid: userID },
  });

  //console.log("called FetchInvoiceTotal with user id ", userID);
  if (error) {
    console.log("Widget: GRAPHQL FetchInvoiceTotalByUser Error!", error.message);
    return <div></div>;
  }
  if (loading) {
    //console.log("Widget: Loading...");
  }

  if (data) {
    //console.log("Widget data:", data);
    return data.getInvoiceTotalByUser.total ? (
      <Button
        className={classNames(props.totalWidgetClassName, {
          "live-btn": props,
        })}
      >
        <Badge color="secondary" badgeContent={`$${data.getInvoiceTotalByUser.total}`}>
          <ShoppingCartIcon fontSize="large" />
        </Badge>
      </Button>
    ) : (
      <div></div>
    );
  } else {
    return <div></div>;
  }
};

export default DonateAmountWidget;
