import React, { useState, useEffect } from "react";
import { TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, Grid, Container, Paper, Checkbox } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import * as QUERIES from "../graphql/queries";
import * as MUTATIONS from "../graphql/mutations";
import { RunOfShowStates } from "../logic/RunOfShowLogic";

const PollControl = () => {
  const { data: eventsData } = useQuery(QUERIES.getEvents);
  const [getPolls, { data: pollsData, refetch: refetchPolls }] = useLazyQuery(QUERIES.getPolls, { fetchPolicy: "network-only" });
  const [getPollChoices, { data: pollChoicesData, refetch: refetchPollChoices }] = useLazyQuery(QUERIES.getExperiences, { fetchPolicy: "network-only" });
  const [getCheckout, { data: checkoutData }] = useLazyQuery(QUERIES.getExperiences, { fetchPolicy: "network-only" });
  const [getBlueLight, { data: blueLightData }] = useLazyQuery(QUERIES.getExperiences, { fetchPolicy: "network-only" });
  const [getCurrentExperiences, { data: currentExpData }] = useLazyQuery(QUERIES.getCurrentExperiences, { fetchPolicy: "network-only" });
  const [createPoll] = useMutation(MUTATIONS.createPoll);
  const [updatePollState] = useMutation(MUTATIONS.updatePollState);
  const [updatePollTime] = useMutation(MUTATIONS.updatePollTime);
  const [completePoll] = useMutation(MUTATIONS.completePoll);
  const [startExperience] = useMutation(MUTATIONS.startExperience)
  const [stopExperience] = useMutation(MUTATIONS.stopExperience)
  const [choices, setChoices] = useState({});
  const [newPoll, setNewPoll] = useState({ pollName: "Poll 98", pollTitle: "Choose the next experience", pollTime: 30, eventId: 0 });
  const [newPollOpen, setNewPollOpen] = useState(false);
  const [checkoutQueueId, setCheckoutQueueId] = useState(null);
  const [blueLightQueueId, setBlueLightQueueId] = useState(null);
  const [checkoutExpId, setCheckoutExpId] = useState(0);
  const [blueLightExpId, setBlueLightExpId] = useState(0);
  const [selectedEventId, setSelectedEventId] = useState(0);

  useEffect(() => {
    if (!pollChoicesData?.getExperiences) return;

    const newChoices = pollChoicesData.getExperiences.reduce((agg, curr) => {
      return {
        ...agg, [curr.id]: !curr.watched
      }
    }, {});

    setChoices(newChoices);

  }, [pollChoicesData])

  useEffect(() => {
    const checkOutExp = currentExpData?.getCurrentExperiences.find(x => x.type === RunOfShowStates.checkOut);
    const blueLightExp = currentExpData?.getCurrentExperiences.find(x => x.type === RunOfShowStates.blueLightSpecial);

    setCheckoutQueueId(checkOutExp?.id);
    setBlueLightQueueId(blueLightExp?.id);

  }, [currentExpData])

  useEffect(() => {
    const id = checkoutData?.getExperiences.length ? checkoutData.getExperiences[0].id : 0;
    setCheckoutExpId(id);

  }, [checkoutData])

  useEffect(() => {
    const id = blueLightData?.getExperiences.length ? blueLightData.getExperiences[0].id : 0;
    setBlueLightExpId(id);

  }, [blueLightData])

  const handleToggleCheckout = (event) => {
    event.preventDefault();

    if (checkoutQueueId) {
      stopExperience({ variables: { input: { id: checkoutQueueId } } });
      setCheckoutQueueId(null);
    } else {
      startExperience({ variables: { input: { id: checkoutExpId } } }).then((resp) => {
        setCheckoutQueueId(resp.data.startExperience.id);
      })
    }
  }

  const handleToggleBlueLight = (event) => {
    event.preventDefault();

    if (blueLightQueueId) {
      stopExperience({ variables: { input: { id: blueLightQueueId } } });
      setBlueLightQueueId(null);
    } else {
      startExperience({ variables: { input: { id: blueLightExpId } } }).then((resp) => {
        const queueId = resp.data.startExperience.id;
        setBlueLightQueueId(queueId);
        setTimeout(() => {
          stopExperience({ variables: { input: { id: queueId } } });
          setBlueLightQueueId(null);
        }, 15000)
      })
    }
  }

  useEffect(() => {
    if (!selectedEventId) return;

    const id = parseInt(selectedEventId);
    getCheckout({ variables: { eventId: id, expTypes: [RunOfShowStates.checkOut] } });
    getBlueLight({ variables: { eventId: id, expTypes: [RunOfShowStates.blueLightSpecial] } });
    getPolls({ variables: { eventId: id } });
    getPollChoices({ variables: { eventId: id } });
    getCurrentExperiences({ variables: { eventId: id } });
  }, [selectedEventId])

  const handleEventChange = (event) => {
    const { value } = event.target;
    setSelectedEventId(value);
  }

  const handlePollFieldChange = (event) => {
    const { id, value } = event.target;
    let val = id == "pollTime" ? parseInt(value) : value;

    setNewPoll(currentPoll => ({
      ...currentPoll, [id]: val
    }))
  }

  const handleCheckboxChange = (event) => {
    const { name } = event.target;

    setChoices(currentChoices => ({
      ...currentChoices, [name]: !currentChoices[name]
    }))
  }

  const handleStartClick = async (event, pollId, seconds) => {
    event.preventDefault();
    await updatePollState({
      variables:
      {
        input: {
          id: pollId,
          state: "Running"
        }
      }
    });
    await refetchPolls();
    countDown(pollId, seconds);
  }

  const handleExitClick = async (event, pollId) => {
    event.preventDefault();
    await updatePollState({
      variables:
      {
        input: {
          id: pollId,
          state: "Announced"
        }
      }
    });
    await refetchPolls();
  }

  const countDown = (pollId, seconds) => { // this should REALLY be moved to a service running in the cloud

    let interval = setInterval(() => {
      if (--seconds < 0) seconds = 0;
      updatePollTime({
        variables:
        {
          input: {
            id: pollId,
            time_in_seconds: seconds
          }
        }
      }).then(() => {
        refetchPolls().then(() => {
          if (seconds < 1) {
            console.log("seconds: " + seconds);
            clearInterval(interval);
            completePoll({
              variables:
              {
                input: {
                  id: pollId
                }
              }
            }).then(() => refetchPolls().then(() => refetchPollChoices()))
          }
        })
      })
    }, 1000);
  }

  const handleNewPollClick = () => {
    setNewPollOpen(true);
  }

  const handleNewPollClose = () => {
    setNewPollOpen(false);
  }

  const handleNewPollSave = () => {
    setNewPollOpen(false);
    const selectedChoices = Object.keys(choices).reduce((agg, curr) => {
      if (choices[curr]) agg.push(parseInt(curr));
      return agg;
    }, []);

    const escapeQuotes = (value) => value.replace("'", "''").replace(/"/g, '\\\"');

    createPoll({
      variables:
      {
        input: {
          eventId: selectedEventId,
          timeInSeconds: newPoll.pollTime,
          title: escapeQuotes(newPoll.pollTitle),
          name: escapeQuotes(newPoll.pollName),
          choiceIds: selectedChoices
        }
      }
    }).then(() => refetchPolls())
  }

  const listPolls = () => {
    if (!pollsData?.getPolls) return null;

    const pollRunning = pollsData.getPolls.some(p => p.state == "Running");
    const sorted = [...pollsData.getPolls].sort((a, b) => a.pollid - b.pollid);
    return (
      <TableBody>
        {sorted.map((p) => {
          const action = p.state == "Ready" ? (<Button variant="outlined" disabled={pollRunning} size="small" onClick={(event) => handleStartClick(event, p.pollid, p.time_in_seconds)}>Start</Button>)
            : p.state == "Complete" ? (<Button variant="outlined" size="small" onClick={(event) => handleExitClick(event, p.pollid)}>Exit Poll</Button>)
              : null
          return (
            <TableRow key={p.pollid} hover={true}>
              <TableCell style={{ width: "20%" }} component="th" scope="row">{p.name}</TableCell>
              <TableCell style={{ width: "20%" }}>{p.time_in_seconds}</TableCell>
              <TableCell>{p.winning_choice_title}</TableCell>
              <TableCell style={{ width: "20%" }}>{action}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  }

  const listPollChoices = () => {
    if (!pollChoicesData?.getExperiences) return null;
    return (
      <div style={{ marginTop: "5px", fontSize: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item style={{ fontWeight: "800" }}>Choices:</Grid>
          {pollChoicesData.getExperiences.map((e) => {
            return (
              <Grid key={e.id} container direction="row" style={{ alignItems: "center" }}>
                <Checkbox checked={choices[e.id]} name={e.id.toString()} value={e.id} onChange={handleCheckboxChange} />
                <Grid item style={{ marginLeft: "5px" }}>{e.title}</Grid>
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }

  const listEvents = () => {
    if (!eventsData) return null;

    const sorted = [...eventsData.getEvents].sort((a, b) => a.id - b.id);
    sorted.unshift({ chatgroupname: "Select Event...", id: 0 })

    return sorted.map((e, i) => {
      return (
        <MenuItem key={i} value={e.id}>
          {e.chatgroupname}
        </MenuItem>
      );
    })
  }

  return (
    <div>
      <Container maxWidth="md">
        <div className="handle" style={{ width: "100%", height: "10px", backgroundColor: "#E0E0E0", borderTopRightRadius: "4px", borderTopLeftRadius: "4px", cursor: "move" }} />
        <Paper variant="outlined" style={{ padding: "0.5rem 1rem 1rem", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Select
                    autoFocus
                    variant="outlined"
                    id="eventId"
                    margin="dense"
                    value={selectedEventId}
                    onChange={handleEventChange}
                    fullWidth
                    required
                    style={{ height: "fit-content" }}
                  >
                    {listEvents()}
                  </Select>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: "10px" }}>
                    <Button variant="outlined" size="large" style={{ width: "153px" }} onClick={handleNewPollClick} disabled={!selectedEventId}>
                      New Poll
                    </Button>
                    <Button variant="outlined" size="large" style={{ width: "153px", marginTop: "5px" }} onClick={handleToggleBlueLight} disabled={!selectedEventId}>
                      {`Turn "Flashing Donate" ${blueLightQueueId ? "Off" : "On"}`}
                    </Button>
                    <Button variant="outlined" size="large" style={{ width: "153px", marginTop: "5px" }} onClick={handleToggleCheckout} disabled={!selectedEventId}>
                      {`Turn "Checkout" ${checkoutQueueId ? "Off" : "On"}`}
                    </Button>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginTop: "30px", borderTopStyle: "solid", borderTopWidth: "1px", flexGrow: "1" }}>
                  <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "700" }}>Name</TableCell>
                          <TableCell style={{ fontWeight: "700" }}>Time Left</TableCell>
                          <TableCell style={{ fontWeight: "700" }}>Winner</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      {listPolls()}
                    </Table>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Dialog open={newPollOpen} onClose={handleNewPollClose}>
        <DialogTitle>Create New Poll</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="pollName"
            label="Poll Name"
            variant="outlined"
            value={newPoll.pollName}
            onChange={handlePollFieldChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="pollTitle"
            label="Poll Title"
            variant="outlined"
            value={newPoll.pollTitle}
            onChange={handlePollFieldChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="pollTime"
            label="Time (seconds)"
            variant="outlined"
            value={newPoll.pollTime}
            onChange={handlePollFieldChange}
            required
            inputProps={{ type: "number", min: 0 }}
          />
          {listPollChoices()}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="large" onClick={handleNewPollSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PollControl;
