import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import { useAuth } from "./../hooks/UseAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    width: 400,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    textAlign: "center",
    padding: theme.spacing(2, 4, 3),
  },
}));

const Alert = ({ open, setOpen }) => {
  const auth = useAuth();
  const classes = useStyles();
  if (auth.user === false) {
    console.log("user not logged in");
  } else console.log("user logged in");

  const handleClose = () => {
    setOpen(false);
  };

  const content = () => {
    return auth.user ? (
      <>
        <p>The Event has not begun yet.</p>
        <p>Check back at event start time.</p>
      </>
    ) : (
      <>
        <p>Please log in to join event.</p>
      </>
    );
  };

  const body = (
    <div className={classes.paper}>
      {content()}
      <Button
        onClick={handleClose}
        variant="contained"
        color="primary"
        size="large"
      >
        Okay
      </Button>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {body}
      </Modal>
    </div>
  );
};
export default Alert;
