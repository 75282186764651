export default new class {
    constructor() {
        this._eventHandlers = {};
    }

    addListener = (node, event, handler, capture = false) => {
        if (!(node in this._eventHandlers)) {
            // _eventHandlers stores references to nodes
            this._eventHandlers[node] = {};
        }
        if (!(event in this._eventHandlers[node])) {
            // each entry contains another entry for each event type
            this._eventHandlers[node][event] = [];
        }
        // capture reference
        this._eventHandlers[node][event].push([handler, capture]);
        node.addEventListener(event, handler, capture);
    }

    removeAllListeners(node, event) {
        if (node in this._eventHandlers) {
            var handlers = this._eventHandlers[node];
            if (event in handlers) {
                var eventHandlers = handlers[event];
                for (var i = eventHandlers.length; i--;) {
                    var handler = eventHandlers[i];
                    node.removeEventListener(event, handler[0], handler[1]);
                }
            }
        }
    }
}()