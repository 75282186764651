import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Typography,
} from "@material-ui/core";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import EventIcon from "@material-ui/icons/Event";

const RsvpPage5 = ({
  address,
  creditCard,
  setAddress,
  setCreditCard,
  fullName,
  userData,
  eventPrice,
  paymentError,
}) => {
  const [editAddress, setEditAddress] = useState(false);
  const [editCC, setEditCC] = useState(false);
  const [newAddress, setNewAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [newCreditCard, setNewCreditCard] = useState({
    cc: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    firstName: "",
    lastName: "",
  });

  const months = [];

  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  const years = [];
  for (let i = 20; i <= 45; i++) {
    years.push(i);
  }

  const handleYear = ({ target }) => {
    setNewCreditCard((prevState) => ({
      ...prevState,
      expYear: target.value,
    }));
  };

  const handleMonth = ({ target }) => {
    setNewCreditCard((prevState) => ({
      ...prevState,
      expMonth: target.value,
    }));
  };

  const renderMonth = () => {
    return (
      <Select
        variant="outlined"
        value={newCreditCard.expMonth}
        onChange={handleMonth}
        fullWidth
        labelid="expiration_month"
        sm={12}
      >
        {months.map((item, i) => {
          return (
            <MenuItem label="Select a month" key={i} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const renderYear = () => {
    return (
      <Select
        variant="outlined"
        value={newCreditCard.expYear}
        fullWidth
        onChange={handleYear}
        labelid="expiration_year"
        sm={12}
      >
        {years.map((item, i) => {
          return (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const handleChange = ({ target }) => {
    let fieldName = target.id;
    if (fieldName === "address1") {
      setNewAddress((prevState) => ({
        ...prevState,
        address1: target.value,
      }));
    }
    if (fieldName === "address2") {
      setNewAddress((prevState) => ({
        ...prevState,
        address2: target.value,
      }));
    }
    if (fieldName === "city") {
      setNewAddress((prevState) => ({
        ...prevState,
        city: target.value,
      }));
    }
    if (fieldName === "state") {
      setNewAddress((prevState) => ({
        ...prevState,
        state: target.value,
      }));
    }
    if (fieldName === "zip") {
      setNewAddress((prevState) => ({
        ...prevState,
        zip: target.value,
      }));
    }
    if (fieldName === "cc") {
      setNewCreditCard((prevState) => ({
        ...prevState,
        cc: target.value,
      }));
    }
    if (fieldName === "cvv") {
      setNewCreditCard((prevState) => ({
        ...prevState,
        cvv: target.value,
      }));
    }
    if (fieldName === "firstName") {
      setNewCreditCard((prevState) => ({
        ...prevState,
        firstName: target.value,
      }));
    }
    if (fieldName === "lastName") {
      setNewCreditCard((prevState) => ({
        ...prevState,
        lastName: target.value,
      }));
    }
  };

  const saveNewAddress = () => {
    setAddress(newAddress);
    setEditAddress(false);
  };
  const saveNewCreditCard = () => {
    setCreditCard(newCreditCard);
    setEditCC(false);
  };

  return (
    <div className="page-content">
      <header className="page-header">
        <div>
          <h2>Event RSVP</h2>
          <span className="divider"></span>
        </div>
      </header>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 style={{ marginBottom: "1rem" }} className="formgroup-header">
            Please Confirm Your Information
          </h3>

          <Grid container className="icon-group">
            <Grid item xs={1}>
              <EventIcon className="icon-group-icon" />
            </Grid>
            <Grid item xs={11} justify-content="">
              <h3>Event Date + Time</h3>
              <p>Friday, February 19, 2021 at 1:00pm CT</p>
            </Grid>
          </Grid>

          {!editCC ? (
            <Grid container className="icon-group">
              <Grid item xs={2} sm={1}>
                <CreditCardOutlinedIcon className="icon-group-icon" />
              </Grid>

              <Grid item container xs={10} sm={11} justify="space-between">
                <Grid item xs={8}>
                  <h3>Credit Card</h3>
                </Grid>
                <Grid item xs={4}>
                  <h3
                    className="edit-btn"
                    color="primary"
                    onClick={() => setEditCC(!editCC)}
                  >
                    Edit
                  </h3>
                </Grid>

                <Grid item>
                  <p>
                    XXXX-XXXX-XXXX-
                    {creditCard.cc.substr(creditCard.cc.length - 4)}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div className="edit-form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Update Credit Card</h3>

                  <TextField
                    name="cc"
                    variant="outlined"
                    required
                    fullWidth
                    id="cc"
                    label="Card No:"
                    color="secondary"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel fullWidth id="expiration">
                    Expiration Date
                  </InputLabel>
                </Grid>
                <Grid item xs={3}>
                  {renderMonth()}
                </Grid>
                <Grid item xs={4}>
                  {renderYear()}
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="cvv"
                    label="CVV"
                    name="cvv"
                    color="secondary"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id="expiration">Cardholder Name</InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstname"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastname"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    className="btn-cancel"
                    startIcon={<CancelIcon />}
                    fullWidth
                    onClick={() => setEditCC(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={saveNewCreditCard}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          {!editAddress ? (
            <Grid container className="icon-group">
              <Grid item xs={2} sm={1}>
                <MailOutlineOutlinedIcon className="icon-group-icon" />
              </Grid>

              <Grid item container xs={10} sm={11} justify="space-between">
                <Grid item xs={8}>
                  <h3>Billing Address</h3>
                </Grid>
                <Grid item xs={4}>
                  <h3
                    className="edit-btn"
                    onClick={() => setEditAddress(!editAddress)}
                  >
                    Edit
                  </h3>
                </Grid>

                <Grid item>
                  <span className="entry-line">{`${creditCard.firstName} ${creditCard.lastName}`}</span>
                  <span className="entry-line">{address.address1}</span>
                  <span className="entry-line">
                    {address.city}, {address.state} {address.zip}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div className="edit-form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Update Billing Address</h3>
                  <TextField
                    name="address1"
                    id="address1"
                    label="Street Address 1"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="address2"
                    id="address2"
                    label="Street Address 2"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="city"
                    id="city"
                    label="City"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="state"
                    id="state"
                    label="State"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="zip"
                    id="zip"
                    label="Zip Code"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    className="btn-cancel"
                    startIcon={<CancelIcon />}
                    fullWidth
                    onClick={() => setEditAddress(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={saveNewAddress}
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid container className="icon-group">
            <Grid item xs={2} sm={1}>
              <ConfirmationNumberIcon className="icon-group-icon" />
            </Grid>

            <Grid item container xs={10} sm={11} justify="space-between">
              <Grid item xs={8}>
                <h3>Event Charges</h3>
              </Grid>
              <Grid item xs={4}>
                <h3 className="event-cost">{`$${eventPrice}`}</h3>
              </Grid>
              <Grid item>
                <p>Event Donation</p>
                <p>
                  100% of this amount is tax deductible to the extent allowed by
                  law.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <hr className="form-line"></hr>

        <Grid sm={12} item container alignItems="center" justify="flex-end">
          <h3>
            Total <span className="event-total">{`$${eventPrice}`}</span>
          </h3>
        </Grid>
        {paymentError && (
          <Grid item container alignItems="center" sm={12} justify="center">
            <Typography
              style={{ color: "red" }}
              variant="caption"
              className="sub-header"
            >
              Something went wrong. Please retry.
            </Typography>
          </Grid>
        )}
        <Divider variant="middle" />
      </Grid>
    </div>
  );
};

export default RsvpPage5;
