import React from "react";
import { Grid } from "@material-ui/core";

const CheckoutCart = (props) => {
  props.setShowBackButton(false);
  const renderView = () => {
    return (
      <Grid
        direction="column"
        container
        wrap="nowrap"
        style={{ height: "100%" }}
        justify="center"
        alignItems="center"
      >
        <Grid item className="checkout_title">
          Thank You!
        </Grid>

        <Grid item className="checkout_title">
          Now, let's leave your mark!
        </Grid>
      </Grid>
    );
  };

  return renderView();
};

export default CheckoutCart;
