import React from "react";
import { Button, Box } from "@material-ui/core";

const DonateThankYou = (props) => {
  function handleBackToEventClick() {
    props.setAnchorEl(false);
    props.setActiveView("buttonToggle");
  }
  return (
    <div className="col-flexbox-component">
      <Box textAlign="center" className="body">
        <h2>Thanks!</h2>
        <p>You've added ${props.pledgeAmt} to your donation cart.</p>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => handleBackToEventClick()}
        >
          Back to Event {">"}
        </Button>
      </Box>
    </div>
  );
};

export default DonateThankYou;
