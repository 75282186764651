import React from "react";
import { Container } from "@material-ui/core";

import ForgotPassword from "./../components/ForgotPassword";

const PageForgotPass = () => {

  return (
    <>
      <Container maxWidth="sm" className="change-password-page-wrapper">
        <ForgotPassword />
      </Container>
    </>
  );

};

export default PageForgotPass;
