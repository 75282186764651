import client from "../graphql/client";
import { from, interval, throwError, timer } from 'rxjs';
import { distinctUntilChanged, exhaustMap, mergeMap, tap, filter, retryWhen, catchError } from 'rxjs/operators';

export default new class {
  constructor() {
    this._interval = 300;
    this._retryCount = 10;
    this._retryInterval = 300;
    this._defaultOptions = { fetchPolicy: "network-only" };
  }

  pollingStream(query, pollInterval = this._interval) {
    let q = { ...this._defaultOptions, ...query };
    const polling$ = interval(pollInterval).pipe(
      exhaustMap(() => from(client.query(q))),
      catchError((e) => {
        console.error(`Network error: ${e}`);
        return throwError(e);
      }),
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((e, index) => {
            if (index > this._retryCount) {
              return throwError(e);
            }

            return timer((index + 1) * this._retryInterval).pipe(
              tap({
                complete: () => console.log(`Network retry attempt ${index + 1}`)
              })
            );
          })
        )
      })
    )
    return polling$;
  }
}()