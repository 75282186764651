import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Typography,
  Fab,
  //Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Divider,
  Fade,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../../hooks/UseAuth";
import MuiPhoneNumber from "material-ui-phone-number";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ValidatePassword } from "./ValidatePassword";
import { ValidateContactInfo } from "./ValidateContactInfo";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditProfile = () => {
  const auth = useAuth();
  //console.log("Auth", auth);
  const user = auth.user.attributes;
  const chatUser = auth.user.chatUser;
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    phone: false,
    password: false,
    editMode: false,
    loadingUpdateUser: false,
    loadingUpdatePassword: false,
    displayName: false,
    loadingDisplayName: false,
  });
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(
    false
    //!user["email_verified"]
  );
  const [verificationCode, setVerificationCode] = useState("");
  const [fullName, setFullName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [displayName, setDisplayName] = useState(chatUser.name);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState(user.phone_number);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  /*const [check, setCheck] = useState({
    checkedEmail: false,
    checkedPhone: false,
  });*/
  const [error, setError] = useState({
    fullName: false,
    email: false,
    currentPassword: false,
    password: false,
    confirmPassword: false,
    samePassword: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    phone: false,
    userExists: false,
  });
  const [modifyContactError, setModifyContactError] = useState("");
  const [modifyPasswordError, setModifyPasswordError] = useState("");
  const [modifyDisplayNameError, setModifyDisplayNameError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  /*useEffect(() => {
    console.log("isEditing", isEditing);
  }, [isEditing]);
  useEffect(() => {
    console.log("error", error);
  }, [error]);*/

  const displayNameSaveButton = useRef(null);
  const changePasswordButtonRef = useRef(null);
  const saveProfileButtonRef = useRef(null);
  const scrollToSaveButton = () => {
    if (displayNameSaveButton.current) {
      displayNameSaveButton.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const scrollToChangePasswordButton = () => {
    if (changePasswordButtonRef.current) {
      changePasswordButtonRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const scrollToSaveProfileButton = () => {
    if (saveProfileButtonRef.current) {
      saveProfileButtonRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const resendConfirmationCode = () => {
    auth.resendEmailVerification();
    setModifyContactError("");
    setSeverity("success");
    setOpen(true);
    setSuccessMessage("Verification code sent!");
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setIsEditing((prevState) => ({
      ...prevState,
      loadingUpdateUser: true,
    }));

    try {
      //console.log("calling current user info");
      var currentUserInfo = await auth.currentUserInfo();
    } catch (e) {
      console.log(e.message);
    }
    if (
      currentUserInfo["attributes"]["email"] === email &&
      currentUserInfo["attributes"]["name"] === fullName &&
      currentUserInfo["attributes"]["phone_number"] === phone
    ) {
      //console.log("all the same");
      setIsEditing((prevState) => ({
        ...prevState,
        name: false,
        email: false,
        phone: false,
        password: false,
        editMode: false,
        loadingUpdateUser: false,
      }));
    } else {
      //console.log("not all the same");
      const contactInfoErrors = await ValidateContactInfo(
        auth,
        fullName,
        email,
        phone,
        setError,
        currentUserInfo["attributes"]["email"]
      );
      //console.log("contactInfoErrors", contactInfoErrors);
      if (Boolean(contactInfoErrors.length)) {
        setIsEditing((prevState) => ({
          ...prevState,
          loadingUpdateUser: false,
        }));
        setOpen(true);
        setSeverity("error");
        setSuccessMessage("Please correct the invalid information!");
      } else {
        try {
          await auth.updateUser({
            email: email,
            name: fullName,
            phone_number: phone,
          });

          if (currentUserInfo["attributes"]["email"] !== email) {
            setOpen(true);
            setSeverity("info");
            setSuccessMessage(
              "Verification code sent! Please check your E-mail to verify!"
            );
            setIsVerifyingEmail(true);
          } else {
            setOpen(true);
            setSeverity("success");
            setSuccessMessage("You have successfully updated your account!");
          }
          setIsEditing((prevState) => ({
            ...prevState,
            name: false,
            email: false,
            phone: false,
            password: false,
            editMode: false,
            loadingUpdateUser: false,
          }));
          setModifyContactError("");
        } catch (error) {
          console.log(error);
          setModifyContactError(error.message);
          setIsEditing((prevState) => ({
            ...prevState,
            loadingUpdateUser: false,
          }));
        }
      }
    }
  };

  const verifyingChangeEmail = async (e) => {
    e.preventDefault();
    try {
      //console.log("verification code is", verificationCode);
      //let verifyResult =
      await auth.verifyAttribute(verificationCode);
      //console.log("verify result", verifyResult);
      await auth.getUpdatedUser();
      setOpen(true);
      setSeverity("success");
      setSuccessMessage("You have successfully updated your account!");
      setModifyContactError("");
      setIsVerifyingEmail(false);
    } catch (error) {
      console.log(error);
      setModifyContactError(error.message);
      setIsEditing((prevState) => ({
        ...prevState,
        loadingUpdateUser: false,
      }));
    }
  };

  async function changePassword(event) {
    event.preventDefault();

    setModifyPasswordError("");

    setIsEditing((prevState) => ({
      ...prevState,
      loadingUpdatePassword: true,
    }));

    const passErrors = await ValidatePassword(
      password,
      confirmPassword,
      setError,
      currentPassword,
      setModifyPasswordError
    );
    console.log("passError", passErrors);
    if (!Boolean(passErrors.length)) {
      try {
        await auth.changePassword(currentPassword, password);
        setError((prevState) => ({
          ...prevState,
          currentPassword: false,
        }));
        setOpen(true);
        setSeverity("success");
        setSuccessMessage("You have successfully changed your password!");
        setIsEditing((prevState) => ({
          ...prevState,
          password: false,
        }));
        setModifyPasswordError("");
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      } catch (err) {
        console.log(err);
        //setModifyPasswordError("Incorrect Password");
        setError((prevState) => ({
          ...prevState,
          currentPassword: true,
        }));
      }
    }
    setIsEditing((prevState) => ({
      ...prevState,
      loadingUpdatePassword: false,
    }));
  }

  const changeDisplayName = async (e) => {
    e.preventDefault();

    setIsEditing((prevState) => ({
      ...prevState,
      loadingDisplayName: true,
    }));
    if (displayName === chatUser.name) {
      setIsEditing((prevState) => ({
        ...prevState,
        displayName: false,
      }));
      setError((prevState) => ({
        ...prevState,
        displayName: false,
      }));
      setModifyDisplayNameError("");
    } else {
      try {
        auth.updateChatDisplayName(displayName).then(() => {
          setIsEditing((prevState) => ({
            ...prevState,
            displayName: false,
          }));
          setError((prevState) => ({
            ...prevState,
            displayName: false,
          }));
          setModifyDisplayNameError("");
          setOpen(true);
          setSeverity("success");
          setSuccessMessage("You have successfully updated your display name!");
        });
      } catch (err) {
        console.log(err);
        setModifyDisplayNameError(err);
        setError((prevState) => ({
          ...prevState,
          displayName: true,
        }));
      } finally {
        setIsEditing((prevState) => ({
          ...prevState,
          loadingDisplayName: false,
        }));
      }
    }
  };

  /*const handleCheck = (e) => {
    setCheck({ ...check, [e.target.name]: e.target.checked });
  };*/

  const handleChange = ({ target }) => {
    let fieldName = target.id;

    switch (fieldName) {
      case "email":
        setEmail(target.value);
        break;
      case "fullName":
        setFullName(target.value);
        break;
      case "currentPassword":
        setCurrentPassword(target.value);
        break;
      case "password":
        setPassword(target.value);
        break;
      case "confirm":
        setConfirmPassword(target.value);
        break;
      case "displayname":
        setDisplayName(target.value);
        break;
      default:
        break;
    }
  };

  const handleVerificationCode = ({ target }) => {
    setVerificationCode(target.value.replace(/\s/g, ""));
  };

  const handlePhone = (e) => {
    const phone_re = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const formatPhone = e.replace(/[() -]/g, "");

    if (!phone_re.test(formatPhone)) {
      setError((prevState) => ({
        ...prevState,
        phone: true,
      }));
    } else if (phone_re.test(formatPhone)) {
      setError((prevState) => ({
        ...prevState,
        phone: false,
      }));
      setPhone(formatPhone);
    }
  };

  const handleChangePassword = () => {
    setIsEditing((prevState) => ({
      ...prevState,
      password: true,
    }));
  };

  const handleEdit = (e) => {
    switch (e) {
      case user.name:
        setIsEditing((prevState) => ({
          ...prevState,
          name: true,
        }));
        break;
      case user.phone_number:
        setIsEditing((prevState) => ({
          ...prevState,
          phone: true,
        }));
        break;
      case user.email:
        setIsEditing((prevState) => ({
          ...prevState,
          email: true,
        }));
        break;
      default:
        break;
    }
  };

  const handleCancelEditing = (e) => {
    if (e === user.name) {
      setIsEditing((prevState) => ({
        ...prevState,
        name: false,
      }));
      setFullName(user.name);
      setError((prevState) => ({
        ...prevState,
        fullName: false,
      }));
    } else if (e === user.phone_number) {
      setIsEditing((prevState) => ({
        ...prevState,
        phone: false,
      }));
      setError((prevState) => ({
        ...prevState,
        phone: false,
      }));
      setPhone(user.phone_number);
    } else if (e === user.email) {
      setIsEditing((prevState) => ({
        ...prevState,
        email: false,
      }));
      setError((prevState) => ({
        ...prevState,
        email: false,
      }));
      setEmail(user.email);
    }
  };

  const handleEditDisplayName = (e) => {
    setIsEditing((prevState) => ({
      ...prevState,
      displayName: true,
    }));
  };

  const cancelEditDisplayName = (e) => {
    setIsEditing((prevState) => ({
      ...prevState,
      displayName: false,
    }));
  };

  const cancelPasswordUpdate = () => {
    setError((prevState) => ({
      ...prevState,
      currentPassword: false,
    }));
    setIsEditing((prevState) => ({
      ...prevState,
      password: false,
    }));
  };

  const hiddenEmail = (email) => {
    let y = email.split("@");
    const hidden = `${y[0].charAt(0)}***${y[0].slice(-1)}@` + y.pop();
    return hidden;
  };

  const hiddenPhone = (phone) => {
    if (!phone) return "";
    const hidden = `*******${phone.slice(phone.length - 4)}`;
    return hidden;
  };

  function renderPassword() {
    return (
      <>
        <Grid item xs={12} className="edit-profile-text-field">
          <TextField
            name="Password"
            id="Password"
            label="Password"
            value="********"
            variant="outlined"
            onChange={handleChange}
            inputProps={{ "aria-label": "Password" }}
            fullWidth
            disabled
          />

          <Fab
            size="small"
            color="primary"
            aria-label="edit"
            className="btn-edit-form-field"
            style={styles.editIcon}
            onClick={handleChangePassword}
          >
            <EditIcon />
          </Fab>
        </Grid>
      </>
    );
  }

  function renderVerifyingEmail() {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            name="verificationCode"
            id="verificationCode"
            label="Verification Code"
            value={verificationCode}
            variant="outlined"
            onChange={handleVerificationCode}
            inputProps={{ "aria-label": "Verification Code" }}
            fullWidth
            focused="true"
            required
          />
          <p>
            Didn't receive the email?{" "}
            <span
              onClick={resendConfirmationCode}
              style={{
                textDecoration: "underline",
                color: "primary",
                cursor: "pointer",
              }}
            >
              Resend Confirmation Code
            </span>
          </p>
        </Grid>
        <Grid item xs={12} container direction="row" justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={verifyingChangeEmail}
            disabled={!verificationCode}
          >
            Next
          </Button>
        </Grid>
      </>
    );
  }

  function renderChangePassword() {
    return (
      <>
        <Grid item xs={12} className="edit-profile-text-field">
          <TextField
            name="currentPassword"
            id="currentPassword"
            type={showCurrentPassword ? "text" : "password"}
            focused="true"
            label="Current Password"
            variant="outlined"
            value={currentPassword}
            onChange={handleChange}
            inputProps={{ "aria-label": "description" }}
            fullWidth
            required
            error={error.currentPassword}
            helperText={error.currentPassword ? "Incorrect Password" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}
                  >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className="edit-profile-text-field">
          <TextField
            name="password"
            id="password"
            type={showPassword ? "text" : "password"}
            focused="true"
            label="New Password"
            variant="outlined"
            value={password}
            onChange={handleChange}
            inputProps={{ "aria-label": "description" }}
            fullWidth
            required
            error={error.password || error.confirmPassword}
            helperText={
              error.password
                ? "Password must contain 8 to 20 characters with at least one digit, one upper case letter, one lower case letter and one special symbol (“@#$%”)"
                : error.confirmPassword
                  ? ""
                  : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className="edit-profile-text-field">
          <TextField
            name="confirm"
            id="confirm"
            type={showConfirmPassword ? "text" : "password"}
            focused="true"
            label="Confirm Password"
            variant="outlined"
            value={confirmPassword}
            onChange={handleChange}
            inputProps={{ "aria-label": "description" }}
            fullWidth
            required
            error={error.confirmPassword || error.password}
            helperText={
              error.confirmPassword
                ? "Passwords do not match"
                : error.password
                  ? "Password must contain 8 to 20 characters with at least one digit, one upper case letter, one lower case letter and one special symbol (“@#$%”)"
                  : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} className="login_error">
          <Typography color="error" variant="caption" display="block">
            {modifyPasswordError}
          </Typography>
        </Grid>

        <Grid item xs={12} container direction="row" justify="space-between">
          <Button
            size="large"
            className="btn-cancel"
            onClick={cancelPasswordUpdate}
            disabled={error.error}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>

          {isEditing.password ? (
            <Fade in={true} onEntered={scrollToChangePasswordButton}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={changePassword}
                disabled={
                  currentPassword === "" ||
                  password === "" ||
                  confirmPassword === ""
                }
                ref={changePasswordButtonRef}
              >
                {isEditing.loadingUpdatePassword ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Change Password"
                )}
              </Button>
            </Fade>
          ) : null}
        </Grid>
      </>
    );
  }

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <header className="page-header">
            <div>
              <h2>Edit Profile</h2>
              <span className="divider"></span>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="edit-profile-content">
        <Grid item xs={12}>
          <h3 className="formgroup-header">Contact Information</h3>
        </Grid>

        {isVerifyingEmail && renderVerifyingEmail()}

        {!isVerifyingEmail && !isEditing.name && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="fullName"
                id="fullName"
                label="Full Name"
                value={fullName}
                variant="outlined"
                onChange={handleChange}
                inputProps={{ "aria-label": "description" }}
                fullWidth
                disabled
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleEdit(user.name)}
              >
                <EditIcon />
              </Fab>
            </Grid>
          </>
        )}

        {!isVerifyingEmail && isEditing.name && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="fullName"
                id="fullName"
                label="Full Name"
                variant="outlined"
                focused="true"
                value={fullName}
                onChange={handleChange}
                inputProps={{ "aria-label": "description" }}
                fullWidth
                required
                error={error.fullName}
                helperText={
                  error.fullName ? "Please enter your full name." : null
                }
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleCancelEditing(user.name)}
              >
                <CancelIcon />
              </Fab>
            </Grid>
          </>
        )}

        {!isVerifyingEmail && !isEditing.email && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="email"
                id="email"
                label="Email Address"
                value={hiddenEmail(email)}
                variant="outlined"
                onChange={handleChange}
                inputProps={{ "aria-label": "Email Address" }}
                fullWidth
                disabled
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleEdit(user.email)}
              >
                <EditIcon />
              </Fab>
            </Grid>
          </>
        )}
        {!isVerifyingEmail && isEditing.email && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="email"
                id="email"
                label="Email Address"
                value={email}
                variant="outlined"
                onChange={handleChange}
                focused="true"
                inputProps={{ "aria-label": "Email Address" }}
                fullWidth
                required
                error={error.userExists ? true : error.email}
                helperText={
                  error.userExists
                    ? "An account with the given email already exists."
                    : error.email
                      ? "Please enter a valid email address."
                      : null
                }
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleCancelEditing(user.email)}
              >
                <CancelIcon />
              </Fab>
            </Grid>
          </>
        )}

        {!isVerifyingEmail && !isEditing.phone && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="phone"
                id="phone"
                label="Phone Number"
                defaultCountry={"us"}
                //error={error.phone}
                //value={phone}
                value={hiddenPhone(phone)}
                variant="outlined"
                inputProps={{ "aria-label": "Phone Number" }}
                fullWidth
                disabled
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleEdit(user.phone_number)}
              >
                <EditIcon />
              </Fab>
            </Grid>
          </>
        )}
        {!isVerifyingEmail && isEditing.phone && (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <MuiPhoneNumber
                name="phone"
                id="phone"
                label="Phone Number"
                focused="true"
                defaultCountry={"us"}
                error={error.phone}
                helperText={
                  error.phone ? "Please enter a valid phone number." : null
                }
                value={phone}
                variant="outlined"
                inputProps={{ "aria-label": "Phone Number" }}
                fullWidth
                onChange={handlePhone}
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleCancelEditing(user.phone_number)}
              >
                <CancelIcon />
              </Fab>
            </Grid>
          </>
        )}

        <Grid item xs={12} className="login_error">
          <Typography color="error" variant="caption" display="block">
            {modifyContactError}
          </Typography>
        </Grid>

        <Grid item xs={12} container direction="row" justify="flex-end">
          {isEditing.phone || isEditing.email || isEditing.name ? (
            <Fade in={true} onEntered={scrollToSaveProfileButton}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={updateUser}
                disabled={error.error}
                startIcon={<SaveIcon />}
                ref={saveProfileButtonRef}
              >
                {isEditing.loadingUpdateUser ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Save Profile"
                )}
              </Button>
            </Fade>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <h3 className="formgroup-header">Display Name</h3>
        </Grid>

        {!isEditing.displayName ? (
          <>
            <Grid item xs={12} className="edit-profile-text-field">
              <TextField
                name="displayName"
                id="displayName"
                label="Display Name"
                value={displayName}
                variant="outlined"
                onChange={handleChange}
                inputProps={{ "aria-label": "Email Address" }}
                fullWidth
                disabled
              />

              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className="btn-edit-form-field"
                style={styles.editIcon}
                onClick={() => handleEditDisplayName()}
              >
                <EditIcon />
              </Fab>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid item xs={12} className="edit-profile-text-field">
                <TextField
                  name="displayname"
                  id="displayname"
                  label="Display Name"
                  focused="true"
                  value={displayName}
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Email Address" }}
                  fullWidth
                />

                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  className="btn-edit-form-field"
                  style={styles.editIcon}
                  onClick={() => cancelEditDisplayName()}
                >
                  <CancelIcon />
                </Fab>
              </Grid>
            </Grid>

            <Grid item xs={12} className="login_error">
              <Typography color="error" variant="caption" display="block">
                {modifyDisplayNameError}
              </Typography>
            </Grid>

            <Grid item xs={12} container direction="row" justify="flex-end">
              {isEditing.displayName && (
                <Fade in={true} onEntered={scrollToSaveButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                    onClick={changeDisplayName}
                    ref={displayNameSaveButton}
                  >
                    {isEditing.loadingUpdateDisplayName ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "Save Change"
                    )}
                  </Button>
                </Fade>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <h3 className="formgroup-header">Password</h3>
        </Grid>
        {!isEditing.password ? renderPassword() : renderChangePassword()}

        {/*
        <Grid item xs={12}>
          <h3 className="formgroup-header">Event Communication</h3>
          <FormGroup column>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkedPhone}
                  onChange={handleCheck}
                  name="checkedPhone"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className="sub-header">
                  Receive essential updates about this event via email
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        */}

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity}>
            {successMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

const styles = {
  fieldContainer: {
    width: "100%",
    margin: "0 .5rem 0 .5rem",
    textAlign: "center",
  },
  divider: {
    width: "100%",
    color: "#ddd",
    marginTop: "-.5rem",
  },
  editIcon: {
    //position: "absolute",
    right: "2rem",
    marginTop: ".5rem",
    cursor: "pointer",
    background: "none",
    boxShadow: "none",
    color: "#8e8e8e",
    position: "fixed",
  },
  name: {
    display: "inline-block",
  },
  label: {
    color: "#ddd",
    fontWeight: "bolder",
    display: "inline-block",
    float: "left",
  },
};

export { EditProfile };
