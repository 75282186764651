import React from "react";
import { Container } from "@material-ui/core";

import RegistrationMain from "./../components/Registration/RegistrationMain";

const PageSignUp = (props) => {
  return (
    <>
      <Container
        className="register_container"
      >
        <RegistrationMain props={props} />
      </Container>
    </>
  );
};

export default PageSignUp;
