import React from "react";
import { Container, Paper } from "@material-ui/core";

import LoadSequins from "./../components/LoadSequins";

const LoadSequinsPage = () => {

  return (
    <>
      <Container maxWidth="sm" >
        <Paper className="paper_wrapper" variant="outlined">
          <h3>Load Sequins</h3>
          <LoadSequins />
        </Paper>
      </Container>
    </>
  );

};

export default LoadSequinsPage;
