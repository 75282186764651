import React, { useState, useEffect, Fragment } from "react";
//import FetchInvoiceItems from "./DonateFetchInvoiceItems";
import { Grid, Button, withStyles } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import * as MUTATIONS from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import * as QUERIES from "../../graphql/queries";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import blue from "@material-ui/core/colors/blue";
import { useQuery } from "@apollo/client";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "gray",
    position: "absolute",
    //position: "relative",
    textAlign: "center",
    padding: "10px",
  },
  dialogBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    //color: "lightgray",
    backgroundColor: "transparent",
    position: "relative",
    //padding: "10px",
    boxShadow: "none",
  },
  root: {
    //backgroundColor: "transparent",
  },
}));

const AmountButton = withStyles((theme) => ({
  root: {
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //padding: '5px 32px',
    //'&:hover': {
    //    background: '#bdbdbd',
    //},
    borderRadius: 25,
    //border: 1,
    borderColor: blue[700],
    color: blue[700],
    height: 48,
    outline: 2,
    opacity: 1,
  },
}))(Button);

const ManageDonations = (props) => {
  //console.log("props");
  //console.log(props);
  //console.log("invoice id is", props.invoiceID);
  //console.log(props.callbackFromParent);
  //props.callbackFromParent("test");
  /*useEffect(() => {
    console.log("invoice ID is ", props.invoiceID);
  }, [props.invoiceID]);*/
  const classes = useStyles();
  const invoiceID = parseInt(props.invoiceID);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { loading, error, data } = useQuery(QUERIES.listItemsByInvoice, {
    skip: !invoiceID, 
    variables: { invoiceid: invoiceID },
  });

  const [open, setOpen] = useState(false);
  const [itemIDToDelete, setItemIDToDelete] = useState(null);
  const [itemAmtToDelete, setItemAmtToDelete] = useState(null);
  const handleClickOpen = (itemID, itemAmt) => {
    setOpen(true);
    setItemIDToDelete(itemID);
    setItemAmtToDelete(itemAmt);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [deleteInvoiceItem] = useMutation(MUTATIONS.deleteInvoiceItem);

  //const invoiceItemsList = FetchInvoiceItems(invoiceID);

  useEffect(() => {
    if (props.popperRef.current) {
      //console.log("UPDATING popper");
      props.popperRef.current.update();
    }
  }, [data, props.popperRef]);

  useEffect(() => {
    if (props.totAmountFetched === 0) {
      props.setActiveView("buttonToggle");
    }
  }, [props, props.totAmountFetched]);

  function deleteAnInvoiceItem(id, amt) {
    handleClose();
    //console.log("Amt to delete is ", amt);
    deleteInvoiceItem({
      variables: { input: { id: id } },

      update: (cache) => {
        // delete invoice item in cache
        const existingInvoiceItems = cache.readQuery({
          query: QUERIES.listItemsByInvoice,
          variables: { invoiceid: props.invoiceID },
        });
        if (existingInvoiceItems) {
          const newInvoiceItems = existingInvoiceItems.listItemsByInvoice.filter(
            (item) => item.id !== id
          );
          cache.writeQuery({
            query: QUERIES.listItemsByInvoice,
            variables: { invoiceid: props.invoiceID },
            data: { listItemsByInvoice: newInvoiceItems },
          });
        }

        /*
        // update total in cache
        const existingInvoiceTotal = cache.readQuery({
          query: QUERIES.getInvoiceTotal,
          variables: { invoiceid: props.invoiceID },
        });
        if (existingInvoiceTotal) {
          var newInvoiceTotal = JSON.parse(JSON.stringify(existingInvoiceTotal));
          newInvoiceTotal.getInvoiceTotal.total =
            existingInvoiceTotal.getInvoiceTotal.total - amt;
          cache.writeQuery({
            query: QUERIES.getInvoiceTotal,
            variables: { invoiceid: props.invoiceID },
            data: { getInvoiceTotal: newInvoiceTotal },
          });
        }
        */

        // update user total in cache
        const existingInvoiceTotalByUser = cache.readQuery({
          query: QUERIES.getInvoiceTotalByUser,
          variables: { uid: props.userID },
        });
        if (existingInvoiceTotalByUser) {
          var newInvoiceTotalByUser = JSON.parse(
            JSON.stringify(existingInvoiceTotalByUser)
          );
          newInvoiceTotalByUser.getInvoiceTotalByUser.total =
            existingInvoiceTotalByUser.getInvoiceTotalByUser.total - amt;
          cache.writeQuery({
            query: QUERIES.getInvoiceTotalByUser,
            variables: { uid: props.userID },
            data: { getInvoiceTotalByUser: newInvoiceTotalByUser },
          });
        }
      },
    });
  }

  useEffect(() => {
    if (loading) {
      //console.log("setting back drop to true");
      setOpenBackDrop(true);
    } else {
      setOpenBackDrop(false);
      //console.log("setting back drop to false");
    }
  }, [loading]);

  useEffect(() => {
    if (error) console.log("GraphQL Error! listItemsByInvoice", error.message);
  }, [error]);

  return (
    <Fragment>
      <div className="col-flexbox-component">
        <div className="donateTitle">Donation Cart:</div>
      </div>
      {data ? (
        <div className="col-flexbox-component donationAmountSection">
          <Grid container spacing={2}>
            {data.listItemsByInvoice &&
              data.listItemsByInvoice.map((item) => (
                <Grid item xs={6} sm={4} key={item.id} className="amountButtonGridItem">
                  <AmountButton
                    size="large"
                    variant="outlined"
                    endIcon={
                      <Cancel
                        //className="cancel"
                        style={{ fill: "gray", fontSize: "2rem" }}
                        onClick={() => handleClickOpen(item.id, item.amount)}
                      />
                    }
                  >
                    $ {item.amount}
                  </AmountButton>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="confirm-delete-dialog"
                    aria-describedby="confirm-delete-an-donation-amount"
                    BackdropProps={{
                      classes: {
                        root: classes.dialogBackdrop,
                      },
                    }}
                    PaperProps={{
                      style: {
                        backgroundColor: "white",
                        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove your donation of $
                        {itemAmtToDelete}?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          deleteAnInvoiceItem(itemIDToDelete, itemAmtToDelete)
                        }
                        color="primary"
                      >
                        Yes
                      </Button>
                      <Button onClick={handleClose} color="primary" autoFocus>
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              ))}
          </Grid>
        </div>
      ) : (
        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
  </Fragment>
  );
};

export default ManageDonations;
