export default function getDateOnlyFromEpoch(epochSeconds){
    //console.log("epochSeconds", parseInt(epochSeconds));
    const dateOnlyFromEpoch = new Date(new Date(parseInt(epochSeconds)).toDateString());
    
    const year = dateOnlyFromEpoch.getFullYear();
    var month = (1 + dateOnlyFromEpoch.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month; //padding month to 2 digits
    var day = dateOnlyFromEpoch.getDate().toString();
    day = day.length > 1 ? day : '0' + day; //padding day to 2 digits
    const formattedDateString = month + "/" + day + "/" + year;

    return formattedDateString;
}