import React from "react";
//import CountdownClock from "./CountdownClock";
import { Button, Typography, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { REACT_APP_BASE_URL, SocialShare } from "./../components/SocialShare";
import grey from "@material-ui/core/colors/grey";
//import EventButton from "./EventButton";

const Home = () => {
  // const [color, setColor] = React.useState("default");

  // const handleChange = (event) => {
  //   setColor(event.target.checked ? "blue" : "default");
  // };

  return (
    <>
      <div className="home-container">
        <div className="top">
          <Typography className="home-welcome-msg">Welcome</Typography>
          <div className="btn-mid-row">
            <Button
              className="btn-garden"
              variant="outlined"
              size="large"
              component={RouterLink}
              to="/video/introduction"
              color="inherit"
              fullWidth={false}
            >
              Visit Sequin Forest
            </Button>
          </div>
          {/*
          <div className="mid-row">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  className="btn-garden"
                  variant="outlined"
                  size="large"
                  component={RouterLink}
                  to="/video/introduction"
                  color="inherit"
                  fullWidth={true}
                >
                  Visit Sequin Forest
                </Button>
              </Grid>
             
              <Grid item xs={12} sm={6} md={6}>
                <EventButton
                  className="btn-garden"
                  variant="outlined"
                  size="large"
                  to="/live"
                  color="inherit"
                  fullWidth={true}
                >
                  Join Event
                </EventButton>
              </Grid>
              
            </Grid>
            
          </div>
          */}
        </div>

        <SocialShare
          url={REACT_APP_BASE_URL}
          classname="social-share"
          iconStyle={{ color: grey[50] }}
        />
      </div>
    </>
  );
};

export default Home;
