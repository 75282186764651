import * as QUERIES from "../../../graphql/queries";
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../hooks/UseAuth";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  makeStyles,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import DeleteIcon from "@material-ui/icons/Delete";
//import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CreditCardForm from "../../CreditCardForm";
import CreditCardAddressForm from "../../CreditCardAddressForm";
import axios from "axios";
import * as PaymentAPI from "../../../utilities/PaymentAPI";
import axiosRetry from "axios-retry";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as MUTATIONS from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  styledEditIcon: {
    width: "2em",
    cursor: "pointer",
  },
  circularProgress: {
    textAlign: "center",
  },
  expanded: {
    backgroundColor: "#f1f1f1",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MyCreditCard = (theme) => {
  const classes = useStyles();
  const userID = useAuth().user.username;

  // states for displaying/changing current credit card info
  //const { loading, error, data, refetch } = useQuery(QUERIES.getPaymentInformationByUser, {
  const { loading, error, data } = useQuery(
    QUERIES.getActiveAliasByUser,
    {
      variables: { uid: userID },
      //fetchPolicy: "cache-and-network",
    }
  );
  const [name, setName] = useState("");
  const [currentCreditCardAddress, setCurrentCreditCardAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });

  // states for adding new credit card
  const [valid, setValid] = useState(false);
  const [newCreditCard, setNewCreditCard] = useState({
    cc: null,
    expMonth: null,
    expYear: null,
    cvv: null,
    firstName: "",
    lastName: "",
  });
  const [newCreditCardAddress, setNewCreditCardAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [processingAddCC, setProcessingAddCC] = useState(false);

  const [aliasError, setAliasError] = useState(false);

  const updateAliasCache = (cache, { data }) => {
    // update active alias
    const existingActiveAliasByUser = cache.readQuery({
      query: QUERIES.getActiveAliasByUser,
      variables: { uid: userID },
    });
    if (existingActiveAliasByUser) {
      var newActiveAliasByUser = JSON.parse(
        JSON.stringify(existingActiveAliasByUser)
      );
      newActiveAliasByUser = data;
      console.log(newActiveAliasByUser);
      cache.writeQuery({
        query: QUERIES.getActiveAliasByUser,
        variables: { uid: userID },
        data: { getActiveAliasByUser: newActiveAliasByUser },
      });
    }
  };

  const [createActivePaymentInformation] = useMutation(
    MUTATIONS.createActivePaymentInformation,
    {
      update: updateAliasCache,
    }
  );

  // states for snackbar status
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getLast3DigitsOfCC = (cc) => {
    const last3 = `${cc.slice(cc.length - 3)}`;
    return last3;
  };

  const getExpirationForDB = (month, year) => {
    if (String(month).length === 2) {
      return String(month) + "20" + String(year);
    } else {
      return "0" + String(month) + "20" + String(year);
    }
  };

  const SaveCreditAlias = async (creditCard, address, userID) => {
    const client = axios.create();
    axiosRetry(client, {
      retries: 5,
      retryDelay: (retryCount, error) => {
        console.log("retry attempt: ", retryCount);
        console.log("error", error);
        return retryCount * 500;
      },
      retryCondition: axiosRetry.isRetryableError,
    });

    const options = {
      url: PaymentAPI.ALIAS_URL,
      method: "POST",
      headers: {
        "x-api-key": PaymentAPI.APIKEY,
        "Content-Type": "application/json",
      },
      data: {
        userid: userID,
        first_name: creditCard.firstName,
        last_name: creditCard.lastName,
        address: address.address1,
        city: address.city,
        state: address.state,
        postal_code: address.zip,
        ccNumber: creditCard.cc,
        ccExpMonth: creditCard.expMonth,
        ccExpYear: creditCard.expYear,
        cvv: creditCard.cvv,
      },
    };

    return client(options)
      .then((response) => {
        console.log(response);
        console.log("result", response.data.result);
        if (response.data.result === "Alias Created") {
          setAliasError(false);
          console.log(response.data);
          setOpen(true);
          setProcessingAddCC(false);
          /*
          console.log(
            "expirationfordb",
            getExpirationForDB(creditCard.expMonth, creditCard.expYear)
          );
          */
          createActivePaymentInformation({
            variables: {
              input: {
                aliasid: response.data.aliasid,
                userid: response.data.userid,
                first_name: creditCard.firstName,
                last_name: creditCard.lastName,
                address: address.address1,
                city: address.city,
                state: address.state,
                postal_code: address.zip,
                paymentmethod: response.data.paymentmethod,
                last_3: getLast3DigitsOfCC(creditCard.cc),
                expiration: getExpirationForDB(
                  creditCard.expMonth,
                  creditCard.expYear
                ),
              },
            },
          });

          // refetch credit card info
          //refetch();

          // set current credit card info
          setName(creditCard.firstName + " " + creditCard.lastName);
          setCurrentCreditCardAddress((prevState) => ({
            ...prevState,
            address1: newCreditCardAddress.address1,
            address2: newCreditCardAddress.address2,
            city: newCreditCardAddress.city,
            state: newCreditCardAddress.state,
            zip: newCreditCardAddress.zip,
          }));

          //clears textfields of new cc
          setNewCreditCard(() => ({
            cc: null,
            expMonth: null,
            expYear: null,
            cvv: null,
            firstName: "",
            lastName: "",
          }));
          setNewCreditCardAddress(() => ({
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
          }));

          setIsEditing((prevState) => ({
            ...prevState,
            newCreditCard: false,
            newCreditCardAddress: false,
          }));
        }
      })
      .catch((err) => {
        console.log("CAUGHT alias error", err);
        setAliasError(true);
        if (err.response) {
          console.log("5xx or 4xx");
          console.log(err.response);
        } else if (err.request) {
          console.log("err.request", err.request);
        }
        setProcessingAddCC(false);
      });
  };

  useEffect(() => {
    if (!loading && data.getActiveAliasByUser) {
      setName(
        data.getActiveAliasByUser.first_name +
        " " +
        data.getActiveAliasByUser.last_name
      );
      setCurrentCreditCardAddress((prevState) => ({
        ...prevState,
        address1: data.getActiveAliasByUser.address,
        city: data.getActiveAliasByUser.city,
        state: data.getActiveAliasByUser.state,
        zip: data.getActiveAliasByUser.postal_code,
      }));
    }
    /*
    if (loading) {
      console.log("calling getActiveAliasByUser with user ID ", userID);
    }
    */
  }, [data, loading, userID]);

  useEffect(() => {
    if (error) {
      console.log("GraphQL Error! getActiveAliasByUser", error.message);
    }
  }, [error]);

  const [isEditing, setIsEditing] = useState({
    name: false,
    billingAddress: false,
    newCreditCard: false,
    newCreditCardAddress: false,
    loadingUpdateName: false,
    loadingUpdateBillingAddress: false,
  });

  //  console.log("newCreditCard? ", isEditing.newCreditCard);
  //  console.log("newCreditCardAddress? ", isEditing.newCreditCardAddress);

  const handleChange = ({ target }) => {
    let fieldName = target.id;
    switch (fieldName) {
      case "name":
        setName(target.value);
        break;
      case "address1":
        setCurrentCreditCardAddress((prevState) => ({
          ...prevState,
          address1: target.value,
        }));
        break;
      case "address2":
        setCurrentCreditCardAddress((prevState) => ({
          ...prevState,
          address2: target.value,
        }));
        break;
      case "city":
        setCurrentCreditCardAddress((prevState) => ({
          ...prevState,
          city: target.value,
        }));
        break;
      case "state":
        setCurrentCreditCardAddress((prevState) => ({
          ...prevState,
          state: target.value,
        }));
        break;
      case "postal_code":
        setCurrentCreditCardAddress((prevState) => ({
          ...prevState,
          zip: target.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setProcessingAddCC(true);
    /*
    console.log("userID", userID);
    console.log("cc", newCreditCard);
    console.log("add", newCreditCardAddress);
    */
    await SaveCreditAlias(newCreditCard, newCreditCardAddress, userID);
  };

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <header className="page-header">
            <div>
              {!isEditing.newCreditCard && !isEditing.newCreditCardAddress && (
                <h2>My Credit Card</h2>
              )}
              {isEditing.newCreditCard && <h2>Enter New Credit Card</h2>}
              {isEditing.newCreditCardAddress && <h2>Billing Address</h2>}
              <span className="divider"></span>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="credit-card-scrollable-wrapper">
        {isEditing.newCreditCard && (
          <Grid item xs={12}>
            <Typography variant="caption" className="sub-header">
              Saving a new credit card will replace your previous saved credit
              card.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {loading && (
            <div className={classes.circularProgress}>
              <CircularProgress style={{ color: "gray" }} />
            </div>
          )}

          {!loading && !data.getActiveAliasByUser && <div></div>}

          {
            !loading &&
            data.getActiveAliasByUser &&
            !isEditing.newCreditCard &&
            !isEditing.newCreditCardAddress &&
            data &&
            data.getActiveAliasByUser && (
              <div className="credit-accordion-container">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="creditcard-panel"
                    classes={{ expanded: classes.expanded }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        {
                          <div className="payment">
                            <CreditCardIcon fontSize="large" />
                            <div className="eventName">
                              {data.getActiveAliasByUser.paymentmethod + " "}
                              ****{data.getActiveAliasByUser.last_3}
                            </div>
                          </div>
                        }
                      </Grid>
                    </Grid>
                    {/*<Grid item xs={1}>
                  <DeleteIcon />
                    </Grid>*/}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={6} direction="column">
                        <div className="boldSubHeader">Name on card</div>

                        {!isEditing.name ? (
                          <Grid container>
                            <Grid item xs={12} container direction="row">
                              <div>
                                {/*paymentItem.first_name + " " + paymentItem.last_name*/}
                                {data.getActiveAliasByUser.first_name +
                                  " " +
                                  data.getActiveAliasByUser.last_name}
                              </div>
                              {/*
                          <EditIcon
                            className={classes.styledEditIcon}
                            onClick={() => {
                              setIsEditing((prevState) => ({
                                ...prevState,
                                name: true,
                              }));
                            }}
                          />
                          */}
                            </Grid>
                          </Grid>
                        ) : (
                          <div className="paddedTextFieldsArea">
                            <Grid container>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="name"
                                  id="name"
                                  value={name}
                                  variant="outlined"
                                  //onChange={handleChange}
                                  inputProps={{ "aria-label": "Name" }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6} direction="column">
                        <Grid container direction="row">
                          <Grid item xs={12}>
                            <div className="boldSubHeader">
                              Billing Address
                              {/*!isEditing.address && (
                            <EditIcon
                              className={classes.styledEditIcon}
                              onClick={() => {
                                setIsEditing((prevState) => ({
                                  ...prevState,
                                  address: true,
                                }));
                              }}
                            />
                            )*/}
                            </div>
                          </Grid>
                        </Grid>

                        {!isEditing.address ? (
                          [
                            <Grid item xs={12} direction="row">
                              {/*paymentItem.address*/}
                              {data.getActiveAliasByUser.address}
                            </Grid>,
                            <Grid item xs={12} direction="row">
                              {data.getActiveAliasByUser.city},{" "}
                              {data.getActiveAliasByUser.state},{" "}
                              {data.getActiveAliasByUser.postal_code}
                              {/*
                          {paymentItem.city},{" "}
                          {paymentItem.state},{" "}
                          {paymentItem.postal_code}
                          */}
                            </Grid>,
                          ]
                        ) : (
                          <div className="paddedTextFieldsArea">
                            <Grid container spacing={2}>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="currentAddress1"
                                  label="Street Address 1"
                                  id="currentAddress1"
                                  value={currentCreditCardAddress.address1}
                                  variant="outlined"
                                  //onChange={handleChange}
                                  inputProps={{
                                    "aria-label": "Street Address 1",
                                  }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="currentAddress2"
                                  label="Street Address 2"
                                  id="currentAddress2"
                                  value={currentCreditCardAddress.address2}
                                  variant="outlined"
                                  //onChange={handleChange}
                                  inputProps={{
                                    "aria-label": "Street Address 2",
                                  }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="currentCity"
                                  id="currentCity"
                                  value={currentCreditCardAddress.city}
                                  label="City"
                                  variant="outlined"
                                  //onChange={handleChange}
                                  inputProps={{ "aria-label": "City" }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="currentState"
                                  id="currentState"
                                  value={currentCreditCardAddress.state}
                                  label="State"
                                  variant="outlined"
                                  //onChange={handleChange}
                                  inputProps={{ "aria-label": "State" }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                              <Grid item xs={12} direction="row">
                                <TextField
                                  name="currentZip"
                                  id="currentZip"
                                  value={currentCreditCardAddress.zip}
                                  variant="outlined"
                                  label="Postal Code"
                                  onChange={handleChange}
                                  inputProps={{ "aria-label": "Postal Code" }}
                                  fullWidth
                                  focused="true"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}

                        {(isEditing.name || isEditing.address) && (
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<SaveIcon />}
                            //onClick={}
                            >
                              Save Changes
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            )
            //])
          }
          {isEditing.newCreditCard && (
            <CreditCardForm
              setCreditCard={setNewCreditCard}
              creditCard={newCreditCard}
              setValid={setValid}
              showHeader={false}
            />
          )}
        </Grid>

        {isEditing.newCreditCard && [
          <Grid item xs={6} container direction="row" justify="flex-start">
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setIsEditing((prevState) => ({
                  ...prevState,
                  newCreditCard: false,
                }));
              }}
            >
              Back
            </Button>
          </Grid>,
          <Grid item xs={6} container direction="row" justify="flex-end">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                setIsEditing((prevState) => ({
                  ...prevState,
                  newCreditCard: false,
                  newCreditCardAddress: true,
                }));
              }}
              disabled={!valid}
            >
              Next
            </Button>
          </Grid>,
        ]}

        {isEditing.newCreditCardAddress && [
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreditCardAddressForm
                setAddress={setNewCreditCardAddress}
                address={newCreditCardAddress}
                setValid={setValid}
                showHeader={false}
              ></CreditCardAddressForm>
            </Grid>
            <Grid item xs={6} container direction="row" justify="flex-start">
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setIsEditing((prevState) => ({
                    ...prevState,
                    newCreditCard: true,
                    newCreditCardAddress: false,
                  }));
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} container direction="row" justify="flex-end">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={(e) => handleSubmit()}
              >
                {processingAddCC ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Add Card"
                )}
              </Button>
            </Grid>
          </Grid>,
        ]}

        {!loading &&
          !isEditing.newCreditCard &&
          !isEditing.newCreditCardAddress && (
            <Grid item xs={12} container direction="row" justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setIsEditing((prevState) => ({
                    ...prevState,
                    newCreditCard: true,
                  }));
                }}
              >
                Add New Credit Card
              </Button>
            </Grid>
          )}

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            You have successfully added a new credit card.
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export { MyCreditCard };
