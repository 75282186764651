import React, { useEffect } from "react";
import CreditCardForm from "./../../CreditCardForm";

const CheckoutNewCC = ({
  setCreditCard,
  creditCard,
  setValid,
  setShowBackButton,
}) => {
  useEffect(() => {
    setShowBackButton(true);
  }, []);

  const explanation = "";
  const renderView = () => {
    return (
      <CreditCardForm
        setCreditCard={setCreditCard}
        creditCard={creditCard}
        setValid={setValid}
        description={explanation}
      ></CreditCardForm>
    );
  };

  return renderView();
};

export default CheckoutNewCC;
