export const APISettings = {
  baseURL: "https://services.stjude.org/apic/emailservices/v1/email/trigger",
  headers: {
    "stjude-trigger-token": "026f81b4-183d-488f-a736-604279b09ef4",
    "Content-Type": "application/json"
  }
}

const settings = {
  clientID: "10433302",
  sendSequinSettings: {
    externalKey: "235999"
  }
}

export default new class {
  createSendSequinRequest = (email, sequinUrl) => {
    return {
      emailAddress: email,
      externalKey: settings.sendSequinSettings.externalKey,
      clientID: settings.clientID,
      personalAttributes: [
        {
          attributeKey: "sequin_URL",
          attributeValue: sequinUrl
        }
      ]
    }
  }
}()

