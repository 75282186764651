
import React, { useRef } from "react";

const ProgressBar = ({ percentage, text = "" }) => {
  const percentBarRef = useRef();

  let percentBarWidth = percentBarRef.current ? percentBarRef.current.clientWidth : 0;
  let percentFillWidth = percentage * percentBarWidth;

  return (
    <div className="progress_bar_back" ref={percentBarRef}>
      <span>{text}</span>
      <div className="progress_bar_bound_box" style={{ width: `${percentFillWidth}px` }}>
        <div className="progress_bar_front" style={{ width: `${percentBarWidth}px` }}>
          {text}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar;