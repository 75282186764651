import { Engine, Scene } from '@babylonjs/core'
import React, { useEffect, useRef, useState } from 'react'

export default (props) => {
    const reactCanvas = useRef(null);
    const { antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, canvasId, ...rest } = props;

    const [loaded, setLoaded] = useState(false);
    const [scene, setScene] = useState(null);

    useEffect(() => {
        if (!loaded) {


            // function customLoadingScreen() {
            //     // console.log("customLoadingScreen creation")
            // }
            // customLoadingScreen.prototype.displayLoadingUI = function () {
            //     // console.log("customLoadingScreen loading")
            //     loadingScreenDiv.style.display = "flex";
            //     loadingScreenDiv.innerHTML = "<h3>The scene is currently loading</h3>";
            // };
            // customLoadingScreen.prototype.hideLoadingUI = function () {
            //     // console.log("customLoadingScreen loaded")
            //     loadingScreenDiv.style.display = "none";
            // };

            // var loadingScreen = new customLoadingScreen();

            setLoaded(true);
            const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
            const scene = new Scene(engine, sceneOptions);

            // var loadingScreenDiv = window.document.getElementById("loadingScreen");
            // engine.loadingScreen = loadingScreen;
            // engine.displayLoadingUI();

            setScene(scene);
            let e = { scene, engine, canvas: reactCanvas.current };
            if (scene.isReady()) {
                onSceneReady(e)
            } else {
                scene.onReadyObservable.addOnce(scene => onSceneReady(e));
            }

            engine.runRenderLoop(() => {
                if (typeof onRender === 'function') {
                    onRender(e);
                }
                scene.render();
            })
        }

        return () => {
            if (scene !== null) {
                scene.dispose();
            }
        }
    }, [reactCanvas, adaptToDeviceRatio, antialias, engineOptions, loaded, onRender, onSceneReady, scene, sceneOptions])

    return (
        <canvas ref={reactCanvas} id={canvasId} {...rest} />
    );
}
