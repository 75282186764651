import { BehaviorSubject } from "rxjs";

export const AuthStatus = {
  authNone: "auth.status.none",
  authStart: "auth.status.authStart",
  authSuccess: "auth.status.authSuccess",
  authNotConfirmed: "auth.status.notConfirmed",
  authFail: "auth.status.authFail",
  authLogout: "auth.status.authLogout",
};

export default new class {
  constructor() {
    this._status = new BehaviorSubject({
      state: AuthStatus.authNone,
      data: null
    });
  }

  updateStatus(newState, data = null) {
    this._status.next(
      Object.assign(this._status.value, {
        state: newState,
        data: data,
      })
    );
  };

  get authStatus() {
    return this._status;
  }

}()
