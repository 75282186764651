/* eslint-disable */

import { gql } from "@apollo/client";

export const createBulkSequins = gql`
  mutation CreateBulkSequins($input: CreateSequinInput!) {
    createBulkSequins(input: $input) {
      id
    }
  }
`;
export const createBulkRegions = gql`
  mutation CreateBulkRegions($input: CreateRegionInput!) {
    createBulkRegions(input: $input) {
      id
    }
  }
`;
export const deleteAllRegions = gql`
  mutation DeleteAllRegions($input: DeleteRegionInput) {
    deleteAllRegions(input: $input) {
      count
    }
  }
`;
export const deleteAllSequins = gql`
  mutation DeleteAllSequins($input: DeleteSequinInput) {
    deleteAllSequins(input: $input) {
      count
    }
  }
`;
export const startExperience = gql`
  mutation StartExperience($input: StartExperienceInput) {
    startExperience(input: $input) {
      id
    }
  }
`;

export const stopExperience = gql`
  mutation StopExperience($input: StopExperienceInput) {
    stopExperience(input: $input)
  }
`;

export const incrementPollChoice = gql`
  mutation IncrementPollChoice($input: PollChoiceInput) {
    incrementPollChoice(input: $input)
  }
`;

export const createPoll = gql`
  mutation CreatePoll($input: NewPoll) {
    createPoll(input: $input) {
      pollid
    }
  }
`;

export const updatePollState = gql`
  mutation UpdatePollState($input: UpdatePollStateInput) {
    updatePollState(input: $input)
  }
`;

export const updatePollTime = gql`
  mutation UpdatePollTime($input: UpdatePollTimeInput) {
    updatePollTime(input: $input)
  }
`;

export const completePoll = gql`
  mutation CompletePoll($input: CompletePollInput) {
    completePoll(input: $input)
  }
`;

export const createEventUser = gql`
  mutation CreateEventUser($input: EventUserInput!) {
    createEventUser(input: $input)
  }
`;

export const updateEventUserState = gql`
  mutation UpdateEventUserState($input: UpdateEventUserStateInput!) {
    updateEventUserState(input: $input)
  }
`;

export const purchaseSequin = gql`
  mutation PurchaseSequin($input: PurchaseSequinInput!) {
    purchaseSequin(input: $input) {
      id
    }
  }
`;

export const claimSequinWithMedia = gql`
  mutation ClaimSequinWithMedia($input: ClaimSequinWithMediaInput!) {
    claimSequinWithMedia(input: $input) {
      uuid
      sequinid
      type
      label
      url
      bodytext
      displayname
      hometown
    }
  }
`;

export const createInvoice = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      userid
      label
      stateid
    }
  }
`;

/**
 * Invoice State IDs 
  1 | open
  2 | paid
  3 | canceled
  4 | abandoned
 */
export const updateInvoiceState = gql`
  mutation UpdateInvoiceState($input: UpdateInvoiceStateInput!) {
    updateInvoiceState(input: $input) {
      id
      stateid
      total
      paymentinformation{last_3}
      invoicehistory{paid_at}
    }
  }
`;

export const createInvoiceItem = gql`
  mutation CreateInvoiceItem($input: CreateInvoiceItemInput!) {
    createInvoiceItem(input: $input) {
      invoiceid
      label
      amount
      id
    }
  }
`;

export const updateInvoiceItem = gql`
  mutation UpdateInvoiceItem($input: UpdateInvoiceItemInput!) {
    updateInvoiceItem(input: $input) {
      id
      invoiceid
      label
      amount
    }
  }
`;

export const deleteInvoiceItem = gql`
  mutation DeleteInvoiceItem($input: DeleteInvoiceItemInput!) {
    deleteInvoiceItem(input: $input) {
      id
      invoiceid
      label
      amount
    }
  }
`;

export const createPaymentInformation = gql`
  mutation CreatePaymentInformation($input: CreatePaymentInformationInput!){
    createPaymentInformation(input: $input) {
      id
      aliasid
      userid
      last_3
      address
      city
      state
      postal_code
      expiration
      first_name
      last_name
      deleted
      active
      expired
    }
  }
`;

export const createActivePaymentInformation = gql`
  mutation CreateActivePaymentInformation($input: CreateActivePaymentInformationInput!){
    createActivePaymentInformation(input: $input) {
      id
      aliasid
      userid
      last_3
      address
      city
      state
      postal_code
      expiration
      first_name
      last_name
      deleted
      active
      expired
    }
  }
`;

export const updatePaymentInformationResetActiveAlias = gql`
  mutation UpdatePaymentInformationResetActiveAlias($input: UpdatePaymentInformationResetActiveAliasInput!){
    updatePaymentInformationResetActiveAlias(input: $input) {
      id
      aliasid
      active
    }
  }
`;

export const updateDeletePaymentInformation = gql`
  mutation UpdateDeletePaymentInformation($input: UpdateDeletePaymentInformationInput!){
    updateDeletePaymentInformation(input: $input) {
      id
      aliasid
      active
      deleted
    }
  }
`;