import React, { useState, useEffect, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Button, Link, Toolbar, SvgIcon } from "@material-ui/core";
import classNames from "classnames";
import { useSubscription } from "observable-hooks";
import EventAuthLogic from "../logic/EventAuthLogic";
import { useAuth } from "../hooks/UseAuth";
import { AuthStatus } from "../logic/AuthLogic";
import AccountDrawer from "./AccountDrawer";
import EventButton from "./EventButton";
import DonateButtonAndWidget from "./ActiveEventDonation/DonateButtonAndWidget";
import MSDonateButton from "./MSDonateButton";
import RunOfShowStore, { RunOfShowStates } from "./../logic/RunOfShowLogic";

const NavBar = (props) => {
  const auth = useAuth();
  const [authState, setAuthState] = useState(null);
  const [event, setEvent] = useState(null);
  const [blueLight, setBlueLight] = useState(false);

  useSubscription(EventAuthLogic.stream, async (o) => {
    if (!o) return;

    let e = o.event;
    let a = o.auth;

    if (e.id) setEvent(e);
    if (a.state === AuthStatus.authSuccess) setAuthState(a);
  });

  useEffect(() => {
    if (!event) return;

    const subRunOfShow = RunOfShowStore.stream(event.id).subscribe((exps) => {
      // start listening for run of show experiences for the event
      setBlueLight(exps.some(x => x.type === RunOfShowStates.blueLightSpecial));
    });
    return () => subRunOfShow.unsubscribe();

  }, [event]);

  function SjLogo(props) {
    return (
      <SvgIcon viewBox="0 0 74.45 54" {...props}>
        <switch>
          <g>
            <path
              className="st0"
              d="M59.98 38.87c-.64 0-1.13-.5-1.13-1.15 0-.66.48-1.15 1.13-1.15.65 0 1.14.5 1.14 1.15 0 .65-.49 1.15-1.14 1.15m0-2.55c-.79 0-1.4.61-1.4 1.4 0 .78.61 1.4 1.4 1.4.79 0 1.41-.61 1.41-1.4 0-.79-.62-1.4-1.41-1.4"
            />
            <path
              className="st0"
              d="M60.01 37.64h-.26v-.4h.28c.12 0 .2.08.2.2 0 .1-.07.2-.22.2m.24.13c.14-.08.23-.2.23-.34 0-.23-.16-.37-.41-.37h-.59v1.3h.26v-.53h.24l.31.53h.28l-.35-.58.03-.01z"
            />
            <path
              className="st0"
              d="M38.32 31.6c-.04.11-.16.12-.16.12-2 .06-3.72.87-5.09 2.4 0 0-.15.15-.54-.16-.3-.23-.19-.48-.19-.48.53-1.33 1.9-2.21 4.06-2.61l.06-.01c.44-.06 1.47-.2 1.82.49 0 0 .08.13.04.25m6.18 6.61l-.01-.02c.76-1.1 1.82-2.52 2.2-2.78.03-.01.05-.02.08-.03 1.14-.07 1.78-.46 1.95-.58.22-.15.85-.76.95-.88.11-.11.44-.85.57-1.16l-.01.03.05-.12.01-.03.09-.27c0-.08-.05-.15-.12-.19-.05-.03-.11-.03-.18-.02 0-.01.01-.02.01-.02.04-.09.08-.2.08-.28v-.02c-.01-.09-.06-.15-.13-.2a.44.44 0 00-.28-.02.32.32 0 00-.12-.11c-.02-.02-.15-.06-.38.08-.09.05-.18.15-.25.24l.02-.14a.248.248 0 00-.16-.17c-.11-.04-.25-.01-.38.07-.15.1-.34.41-.4.5-.05.04-.24.21-.36.41-.37.12-.6.11-.7.08.23-.13.4-.25.5-.37.22-.27.35-.54.35-.77 0-.04 0-.08-.01-.11a.309.309 0 00-.16-.23c-.22-.11-.49.11-.54.16 0 .01-.08.08-.12.13-.08.02-.26.07-.38.18-.08.07-.18.11-.29.14-.14.05-.29.1-.44.23l-.01.01s.01 0 .01-.01c-.03.02-.27.18-.35.23-.35.18-.54.37-.74.57-.4.39-.89.88-3.11 1.61-.02-.81-.52-2.99-.62-3.83-.2-1.47-1.91-3.5-3.14-4.79 1.16.67 1.81.77 2.45-.1.17-.26.43.02.68-.03.35-.18.31-.28.31-.53.1-.27.38-.14.58-.17.52-.09.21-.63.41-.9 2.27-.14-.29-2.22 1.24-3.09.99-.46 1.37-1.08 1.81-1.68.37-.49.44-.11.72-.51.49-1.78-.36-3.97-1.46-5.48-5.64-7.25-12.07-3.91-13.95.58-.78 1.14-.54 2.8-.58 4.16-.15.47-.43.46-.84.18-.09.94.64 1.13.97 1.19.03.12.25.3.34.43-.29.08-.91-.08-1.11-.19-.01.21.02.39.19.58.14.67.48.91.96 1.1.9.37.48 1.61.37 2.12-.03.11-.07.22-.1.33-.84.21-1.39.97-1.62 1.76-.1 1.9-1.08 3.48-1.07 5.45 0 0-.02.29 0 .41.49 2.13-.14 4.92-.03 7.12C21.11 39.5 14.58 41.79 9 45c8.27-3.33 17.91-5.24 28.22-5.24 10.31 0 19.96 1.92 28.22 5.24-6.02-3.47-13.16-5.86-20.94-6.79"
            />
            <path fill="none" d="M0 0h74.45v54H0z" />
          </g>
        </switch>
      </SvgIcon>
    );
  }
  return (
    <AppBar className="app-bar" position="static">
      <Toolbar className="nav-toolbar">
        <Link
          color="inherit"
          component={RouterLink}
          to="/"
          className="siteBrand"
        >
          <SjLogo fontSize="large" className="siteIcon" />
          <div className="siteName">
            <span className="siteNameTop">St. Jude</span>
            <span className="siteNameBottom">Event Experience</span>
          </div>
        </Link>

        {auth.user && authState?.state === AuthStatus?.authSuccess ? (
          <>
            <div className="nav-links">
              {props.includeDonate && !props.isNoRegEvent && (
                <DonateButtonAndWidget
                  isLive={props.isLive} //used for classname
                  donateBtnClassName={classNames("donate-btn", {
                    "blue-light": blueLight
                  })}
                  totalWidgetClassName="totalButton"
                />
              )}

              {props.includeDonate && props.isNoRegEvent && (
                <MSDonateButton
                  wraperName="donateButton"
                  donateBtnClassName={classNames("ms-donate-btn", {
                    "blue-light": blueLight
                  })}
                  isLive={true} //used for classname for css styling
                />
              )}

              {props.includeForestBtn && (
                <Link
                  component={RouterLink}
                  to={props.isLive ? "forest" : "/video/introduction"}
                  color="inherit"
                  target={props.isLive ? "_blank" : "_self"} //opens in a new tab if on live page, open in same frame otherwise
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classNames("forest-btn", {
                      "live-btn": props.isLive,
                    })}
                  >
                    Visit Forest
                  </Button>
                </Link>
              )}

              {props.includeJoinEventBtn && !props.isNoRegEvent && (
                <EventButton
                  variant="outlined"
                  to="/live"
                  color="inherit"
                  className="join-event MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-colorInherit"
                >
                  {" "}
                  Join Event{" "}
                </EventButton>
              )}

              {props.includeUserAccount && !props.isNoRegEvent && (
                <div
                  className={`user-drawer ${props.isLive ? "live-btn" : ""}`}
                >
                  <AccountDrawer />
                </div>
              )}
            </div>
          </>
        ) : (
          props.includesSignUpLogin &&
          !props.isNoRegEvent && (
            <>
              <div className="nav-links">
                <Link component={RouterLink} to="/login" color="inherit">
                  <Button color="inherit">Login</Button>
                </Link>
                <Link component={RouterLink} to="/signup" color="inherit">
                  <Button color="inherit" id="signUpButton" className="sign-up">
                    Sign up
                  </Button>
                </Link>
              </div>
            </>
          )
        )}
      </Toolbar>
      {/*auth.user &&
        authState.state == AuthStatus.authSuccess &&
        props.isLive &&
        !props.isNoRegEvent && (
          <div className="nav-links-mobile live-btn">
            <Fragment>
              <DonateButtonAndWidget
                isLive={true}
                donateBtnClassName="donate-btn2"
                totalWidgetClassName="totalButton2"
              />
              <div className="user-drawer">
                <AccountDrawer />
              </div>
            </Fragment>
          </div>
        )*/}
    </AppBar>
  );
};

export default NavBar;
